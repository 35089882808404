import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import { faAngleDown, faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isNil } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';
import useLoadPromo from '../../../../../components/data/useLoadPromo';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import { FinderVehicleDataFragment } from '../../../../../components/routes/FinderRoute/data.graphql';
import { ConnectedCalculatorProps } from '../../../../../components/shared/calculator-next/ConnectedCalculator';
import PromoCodeCalculator from '../../../../../components/shared/calculator-next/PromoCodeCalculator';
import { CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import useCalculatorMeta from '../../../../../components/shared/calculator-next/useCalculatorMeta';
import ReservationDepositField from '../../../../../components/shared/calculator/ReservationDepositField';
import CheckboxField from '../../../../../components/shared/form/CheckboxField';
import { CalculatorError } from '../../../../../components/ui/calculator';
import InfoTooltip from '../../../../../components/ui/tooltip/InfoTooltip';
import useCalculatorData from '../../../../../components/utilities/useCalculatorData';
import useCompanyFormatting from '../../../../../components/utilities/useCompanyFormatting';
import { useCountry, useZone } from '../../../../../hookSelectors';
import { useContentTranslation } from '../../../../../i18n';
import { CalculatorVisibility, Channel, PromoCodeUnit } from '../../../../../schema';
import { useGetCalculatorFooterText } from '../../../../../utilities/calculator';
import { CalculatorErrors } from '../../../../DraftFlow';
import useFinanceProductRefinements from '../../../../DraftFlow/components/ExpressCalculator/useFinanceProductRefinements';
import useRefineCalculatorSnapshot from '../../../../DraftFlow/components/ExpressCalculator/useRefineCalculatorSnapshot';
import useRefineFinanceProducts from '../../../../DraftFlow/components/ExpressCalculator/useRefineFinanceProducts';
import { CalculatorSnapshot } from '../../../../utils/getSnapshotFromApplication';
import useDealerEstablishment from '../../../../utils/useDealerEstablishment';
import { PortalContainer } from '../Portal';
import RemoteExpectedCashPaymentField from '../RemoteExpectedCashPaymentField';
import RemoteExpectedTradeInAmount from '../RemoteExpectedTradeInAmountField';
import RemotePromoCodeField from '../RemotePromoCodeField';
import { Buttons, Container, Paragraph, PrimaryButton, Title } from '../ui';
import useApplyDisabled from '../useApplyDisabled';
import Disclaimer from './Disclaimer';
import FinancingForm, { CheckboxRow } from './FinancingForm';

export type FinancingProps = {
    dealerId: string;
    calculatorValues?: Partial<CalculatorValues>;
    initialCalculatorValues: Partial<CalculatorValues>;
    snapshot?: CalculatorSnapshot;
    onCalculatorChange: ConnectedCalculatorProps['onChange'];
    calculatorErrors?: Partial<CalculatorErrors>;
    variant: VariantDataFragment;
    variants: VariantDataFragment[];
    event: EventDataFragment;
    finderVehicle?: FinderVehicleDataFragment | null;
};

export type FinanceData = {
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
    isCalculatorEnabled?: boolean;
    applyForFinancing?: boolean;
};

export const CalculatorContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 6px;
`;

export const CalculatorToggle = styled.div`
    display: block;
    text-align: right;
    margin-top: 6px;
    margin-bottom: 17px;
`;

export const MonthlyPayment = styled.div`
    display: inline-block;
    cursor: pointer;
    span {
        font-style: italic;
    }

    & > *:not(:last-child) {
        margin-right: 0.5rem;
    }
`;

export type PriceProps = {
    promo?: number;
    isPromoValid?: Boolean;
    isCoeEnabled?: Boolean;
    isLast?: Boolean;
};

export const Price = styled.div<PriceProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;

    ${props =>
        props.isLast &&
        props.isCoeEnabled &&
        css`
            border-top: 1px solid #ced4da;
            margin-top: 4px;
            padding-top: 4px;
        `}

    span:first-child {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        ${props =>
            props.theme.display === 'small' &&
            css`
                text-align: left;
            `}
    }

    span:last-child {
        font-weight: 500;
        font-size: 26px;
        margin-left: 17px;
        white-space: nowrap;
        ${props =>
            props.isCoeEnabled &&
            css`
                font-size: 16px;
                font-weight: 400;
            `}
        ${props =>
            props.promo &&
            props.isPromoValid &&
            css`
                color: #44444480;
                text-decoration: line-through;
            `}
    }

    span:last-of-type {
        margin-top: 6px;

        ${props =>
            props.isCoeEnabled &&
            props.isLast &&
            css`
                margin-top: 0;
            `}
    }
`;

export const PriceContainer = styled.div`
    line-height: 10px;
    margin-bottom: 6px;
`;

export const Description = styled.div`
    margin-bottom: 30px;
`;

export const SubHeading = styled.h3`
    font-size: 14px;
    font-weight: normal;
`;

export type CalculatorTranslationContent = {
    title: string;
    content: string;
};

export const FinanceCalculatorCheckboxLabel = () => {
    const { t } = useTranslation();

    const financeCalculatorTooltip: CalculatorTranslationContent = t('eventCalculatorPage.financeCalculator', {
        returnObjects: true,
    });

    const hasTooltip = financeCalculatorTooltip?.title && financeCalculatorTooltip?.content;

    return (
        <span style={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'center' }}>
            {t('eventCalculatorPage.checkbox.financeCalculator')}
            {hasTooltip && (
                <InfoTooltip content={financeCalculatorTooltip.content} title={financeCalculatorTooltip.title} />
            )}
        </span>
    );
};

const Financing = ({
    dealerId,
    calculatorValues,
    initialCalculatorValues,
    snapshot,
    onCalculatorChange,
    handleSubmit,
    calculatorErrors,
    variant,
    variants,
    event,
    change,
    form,
    finderVehicle,
}: FinancingProps & InjectedFormProps<FinanceData, FinancingProps>) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    const [remoteNode, setRemoteNode] = useState<HTMLElement | null>(null);

    // get the text footer from the zone
    const { id: zoneId } = useZone();

    // get countryId for loading promo
    const country = useCountry();
    const { id: countryId } = country;

    const { formatCurrency } = useCompanyFormatting();

    const { financeProducts } = useCalculatorData(Channel.EVENT, dealerId, undefined, event.id);
    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId as string, Channel.EVENT);

    // There is visibility optional, so listen to value change
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);
    const formValues = useSelector<FinanceData>(valueSelector);

    const isCalculatorEnabled = get('isCalculatorEnabled', formValues) as boolean;
    const hasTradeIn = get('hasTradeIn', formValues) as boolean;

    useEffect(() => {
        if (isNil(isCalculatorEnabled)) {
            const visibility = event.setting.calculatorVisibility;

            const newValue = isNil(visibility) ? true : event.setting.calculatorVisibility === CalculatorVisibility.ON;

            change('isCalculatorEnabled', newValue);
        }
    }, [isCalculatorEnabled, change, event.setting.calculatorVisibility]);

    const isCalculatorVisibilityOptional = event.setting.calculatorVisibility === CalculatorVisibility.OPTIONAL;

    const [open, setOpen] = useState(isCalculatorEnabled);
    const toggle = useCallback(() => {
        if (isCalculatorEnabled) {
            setOpen(state => !state);
        }
    }, [isCalculatorEnabled]);

    useEffect(() => {
        setOpen(isCalculatorEnabled);
    }, [isCalculatorEnabled]);

    // we need to render promo code outside the calculator
    // so we can create a div with an unique id
    const promoContainerId = useMemo(uuid, []);

    const applyDisabled = useApplyDisabled(calculatorValues || {});

    const price = variant.prices.find(item => item.zoneId === zoneId)?.value;

    const promo = useLoadPromo(countryId, dealerId, calculatorValues?.promoCode);
    const isPromoValid = promo?.deductValue && promo?.remainingQuantity > 0;

    const promoAmount = useMemo(() => {
        if (promo && promo.value) {
            switch (promo.unit) {
                case PromoCodeUnit.PERCENTAGE: {
                    if (!price) {
                        return 0;
                    }

                    return price * (promo.value / 100);
                }
                case PromoCodeUnit.CURRENCY:
                    return promo.value;
                default:
                    return undefined;
            }
        }

        return 0;
    }, [promo, price]);

    const {
        allowTradeIn,
        allowTestDrive,
        allowTradeInAmountInput,
        isPromoCodeEnabled,
        bookingPayment,
        isCoeEnabled,
        isCoeEditable,
        isPpsrAndEstablishmentEnabled,
        priceDisclaimers,
        applyForFinancing,
    } = event.setting;

    const usedCoeAmount = useMemo(() => calculatorValues?.coe ?? country?.coe?.amount, [calculatorValues, country]);

    const priceIncludeCoe = (price ?? 0) - (isPromoValid ? promoAmount ?? 0 : 0) + (usedCoeAmount ?? 0);

    const priceElement = (
        <Price isCoeEnabled={isCoeEnabled} isPromoValid={isPromoValid} promo={promoAmount}>
            <span>{t('eventCalculatorPage.label.price')}</span>
            <span>{formatCurrency(price)}</span>
        </Price>
    );

    const promoElement =
        promoAmount && isPromoValid ? (
            <Price isCoeEnabled={isCoeEnabled}>
                <span>
                    {t(
                        isCoeEnabled
                            ? 'eventCalculatorPage.label.priceWithoutCoe'
                            : 'eventCalculatorPage.label.finalPrice'
                    )}
                </span>
                <span>{price && formatCurrency(price - promoAmount)}</span>
            </Price>
        ) : null;

    const estimatedCoeElement = isCoeEnabled && (
        <Price isCoeEnabled={isCoeEnabled}>
            <span>{t('eventCalculatorPage.label.estimatedCoe')}</span>
            <span>{formatCurrency(usedCoeAmount)}</span>
        </Price>
    );

    const priceWithCoeElement = isCoeEnabled && (
        <Price isCoeEnabled={isCoeEnabled} isLast>
            <span>{t('eventCalculatorPage.label.priceIncludeCoe')}</span>
            <span>{price && formatCurrency(priceIncludeCoe)}</span>
        </Price>
    );

    const dealer = useMemo(() => event?.dealers?.find(dealer => dealer.id === dealerId), [event, dealerId]);

    const hasDepositPayment = !!event?.setting?.isDepositPaymentMandatory;
    const paymentAmount = bookingPayment?.amount;

    const monthlyPayment = calculatorValues?.monthlyInstalments
        ? calculatorValues.monthlyInstalments[0].amount
        : undefined;

    const descriptionElement = (
        <>
            <Title>{ct(variant.name)}</Title>
            <SubHeading>
                <Trans i18nKey="eventCalculatorPage.subHeading" values={{ name: ct(dealer?.name) }} />
            </SubHeading>
            <Paragraph>{ct(variant.description)}</Paragraph>
        </>
    );

    const [totalPrice, setTotalPrice] = useState<number | undefined>(undefined);
    const financeProductRefinements = useFinanceProductRefinements(finderVehicle?.details);
    const refinedSnapshot = useRefineCalculatorSnapshot(financeProductRefinements, snapshot, totalPrice);
    const refinedFinanceProducts = useRefineFinanceProducts(
        financeProducts,
        financeProductRefinements,
        variant?.version?.id,
        totalPrice
    );

    const onCalculatorChangeEnhanced = useCallback(
        (context: CalculatorContext<CalculatorValues>) => {
            if (context.values.totalPrice) {
                setTotalPrice(context.values.totalPrice);
            }

            if (onCalculatorChange) {
                onCalculatorChange(context);
            }
        },
        [onCalculatorChange]
    );

    const selectedBankEstablishment = financeProducts
        ? financeProducts.find(fp => fp.dealerIds.includes(dealerId))?.bank.establishment
        : null;

    const getCalculatorFooterText = useGetCalculatorFooterText();
    const disclaimerElement = useMemo(() => {
        // get the text footer from the country
        return priceDisclaimers?.map((priceDisclaimer: string, index) => {
            const calculatorFooterText = getCalculatorFooterText(
                priceDisclaimer,
                country?.coe?.amount,
                country?.ppsr?.amount,
                country?.establishment?.amount,
                country?.luxuryTax?.amount,
                selectedDealerEstablishment,
                selectedBankEstablishment
            );

            return <Disclaimer key={index.toString()} text={calculatorFooterText || ''} />;
        });
    }, [country, getCalculatorFooterText, priceDisclaimers, selectedBankEstablishment, selectedDealerEstablishment]);

    // the finance product is not yet setup
    const invalidCalculatorSetup = useMemo(
        () => !financeProducts.some(({ finderVehicles }) => finderVehicles.includes(variant.version.id)),
        [financeProducts, variant]
    );

    const additionalMeta = useMemo(
        () => ({
            snapshot: refinedSnapshot,
            channel: Channel.EVENT,
            variants,
            isPromoCodeEnabled,
            isCoeEnabled,
            isCoeEditable,
            isPpsrAndEstablishmentEnabled,
            financeProducts: refinedFinanceProducts,
            allowTradeInAmountInput,
            selectedDealerEstablishment,
        }),
        [
            refinedSnapshot,
            variants,
            isPromoCodeEnabled,
            isCoeEnabled,
            isCoeEditable,
            isPpsrAndEstablishmentEnabled,
            refinedFinanceProducts,
            allowTradeInAmountInput,
            selectedDealerEstablishment,
        ]
    );
    const meta = useCalculatorMeta(additionalMeta);

    const showTradeInAmountInput = hasTradeIn && allowTradeInAmountInput;

    const isVehiclePreowned = finderVehicle?.listing?.vehicle.condition?.value?.toLowerCase() !== 'new';
    const isVehiclePreownedAndHasLta = isVehiclePreowned && !!finderVehicle?.details;

    const showApplyForFinancing =
        applyForFinancing &&
        ((country.code === 'SG' && (isVehiclePreownedAndHasLta || !isVehiclePreowned)) || country.code !== 'SG');

    return (
        <Container>
            <Description>{descriptionElement}</Description>
            <PriceContainer>
                {priceElement}
                {promoElement}
                {estimatedCoeElement}
                {priceWithCoeElement}
                {!invalidCalculatorSetup && (
                    <CalculatorToggle>
                        <MonthlyPayment onClick={toggle}>
                            <span>
                                <Trans
                                    components={{ s: <span /> }}
                                    i18nKey="eventCalculatorPage.label.monthlyPayment"
                                    values={{ monthlyPayment: formatCurrency(monthlyPayment) }}
                                />
                            </span>
                            {!isCalculatorVisibilityOptional && (
                                <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} size="lg" />
                            )}
                        </MonthlyPayment>
                    </CalculatorToggle>
                )}
            </PriceContainer>
            {!!priceDisclaimers?.length && isCalculatorEnabled && disclaimerElement}
            {!invalidCalculatorSetup && (
                <CalculatorContainer>
                    <PromoCodeCalculator
                        channel={Channel.EVENT}
                        dealerId={dealerId}
                        initialValues={initialCalculatorValues}
                        meta={meta}
                        onChange={onCalculatorChangeEnhanced}
                    >
                        <displayFields.CarModelPriceField
                            fieldKey="carModelAndPrice"
                            isViewable={() => false}
                            size={2}
                            override
                        />
                        <displayFields.CarModelField
                            fieldKey="variant"
                            isViewable={() => false}
                            labelTKey="calculator.label.carModel"
                            override
                        />
                        <displayFields.CarPriceField
                            fieldKey="carPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.carPrice"
                            override
                        />
                        <displayFields.MonthlyInstalmentField
                            fieldKey="monthlyInstalment"
                            isViewable={context => open && displayFields.isMonthlyInstalmentViewable(context)}
                            labelTKey="calculator.label.monthlyInstalment"
                            override
                        />
                        <displayFields.MultipleMonthlyInstalmentsField
                            fieldKey="monthlyInstalments"
                            isViewable={context => open && displayFields.isMultipleMonthlyInstalmentsViewable(context)}
                            labelTKey="calculator.label.monthlyInstalment"
                            override
                        />
                        <displayFields.TotalPriceField
                            fieldKey="totalPrice"
                            isViewable={context => open && displayFields.isTotalPriceViewable(context)}
                            labelTKey="calculator.label.totalPrice"
                            override
                        />
                        <displayFields.BankField
                            fieldKey="bank"
                            isViewable={context => open && displayFields.isBankViewable(context)}
                            labelTKey="calculator.label.bank"
                            override
                        />
                        <displayFields.DownPaymentField
                            fieldKey="downPayment"
                            isViewable={context => open && displayFields.isDownPaymentViewable(context)}
                            labelTKey="calculator.label.downPayment"
                            override
                        />
                        <displayFields.PaymentTermField
                            fieldKey="tenure"
                            isViewable={context => open && displayFields.isPaymentTermViewable(context)}
                            labelTKey="calculator.label.paymentTerm"
                            override
                        />
                        <displayFields.LoanField
                            fieldKey="loan"
                            isViewable={context => open && displayFields.isLoanViewable(context)}
                            labelTKey="calculator.label.loanAmount"
                            override
                        />
                        <displayFields.MarginOfFinanceField
                            fieldKey="loan.percentage"
                            isViewable={context => open && displayFields.isMarginOfFinanceViewable(context)}
                            labelTKey="calculator.label.marginOfFinance"
                        />
                        <displayFields.HPAmountField
                            fieldKey="loan.amount"
                            isViewable={context => open && displayFields.isHPAmountViewable(context)}
                            labelTKey="calculator.label.hpAmount"
                        />
                        <displayFields.InterestRateField
                            fieldKey="interestRate"
                            isViewable={context => open && displayFields.isInterestRateViewable(context)}
                            labelTKey="calculator.label.interestRate"
                            override
                        />
                        <displayFields.FinanceProductField
                            fieldKey="financeProduct"
                            isViewable={() => open}
                            labelTKey="calculator.label.financeProduct"
                            override
                        />
                        <displayFields.BalloonPaymentField
                            fieldKey="balloon"
                            isViewable={context => open && displayFields.isBalloonPaymentViewable(context)}
                            labelTKey="calculator.label.balloonPayment"
                            override
                        />
                        <displayFields.DepositField
                            fieldKey="deposit"
                            isViewable={context => open && displayFields.isDepositViewable(context)}
                            labelTKey="calculator.label.deposit"
                            override
                        />
                        <displayFields.PaymentModeField
                            fieldKey="paymentMode"
                            isViewable={context => open && displayFields.isPaymentModeViewable(context)}
                            labelTKey="calculator.label.paymentMode"
                            override
                        />
                        <displayFields.COEField
                            fieldKey="coe"
                            isViewable={context => open && displayFields.isCOEViewable(context)}
                            labelTKey="calculator.label.coe"
                            override
                        />
                        <displayFields.MileageField
                            fieldKey="mileage"
                            isViewable={context => open && displayFields.isMileageViewable(context)}
                            labelTKey="calculator.label.mileage"
                            override
                        />
                        <displayFields.ResidualValueField
                            fieldKey="residualValue"
                            isViewable={context => open && displayFields.isResidualValueViewable(context)}
                            labelTKey="calculator.label.residualValueField"
                            override
                        />
                        <displayFields.AssuredResaleValueField
                            fieldKey="residualValue.amount"
                            isViewable={context => open && displayFields.isAssuredResaleValueViewable(context)}
                            labelTKey="calculator.label.assuredResaleValueField"
                            override
                        />
                        <displayFields.EstimatedSurplusField
                            fieldKey="estimatedSurplus"
                            isViewable={context => open && displayFields.isEstimatedSurplusViewable(context)}
                            labelTKey="calculator.label.estimatedSurplus"
                            override
                        />
                        <displayFields.TradeInEquityField
                            fieldKey="tradeInEquity"
                            isViewable={context => open && displayFields.isTradeInViewable(context)}
                            labelTKey="calculator.label.tradeInEquity"
                            override
                        />
                        <displayFields.CashPaymentField
                            fieldKey="cashPayment"
                            isViewable={context => open && displayFields.isCashPaymentViewable(context)}
                            labelTKey="calculator.label.cashPayment"
                            override
                        />
                        <RemoteExpectedTradeInAmount
                            container={remoteNode}
                            fieldKey="expectedTradeInAmount"
                            isViewable={context =>
                                open && showTradeInAmountInput && displayFields.isExpectedTradeInAmountViewable(context)
                            }
                            labelTKey="calculator.label.expectedTradeInAmount"
                            initialShowEdition
                        />
                        <RemoteExpectedCashPaymentField
                            container={remoteNode}
                            fieldKey="expectedCashPayment"
                            isViewable={context =>
                                open && showTradeInAmountInput && displayFields.isExpectedCashPaymentViewable(context)
                            }
                            labelTKey="calculator.label.expectedCashPayment"
                        />
                        <RemotePromoCodeField
                            containerId={promoContainerId}
                            fieldKey="promoCode"
                            isViewable={displayFields.isPromoCodeViewable}
                            labelTKey="calculator.label.promoCode"
                            size={2}
                        />
                    </PromoCodeCalculator>
                </CalculatorContainer>
            )}
            <div id={promoContainerId} />
            {hasDepositPayment && <ReservationDepositField amount={paymentAmount} channel={Channel.EVENT} />}
            <PortalContainer
                ref={node => setRemoteNode(node)}
                $mt={showTradeInAmountInput && isCalculatorEnabled ? '12px' : '5px'}
            />
            <FinancingForm allowTestDrive={allowTestDrive} allowTradeIn={allowTradeIn} />
            {showApplyForFinancing && (
                <CheckboxRow $marginTop={!allowTradeIn && !allowTestDrive ? '21px' : '6px'} columns={1}>
                    <CheckboxField
                        channel={Channel.EVENT}
                        label={t('eventCalculatorPage.checkbox.applyForFinancing')}
                        name="appliedForFinancing"
                    />
                </CheckboxRow>
            )}
            {isCalculatorVisibilityOptional && (
                <CheckboxRow $marginTop={!allowTradeIn && !allowTestDrive ? '21px' : '6px'} columns={1}>
                    <CheckboxField
                        channel={Channel.EVENT}
                        label={<FinanceCalculatorCheckboxLabel />}
                        name="isCalculatorEnabled"
                    />
                </CheckboxRow>
            )}
            <Buttons>
                <PrimaryButton disabled={applyDisabled} onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faAngleRight} /> {t('eventCalculatorPage.button.next')}
                </PrimaryButton>
            </Buttons>
            {!invalidCalculatorSetup && <CalculatorError errors={calculatorErrors} />}
        </Container>
    );
};

export default reduxForm<FinanceData, FinancingProps>({ form: 'financing' })(Financing);
