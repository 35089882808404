import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CheckboxField from '../../../../../components/shared/form/CheckboxField';
import InfoTooltip from '../../../../../components/ui/tooltip/InfoTooltip';
import { Channel } from '../../../../../schema';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { Row } from '../ui';

export type FinancingFormProps = {
    allowTradeIn?: boolean;
    allowTestDrive?: boolean;
    page?: 'eventCalculatorPage' | 'eventDraftPage';
};

export const CheckboxRow = styled(Row)<{ $marginTop?: string }>`
    grid-gap: 0;
    margin-top: ${props => props.$marginTop ?? '21px'};
    line-height: 1;
    @media (max-width: ${breakpoints.sm}) {
        display: grid;

        ${({ columns }) => `grid-template-columns: repeat(${columns ?? 2}, 1fr);`}
    }
`;

type TradeInContent = {
    title?: string;
    content?: string;
};

const TradeInLabel = ({ page }: FinancingFormProps) => {
    const { t } = useTranslation();

    const tradeInTooltip: TradeInContent = t('eventCalculatorPage.tradeIn', { returnObjects: true });

    const hasTooltip = tradeInTooltip?.title && tradeInTooltip?.content;

    return (
        <span style={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'center' }}>
            {t(`${page}.checkbox.tradeIn`)}
            {hasTooltip && <InfoTooltip content={tradeInTooltip.content} title={tradeInTooltip.title} />}
        </span>
    );
};

const FinancingForm = ({ allowTradeIn, allowTestDrive, page = 'eventCalculatorPage' }: FinancingFormProps) => {
    const { t } = useTranslation();
    const channel = Channel.EVENT;

    if (!allowTradeIn && !allowTestDrive) {
        return null;
    }

    return (
        <>
            {allowTradeIn && (
                <CheckboxRow columns={1}>
                    <CheckboxField channel={channel} label={<TradeInLabel page={page} />} name="hasTradeIn" />
                </CheckboxRow>
            )}
            {allowTestDrive && (
                <CheckboxRow $marginTop={!allowTradeIn ? '21px' : '6px'} columns={1}>
                    <CheckboxField channel={channel} label={t(`${page}.checkbox.testDrive`)} name="hasTestDrive" />
                </CheckboxRow>
            )}
        </>
    );
};

export default FinancingForm;
