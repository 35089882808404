import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadAddressTypes, loadDialingCodes, loadResidenceTypes } from '../../../../../actions';
import { BankPresetOption } from '../../../../../schema';
import { getAddressTypes, getResidenceTypes } from '../../../../../selectors';
import { AddressType } from '../../../../../utilities/constants/optionTypes';
import { fromContextValidation, onTelKeyPress, yupExt } from '../../../../../utilities/forms';
import SubTitle from '../../../../ui/SubTitle';
import useBankPresetLabel from '../../../../utilities/useBankPresetLabel';
import useEmirates from '../../../../utilities/useEmirates';
import usePhone from '../../../../utilities/usePhone';
import CountryField from '../../CountryField';
import SelectField from '../../SelectField';
import TextField from '../../TextField';

export type ContactDetailsProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const getOfficeEmirateName = (bankPresetOption: BankPresetOption) => {
    switch (bankPresetOption) {
        case BankPresetOption.PMESA:
            return 'details.thirdParty.pmeSA.emirate.value';

        case BankPresetOption.ENBDBANK:
            return 'details.office.city';

        default:
            return 'details.emirate.value';
    }
};

const ContactDetails = ({ allowPrimaryInfoChanges = false, disabled = false }: ContactDetailsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAddressTypes());
        dispatch(loadResidenceTypes());
        dispatch(loadDialingCodes());
    }, [dispatch]);

    const addressTypes = useSelector(getAddressTypes);
    const residenceTypes = useSelector(getResidenceTypes);

    const { getValues } = useContext(ReduxFormContext);
    const formValues = getValues();

    const { code: phoneCountryCode } = usePhone();

    const bankPresetOption = get('financeProduct.bank.presetOption', formValues);

    const getBankPresetLabel = useBankPresetLabel(bankPresetOption);
    const emirates = useEmirates(bankPresetOption);

    const isPMESAPreset = bankPresetOption === BankPresetOption.PMESA;
    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    const addressType = get('customer.details.addressType', formValues);

    const renderResidenceAddress = useMemo(() => {
        return (
            <>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getBankPresetLabel('residenceAddress', 'address')}
                        name="details.residentialAddress.fullAddress"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getBankPresetLabel('residencePoBox', 'poBox')}
                        name="details.residentialAddress.poBox"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getBankPresetLabel('residenceCity', 'emirate')}
                        name={isPMESAPreset ? 'details.thirdParty.pmeSA.emirate.value' : 'details.emirate.value'}
                        options={emirates}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.residenceType')}
                        name="details.residentialAddress.type"
                        options={residenceTypes}
                        disabled
                    />
                </div>
            </>
        );
    }, [emirates, getBankPresetLabel, isPMESAPreset, residenceTypes, t]);

    const renderOfficeAddress = useMemo(() => {
        return (
            <>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getBankPresetLabel('officeAddress', 'address')}
                        name="details.office.fullAddress"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getBankPresetLabel('officePoBox', 'poBox')}
                        name="details.office.poBox"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getBankPresetLabel('officeCity', 'emirate')}
                        name={getOfficeEmirateName(bankPresetOption)}
                        options={emirates}
                        disabled
                    />
                </div>
                {!isEnbdBankPreset && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getBankPresetLabel('officeType', 'residenceType')}
                            name="details.office.type"
                            options={residenceTypes}
                            disabled
                        />
                    </div>
                )}

                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.officeLandLineNumber')}
                        name="details.office.phone"
                        onKeyPress={onTelKeyPress}
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        type="tel"
                        disabled
                    />
                </div>
            </>
        );
    }, [bankPresetOption, emirates, getBankPresetLabel, isEnbdBankPreset, phoneCountryCode, residenceTypes, t]);

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.contactDetails')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getBankPresetLabel('addressType')}
                        name="details.addressType.value"
                        options={addressTypes}
                        disabled
                    />
                </div>
                {(isEnbdBankPreset ||
                    (!isEnbdBankPreset && (!addressType || addressType.value === AddressType.RESIDENCE))) &&
                    renderResidenceAddress}
                {!isEnbdBankPreset && !!addressType && addressType.value === AddressType.OFFICE && renderOfficeAddress}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.homeCountryAddress')}
                        name="details.homeCountryAddress.fullAddress"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <CountryField
                        label={t('customerDetails.label.homeCountry')}
                        name="details.homeCountryAddress.country"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.homeCountryPhone')}
                        name="details.homeCountryAddress.phone"
                        onKeyPress={onTelKeyPress}
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        type="tel"
                        disabled
                    />
                </div>
                {isEnbdBankPreset && renderOfficeAddress}
            </div>
        </>
    );
};

const officeAddressSchema = (t: TFunction) => ({
    fullAddress: yup.string().required(t('common.error.required')),
    phone: yup
        .string()
        .required(t('common.error.required'))
        .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
    poBox: yup.string().required(t('common.error.required')),
    // @ts-ignore
    type: yup.lazy((value, options) => {
        const isEnbdBankPreset =
            get(['context', 'application', 'bank', 'presetOption'], options) === BankPresetOption.ENBDBANK;

        if (isEnbdBankPreset) {
            return yup.string().nullable();
        }

        return yup.string().required(t('common.error.required'));
    }),
});

const residentialAddressSchema = (t: TFunction) => ({
    fullAddress: yup.string().required(t('common.error.required')),
    poBox: yup.string().required(t('common.error.required')),
    type: yup.string().required(t('common.error.required')),
});

export const contactPMESASchema = (t: TFunction) => ({
    emirate: yupExt.customerProperty().shape({
        value: yup.string().nullable(),
    }),
});

export const schema = (t: TFunction) => ({
    addressType: yupExt.customerProperty().shape({ value: yup.string().required(t('common.error.required')) }),
    // @ts-ignore
    office: yup.lazy((value, options) => {
        const isEnbdBankPreset =
            get(['context', 'application', 'bank', 'presetOption'], options) === BankPresetOption.ENBDBANK;
        const parent = get('parent', options);
        if (isEnbdBankPreset || (!isEnbdBankPreset && parent.addressType?.value === AddressType.OFFICE)) {
            return yupExt.customerProperty().shape(officeAddressSchema(t));
        }

        return yupExt.object().nullable();
    }),
    // @ts-ignore
    residentialAddress: yup.lazy((value, options) => {
        const isEnbdBankPreset =
            get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;
        const parent = get('parent', options);
        if (isEnbdBankPreset || (!isEnbdBankPreset && parent.addressType?.value === AddressType.RESIDENCE)) {
            return yupExt.customerProperty().shape(residentialAddressSchema(t));
        }

        return yupExt.object().nullable();
    }),
    homeCountryAddress: yupExt.customerProperty().shape({
        country: yup.string().required(t('common.error.required')),
        fullAddress: yup.string().required(t('common.error.required')),
        phone: fromContextValidation(
            yup
                .string()
                .required(t('common.error.required'))
                .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
            'phonePattern',
            t('common.error.identityNumber')
        ),
    }),
});

export default ContactDetails;
