import { createPortal } from 'react-dom';
import styled from 'styled-components';

export const PortalContainer = styled.div<{ $mt?: string }>`
    display: grid;
    margin-bottom: 10px;
    margin-top: ${props => props.$mt ?? '5px'};
    justify-items: stretch;
    align-items: stretch;
    align-content: start;
    justify-content: stretch;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 5px;
`;

const Portal = ({ container, children }: { container: HTMLElement | null; children: React.ReactNode }) => {
    return container && createPortal(children, container);
};

export default Portal;
