import { BankPresetOption } from '../../schema';

const useBankPresetStep = <T>(options: { [key in BankPresetOption]: T }, bankPreset?: BankPresetOption) => {
    switch (bankPreset) {
        case BankPresetOption.AFFINBANK:
            return options[BankPresetOption.AFFINBANK];

        case BankPresetOption.PMESA:
            return options[BankPresetOption.PMESA];

        case BankPresetOption.ENBDBANK:
            return options[BankPresetOption.ENBDBANK];

        case BankPresetOption.NONE:
        default:
            return options[BankPresetOption.NONE];
    }
};

export default useBankPresetStep;
