import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormErrors, FormSection, SubmitHandler, Normalizer } from 'redux-form';
import useMedia from 'use-media';
import * as yup from 'yup';
import { ConsentDataFragment } from '../../../../../api/consents.graphql';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import { CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import ReservationDepositField from '../../../../../components/shared/calculator/ReservationDepositField';
import FormError from '../../../../../components/shared/form/FormError';
import { Error as ErrorMessage } from '../../../../../components/ui/form/FormActions';
import { Channel, EventExternalSite } from '../../../../../schema';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { MiniConfiguratorDetails } from '../../../../ConfiguratorFlow/components/Summary/Summary';
import FinancingForm from '../../Calculator/blocks/FinancingForm';
import { Buttons, PrimaryButton } from '../../Calculator/ui';
import { ConsentForm, schema as consentFormSchema } from '../../ConsentDeposit/ConsentForm';
import DealerInformation from '../../shared/DealerInformation';
import { CustomerFormValues, CustomerFormProps } from '../CustomerForm';
import CarOfInterest from '../Form/CarOfInterest';
import { EventGrid, Space, Title } from '../ui';
import CustomerInformationForm, { schema as customerDetailsSchema } from './Form/CustomerInformationForm';
import TradeInVehicleForm, { vehicleSchema } from './Form/TradeInVehicleForm';

export type PMECustomerFormProps = {
    event: EventDataFragment;
    variant?: VariantDataFragment;
    calculator?: Partial<CalculatorValues>;
    dealerId: string;
    consents?: ConsentDataFragment[];
    referenceId: string | undefined;
    setReferenceId: (id: string) => unknown;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    nextHasTestDrive?: boolean;
    nextHasTradeIn?: boolean;
    appliedForFinancing?: boolean;
    consentStatuses: Record<string, boolean> | undefined;
    valid: boolean;
    hasConsents: boolean;
    showDealerSelection: boolean;
    handleSubmit: SubmitHandler<CustomerFormValues, CustomerFormProps, string>;
    onNormalizeNames: {
        onNormalizeFullName: Normalizer;
        onNormalizeFirstName: Normalizer;
        onNormalizeLastName: Normalizer;
    };
};

const CustomerForm = (props: PMECustomerFormProps) => {
    const {
        valid,
        handleSubmit,
        event,
        variant,
        calculator,
        consents,
        consentStatuses,
        referenceId,
        setReferenceId,
        miniConfiguratorDetails,
        nextHasTradeIn,
        nextHasTestDrive,
        onNormalizeNames,
        dealerId,
        showDealerSelection,
        appliedForFinancing,
    } = props;
    const { t } = useTranslation();
    const isTabletMode = useMedia({ maxWidth: breakpoints.xl });
    const eventSetting = event.setting;

    const { allowTradeIn, allowTestDrive, externalSite } = eventSetting;

    const isPorscheFinderEnabled = externalSite === EventExternalSite.PORSCHEFINDER;
    const isIccCheckoutEnabled = externalSite === EventExternalSite.ICC;
    const isPorscheFinderOrIccCheckout = isPorscheFinderEnabled || isIccCheckoutEnabled;

    const hasConsents = !!consents?.length;

    const renderReservationField = useMemo(() => {
        const hasDepositPayment = !!event?.setting?.isDepositPaymentMandatory;
        const paymentAmount = event?.setting?.bookingPayment?.amount;

        return hasDepositPayment && <ReservationDepositField amount={paymentAmount} channel={Channel.EVENT} />;
    }, [event]);

    return (
        <>
            <EventGrid>
                <Space>
                    <FormSection name="carOfInterest">
                        <CarOfInterest
                            calculator={calculator}
                            dealerId={dealerId}
                            event={event}
                            miniConfiguratorDetails={miniConfiguratorDetails}
                            showDealerSelection={showDealerSelection}
                            variant={variant}
                        />
                    </FormSection>
                    {renderReservationField}
                    {!isPorscheFinderOrIccCheckout && (
                        <FinancingForm
                            allowTestDrive={allowTestDrive}
                            allowTradeIn={allowTradeIn}
                            page="eventDraftPage"
                        />
                    )}
                    <DealerInformation dealerId={dealerId} />
                </Space>
                <div>
                    <FormSection name="customerInfo">
                        <CustomerInformationForm
                            appliedForFinancing={appliedForFinancing}
                            hasTestDrive={nextHasTestDrive}
                            onNormalizeNames={onNormalizeNames}
                        />
                    </FormSection>
                    {isTabletMode && (
                        <>
                            {nextHasTradeIn && <TradeInVehicleForm />}
                            {hasConsents && (
                                <>
                                    <Title>{t('eventConsentDepositPage.consentTitle')}</Title>
                                    <FormSection name="consentDraft">
                                        <ConsentForm
                                            consentStatuses={consentStatuses}
                                            consents={consents!}
                                            referenceId={referenceId}
                                            setReferenceId={setReferenceId}
                                        />
                                    </FormSection>
                                </>
                            )}
                            <Buttons>
                                <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                                    <FontAwesomeIcon icon={faAngleRight} /> {t('eventDraftPage.button.next')}
                                </PrimaryButton>
                            </Buttons>
                        </>
                    )}
                </div>
                {!isTabletMode && (
                    <div>
                        {nextHasTradeIn && <TradeInVehicleForm />}
                        {hasConsents && (
                            <>
                                <Title>{t('eventConsentDepositPage.consentTitle')}</Title>
                                <FormSection name="consentDraft">
                                    <ConsentForm
                                        consentStatuses={consentStatuses}
                                        consents={consents!}
                                        referenceId={referenceId}
                                        setReferenceId={setReferenceId}
                                    />
                                </FormSection>
                            </>
                        )}
                        <Buttons>
                            <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                                <FontAwesomeIcon icon={faAngleRight} /> {t('eventDraftPage.button.next')}
                            </PrimaryButton>
                        </Buttons>
                    </div>
                )}
            </EventGrid>
            <FormError form="customer">
                {(error: FormErrors<{}, string>) => <ErrorMessage>{error}</ErrorMessage>}
            </FormError>
        </>
    );
};

// remove error message for required in events
export const schema = (hasCalculator: boolean, isMarketingReconsent: boolean) => (t: TFunction) => {
    const shape: yup.ObjectSchemaDefinition<any> = {
        consentDraft: consentFormSchema()(t),
        customerInfo: yup.object().shape({
            ...customerDetailsSchema(t),
        }),
        dealerId: yup.string().required(' '),
        tradeIn: vehicleSchema(t),
    };

    if (!hasCalculator && !isMarketingReconsent) {
        shape.carOfInterest = yup.object().shape(
            {
                assetCondition: yup.string().required(' '),
                /** add this validation if variant name not exist */
                modelId: yup
                    .string()
                    .notRequired()
                    .when('variantName', {
                        is: val => val === undefined,
                        then: yup.string().required(' '),
                        otherwise: yup.string().notRequired(),
                    }),
                variantId: yup
                    .string()
                    .notRequired()
                    .when('variantName', {
                        is: val => val === undefined,
                        then: yup.string().required(' '),
                        otherwise: yup.string().notRequired(),
                    }),
                /** add this validation if model id not exist */
                variantName: yup
                    .string()
                    .notRequired()
                    .when('modelId', {
                        is: val => val === undefined,
                        then: yup.string().required(' '),
                        otherwise: yup.string().notRequired(),
                    }),
            },
            // @ts-ignore
            ['variantName', 'modelId']
        );
    }

    return yup.object().shape(shape);
};

export default CustomerForm;
