import { sum } from 'lodash/fp';
import { ApplicationDataFragment } from '../../components/routes/ApplicationRoute/data.graphql';
import { CalculatorValues } from '../../components/shared/calculator-next/types';

export type CalculatorSourceValues = Pick<
    ApplicationDataFragment,
    'calculator' | 'variant' | 'financeProduct' | 'options' | 'promoCode' | 'miniConfiguratorDetails'
>;

const getMiniConfigurationValues = (values?: CalculatorSourceValues['miniConfiguratorDetails']) => {
    if (!values) {
        return 0;
    }

    const equipmentPirce = values?.tequipment
        ? Object.values(values.tequipment).reduce((acc, equipment) => acc + (equipment?.price || 0), 0)
        : 0;

    // does not include timepiece
    return sum([values.delivery.price, values.enhancedPackage?.price, equipmentPirce]);
};

const getCalculatorFromApplication = (values: CalculatorSourceValues): Partial<CalculatorValues> => {
    const { calculator, variant, financeProduct, options, promoCode, miniConfiguratorDetails } = values;

    const coe = calculator.coe?.amount || 0;
    const dealerOptions = calculator.dealerOptions?.amount || 0;
    const ppsr = calculator.ppsr?.amount || 0;
    const nzFee = calculator.nzFee ?? 0;
    const establishment = calculator.establishment?.amount ?? 0;
    const luxuryTax = calculator.luxuryTax?.amount || 0;
    const optionsPrice = options.reduce((acc, item) => acc + item.price, 0);
    const configuratorPrice = getMiniConfigurationValues(miniConfiguratorDetails);
    const totalPrice = [
        calculator.price,
        coe,
        dealerOptions,
        nzFee,
        luxuryTax,
        +optionsPrice,
        configuratorPrice,
    ].reduce<number>((total, value) => total + (value || 0), 0);

    return {
        promoCode: promoCode?.identifier,
        totalPrice,
        financeProduct: financeProduct?.id,
        variant: variant.id,
        carOptions: options.map(option => option.id) || [],
        bank: financeProduct?.bankId,
        balloon: calculator.balloon || undefined,
        downPayment: calculator.downPayment || undefined,
        loan: calculator.loan || undefined,
        carPrice: calculator.price || undefined,
        tenure: calculator.term || undefined,
        interestRate: calculator.interestRate || undefined,
        deposit: calculator.deposit || undefined,
        mileage: calculator.averageMileage || undefined,
        tradeIn: calculator.tradeIn || undefined,
        tradeInEquity: calculator.tradeInEquity || undefined,
        liability: calculator.liability || undefined,
        residualValue: calculator.residualValue || undefined,
        cashPayment: calculator.cashPayment || undefined,
        coe,
        ppsr,
        establishment,
        nzFee,
        luxuryTax,
        paymentMode: calculator.paymentMode || undefined,
        monthlyInstalments: calculator.monthlyInstalments || undefined,
        dealerOptions,
        estimatedSurplus: calculator.estimatedSurplus || undefined,
        licensePlateFee: calculator.licensePlateFee || undefined,
        commission: calculator.commission || undefined,
        fixedInterestRate: calculator.fixedInterestRate || undefined,
        licenseAndFuelTax: calculator.licenseAndFuelTax || undefined,
        displacement: calculator.displacement || undefined,
        insuranceFee: calculator.insuranceFee || undefined,
        taxLoss: calculator.taxLoss || undefined,
        expectedCashPayment: calculator.expectedCashPayment || undefined,
        expectedTradeInAmount: calculator.expectedTradeInAmount || undefined,
    };
};

export default getCalculatorFromApplication;
