import { useTranslation } from 'react-i18next';
import {
    LicenseType,
    Channel,
    ApplicationAccess,
    PreOwnerIdType,
    EventLeadOrigin,
    EventMedium,
    PaymentProviderType,
    AssetCondition,
    PaymentMode,
    EventExternalSite,
    AppointmentStatus,
    AppointmentPurpose,
    CoeVehicleCondition,
} from '../../schema';

export const publishedOptions = [
    { label: 'ON', value: true },
    { label: 'OFF', value: false },
];

export const licenseTypeOptions = [
    {
        label: 'Qualified',
        value: LicenseType.QUALIFIED,
    },
    {
        label: 'Provisional',
        value: LicenseType.PROVISIONAL,
    },
    {
        label: 'Not Applicable',
        value: LicenseType.NOT_APPLICABLE,
    },
];

export const applicationChannelOptions = t => [
    {
        label: t('customerDetailsPage.label.newDealer'),
        channel: Channel.NEW,
        access: ApplicationAccess.PRIVATE,
    },
    {
        label: t('customerDetailsPage.label.newPublic'),
        channel: Channel.NEW,
        access: ApplicationAccess.PUBLIC,
    },
    {
        label: t('customerDetailsPage.label.usedDealer'),
        channel: Channel.USED,
        access: ApplicationAccess.PRIVATE,
    },
    {
        label: t('customerDetailsPage.label.usedPublic'),
        channel: Channel.USED,
        access: ApplicationAccess.PUBLIC,
    },
    {
        label: t('customerDetailsPage.label.eventDealer'),
        channel: Channel.EVENT,
        access: ApplicationAccess.PRIVATE,
    },
    {
        label: t('customerDetailsPage.label.eventPublic'),
        channel: Channel.EVENT,
        access: ApplicationAccess.PUBLIC,
    },
    {
        label: t('customerDetailsPage.label.preOwned'),
        channel: Channel.EXPRESS,
        access: ApplicationAccess.PRIVATE,
    },
];

export const ownerIdOptions = [
    {
        label: 'Business (e.g. 51234567M)',
        value: PreOwnerIdType.BUSINESS,
    },
    {
        label: 'Club/Association/Organisation (e.g. T08PQ1234A)',
        value: PreOwnerIdType.CLUBORASSOCIATIONORORGANIZATION,
    },
    {
        label: 'Company (e.g. 198912345K)',
        value: PreOwnerIdType.COMPANY,
    },
    {
        label: 'Foreign Company (e.g. T08FC1234A)',
        value: PreOwnerIdType.FOREIGNCOMPANY,
    },
    {
        label: 'Foreign Identification Number (e.g. F/G1234567N)',
        value: PreOwnerIdType.FOREIGNIDENTIFICATIONNUMBER,
    },
    {
        label: 'Foreign Passport (e.g. 12345678)',
        value: PreOwnerIdType.FOREIGNPASSPORT,
    },
    {
        label: 'Government (e.g. T08GA1234A)',
        value: PreOwnerIdType.GOVERNMENT,
    },
    {
        label: 'Limited Liability Partnership (e.g. T08LL1234A)',
        value: PreOwnerIdType.LIMITEDLIABILITYPARTNERSHIP,
    },
    {
        label: 'Limited Partnership (e.g. T08LP1234A)',
        value: PreOwnerIdType.LIMITEDPARTNERSHIP,
    },
    {
        label: 'Malaysia NRIC (e.g. 200312345678)',
        value: PreOwnerIdType.MALAYSIANRIC,
    },
    {
        label: 'Professional (e.g. T08PQ1234A)',
        value: PreOwnerIdType.PROFESSIONAL,
    },
    {
        label: 'Singapore NRIC (e.g. S1234567D)',
        value: PreOwnerIdType.SINGAPORENRIC,
    },
    {
        label: 'Statutory Board (e.g. T08GB1234A)',
        value: PreOwnerIdType.STATUTORYBOARD,
    },
];

export const assetConditionOptions = [
    { value: AssetCondition.DEMO, label: 'Demo' },
    { value: AssetCondition.NEW, label: 'New' },
    { value: AssetCondition.USED, label: 'Preowned' },
];

export const usePaymentModeOptions = () => {
    const { t } = useTranslation();

    return [
        { value: PaymentMode.ADVANCE, label: t('calculator.label.modeOfPayment.options.advance') },
        { value: PaymentMode.ARREARS, label: t('calculator.label.modeOfPayment.options.arrears') },
    ];
};

export const yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

// event form

export const eventLeadOriginOptions = [
    { label: 'Internet', value: EventLeadOrigin.INTERNET },
    { label: 'Dealer', value: EventLeadOrigin.DEALER },
];

export const eventMediumOptions = [
    { label: 'Walk-in (Showroom)', value: EventMedium.WALKIN },
    { label: 'Event', value: EventMedium.EVENT },
    { label: 'Internet', value: EventMedium.INTERNET },
];

export const eventExternalSiteOptions = [
    { label: 'None', value: EventExternalSite.NONE },
    { label: 'Porsche Finder', value: EventExternalSite.PORSCHEFINDER },
    { label: 'Configurator', value: EventExternalSite.CONFIGURATOR },
    { label: 'Marketing Reconsent', value: EventExternalSite.MARKETINGRECONSENT },
    { label: 'ICC', value: EventExternalSite.ICC },
];

export const paymentProviderOptions = [
    { label: 'Adyen', value: PaymentProviderType.ADYEN },
    { label: 'Porsche Payment Widget', value: PaymentProviderType.PORSCHE },
];

export const settlementInstalmentOnOptions = [
    { label: '2 months before last instalment', value: 3 },
    { label: '1 month before last instalment', value: 2 },
    { label: 'Last instalment', value: 1 },
];

export const appointmentStatusOptions = [
    { label: 'New', value: AppointmentStatus.NEW },
    { label: 'Contacted', value: AppointmentStatus.CONTACTED },
    { label: 'Check-in', value: AppointmentStatus.CHECKIN },
    { label: 'Completed', value: AppointmentStatus.COMPLETED },
    { label: 'Void', value: AppointmentStatus.VOID },
];

export const appointmentPurposeOptions = [{ label: 'Test Drive', value: AppointmentPurpose.TESTDRIVE }];

export const applyCoeVehicleConditionOptions = [
    { label: 'All', value: CoeVehicleCondition.ALL },
    { label: 'New', value: CoeVehicleCondition.NEW },
    { label: 'Preowned', value: CoeVehicleCondition.PREOWNED },
];
