import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxFormContext, Normalizer } from 'redux-form';
import * as yup from 'yup';
import DateField from '../../../../../../components/shared/form-v2/DateField';
import IdentityNumberField from '../../../../../../components/shared/form-v2/IdentityNumberField';
import SelectField from '../../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../../components/shared/form-v2/TextField';
import useCustomerSource from '../../../../../../components/utilities/useCustomerSource';
import usePhone from '../../../../../../components/utilities/usePhone';
import { getRuntimeSettings } from '../../../../../../selectors';
import useOptions from '../../../../../../utilities/constants/useOptions';
import { getMinDateOfBirth } from '../../../../../../utilities/dates';
import {
    fromContextValidation,
    onTelKeyPress,
    onTextKeyPress,
    validateTextFieldOnly,
    yupExt,
} from '../../../../../../utilities/forms';
import { Title } from '../../ui';

const watchedFields = ['title', 'name', 'firstName', 'lastName', 'dateOfBirth', 'identityNumber', 'email', 'phone'];

type CustomerInformationFormProps = {
    disabled?: boolean;
    onNormalizeNames: {
        onNormalizeFirstName: Normalizer;
        onNormalizeLastName: Normalizer;
    };
    hasTestDrive?: boolean;
    appliedForFinancing?: boolean;
};

const CustomerInformationForm = ({
    disabled = false,
    onNormalizeNames: { onNormalizeFirstName, onNormalizeLastName },
    appliedForFinancing = false,
}: CustomerInformationFormProps) => {
    const { t } = useTranslation();
    const { useCustomerBirthDay } = useSelector(getRuntimeSettings);
    const { sectionPrefix }: any = useContext(ReduxFormContext);
    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    const minDateOfBirth = useMemo(() => getMinDateOfBirth(), []);
    const { code: phoneCountryCode } = usePhone();

    const { title } = useOptions();

    return (
        <>
            <Title>{t('eventCustomerDetails.title')}</Title>
            <SelectField.Outline
                disabled={disabled || fieldState.title}
                label={`${t('eventCustomerDetails.label.title')}*`}
                name="title.value"
                options={title}
            />
            <TextField
                disabled={disabled || fieldState.firstName}
                label={t('eventCustomerDetails.label.firstName')}
                name="firstName.value"
                normalize={onNormalizeFirstName}
                onKeyPress={onTextKeyPress}
            />
            <TextField
                disabled={disabled || fieldState.lastName}
                label={t('eventCustomerDetails.label.lastName')}
                name="lastName.value"
                normalize={onNormalizeLastName}
                onKeyPress={onTextKeyPress}
            />
            {useCustomerBirthDay && (
                <DateField
                    disabled={disabled || fieldState.dateOfBirth}
                    label={`${t('eventCustomerDetails.label.dateOfBirth')}*`}
                    maxDate={minDateOfBirth}
                    name="dateOfBirth.value"
                />
            )}
            <TextField
                disabled={disabled || fieldState.email}
                label={t('eventCustomerDetails.label.email')}
                name="email.value"
                type="email"
            />
            <TextField
                label={`${t('customerDetails.label.mobile')}*`}
                maxLength="10"
                name="phone.value"
                onKeyPress={onTelKeyPress}
                prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                type="tel"
            />
            {appliedForFinancing && (
                <IdentityNumberField
                    disabled={disabled || fieldState.identityNumber}
                    label={`${t('eventCustomerDetails.label.identityNumberFinder')}*`}
                    maxLength="200"
                    name="identityNumber.value"
                    placeholder={t('customerDetails.placeholder.identityNumber')}
                />
            )}
        </>
    );
};

export default CustomerInformationForm;

export const schema = (t: TFunction) => ({
    // @ts-ignore
    dateOfBirth: yup.lazy((values, options) => {
        const shouldValidate = get(['context', 'useCustomerBirthDay'], options);

        if (shouldValidate) {
            return yupExt.customerProperty().shape({
                value: yup.string().required(t('common.error.required')),
            });
        }

        return yup.mixed().notRequired();
    }),
    email: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .email(t('common.error.email'))
            .max(320, t('common.error.lessThanOrEqual', { max: 320 })),
    }),
    firstName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .test(
                'invalid-text-input',
                t('common.error.alphabet'),
                (value?: string | null) => !value || validateTextFieldOnly(value)
            )
            .max(66, t('common.error.lessThanOrEqual', { max: 66 })),
    }),
    lastName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .test(
                'invalid-text-input',
                t('common.error.alphabet'),
                (value?: string | null) => !value || validateTextFieldOnly(value)
            )
            .max(66, t('common.error.lessThanOrEqual', { max: 66 })),
    }),
    phone: yupExt.customerProperty().shape({
        value: fromContextValidation(
            yup.string().required(t('common.error.required')),
            'phonePattern',
            t('common.error.mobile')
        ),
    }),
    title: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),

    // @ts-ignore
    identityNumber: yup.lazy((values: any, options: any) => {
        const { context } = options;
        if (context?.appliedForFinancing) {
            return yupExt.customerProperty().shape({
                value: yup.string().required(t('common.error.required')),
            });
        }

        return yup.mixed().notRequired();
    }),
});
