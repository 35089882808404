/* eslint-disable no-underscore-dangle */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { History } from 'history';
import { i18n, TFunction } from 'i18next';
import { ThunkDispatch } from 'redux-thunk';
import { ConsentDataFragment } from '../../api/consents.graphql';
import { FinanceDataFragment } from '../../components/data/useFinanceProducts.graphql';
import { PromoDataFragment } from '../../components/data/useLoadPromo.graphql';
import { getAydenSession } from '../../components/routes/wip/AydenCallback';
import { getMyInfoSession } from '../../components/routes/wip/MyInfoCallback';
import { getPayGatePaymentSession } from '../../components/routes/wip/PayGatePaymentCallback';
import { getPorschePaymentSession } from '../../components/routes/wip/PorschePaymentCallback';
import { ContentTranslation } from '../../i18n';
import { ApplicationFlowSource, BankSigningMode, Channel, PaymentProviderType } from '../../schema';
import { Application, Bank, Company, Country, Zone } from '../DraftFlow';
import { getFinancingConsents, getNonFinancingConsents } from '../DraftFlow/utils/consents';
import { Flow, FlowStep } from '../utils/flow';
import * as flowSteps from './steps';

export type RemoteFlowState = {
    // channel
    channel: Channel;
    // company document (mandatory)
    company: Company;
    // bank document (provided after submitting the calculator)
    bank?: Bank;
    // finance product document (provided after submitting the calculator)
    financeProduct?: FinanceDataFragment | null;
    // application document (provided after submitting a draft)
    application: Application;
    // promotion code document (optional)
    promo?: PromoDataFragment | null;
    // country document (mandatory)
    country: Country;
    // zone document (mandatory)
    zone: Zone;
    // token (provided after draft creation)
    token: string;
    // payment result (provided once payment is done)
    paymentResult?: any;
    // proceeding with my info errors (if MyInfo step failed)
    withMyInfoError?: boolean;
    // source flow
    source: ApplicationFlowSource;
    consents?: ConsentDataFragment[];
    // if the application is submitted already
    submitted?: boolean;
};

export type RemoteFlowStep = FlowStep<RemoteFlowState>;

class RemoteFlow extends Flow<RemoteFlowState> {
    private initialized?: Application['steps'];

    constructor(
        initialState: RemoteFlowState,
        pubSubId: string,
        dispatch: ThunkDispatch<any, any, any>,
        apolloClient: ApolloClient<NormalizedCacheObject>,
        history: History,
        t: TFunction,
        i18n: i18n,
        contentTranslation: ContentTranslation
    ) {
        super(initialState, pubSubId, dispatch, apolloClient, history, t, i18n, contentTranslation);
        // because we define initialized as a private property
        // if we don't initialize it in the constructor it will be automatically set back to undefined
        // TS specs says we can use "declare" to only define the that rather than the property itself
        // however CRA has an opened issue on the topic and didn't update the babel configuration of yet
        // therefor we are forced to do this ugly thing...
        this.initialized = initialState.application.steps;
    }

    public get channelSetting() {
        const { channel, country } = this.state;

        switch (channel) {
            case Channel.EXPRESS:
                return country.channelSetting.express;

            case Channel.USED:
                return country.channelSetting.used;

            case Channel.NEW:
                return country.channelSetting.new;

            default:
                throw new Error('Not implemented');
        }
    }

    public get applyForFinancing() {
        const { application } = this.state;
        const { channelSetting } = this;

        return Boolean(
            application?.appliedForFinancing ||
                (channelSetting.allowFinanceApplication && channelSetting.isFinanceApplicationMandatory)
        );
    }

    protected initialize(): FlowStep<RemoteFlowState> {
        const { application } = this.state;

        // setup initialized state
        this.initialized = application.steps;

        if (application) {
            const myInfo = getMyInfoSession(application.id, ApplicationFlowSource.REMOTE);

            if (myInfo) {
                const step = this.getStep(myInfo.step);

                if (step) {
                    return step;
                }
            }

            if (this.getPaymentSession(application.version.id)) {
                const step = this.getStep('deposit');

                if (step) {
                    return step;
                }
            }

            for (const step of this.steps) {
                if (!step.isShadowStep && !step.isCompleted) {
                    return step;
                }
            }
        }

        return super.initialize();
    }

    private getPaymentStep() {
        const { channelSetting } = this;

        switch (channelSetting.bookingPayment?.provider.type) {
            case PaymentProviderType.ADYEN:
                return flowSteps.DepositStep;

            case PaymentProviderType.PORSCHE:
                return flowSteps.PorscheDepositStep;

            case PaymentProviderType.PAYGATE:
                return flowSteps.PayGateDepositStep;

            default:
                throw new Error('Payment Provider Type not supported');
        }
    }

    private getPaymentSession(id: string) {
        const { channelSetting } = this;

        switch (channelSetting.bookingPayment?.provider.type) {
            case PaymentProviderType.ADYEN:
                return getAydenSession(id, ApplicationFlowSource.REMOTE);

            case PaymentProviderType.PORSCHE:
                return getPorschePaymentSession(id, ApplicationFlowSource.REMOTE);

            case PaymentProviderType.PAYGATE:
                return getPayGatePaymentSession(id, ApplicationFlowSource.REMOTE);

            default:
                return null;
        }
    }

    protected plannify(): FlowStep<RemoteFlowState>[] {
        if (this.state.submitted) {
            // only show submitted step
            return [this.createStep(flowSteps.SubmitStep)];
        }

        // there's always the calculator and draft step
        const steps: FlowStep<RemoteFlowState>[] = [this.createStep(flowSteps.PreviewStep)];

        const { bank, application, consents, channel } = this.state;

        if (!this.initialized) {
            this.initialized = application.steps;
        }

        const { applyForFinancing, initialized, channelSetting } = this;
        const initialSubmission = !initialized?.submission;

        if (initialSubmission && applyForFinancing && !initialized?.kyc) {
            if (bank?.isKYCMandatory) {
                if (bank.myInfo) {
                    // there's my info integration
                    steps.push(this.createStep(flowSteps.MyInfoStep));
                }

                // we need to collect KYC fields
                steps.push(this.createStep(flowSteps.KYCStep));
            }
        }

        const financingConsents = getFinancingConsents(consents, channel);
        const nonFinancingConsents = getNonFinancingConsents(consents, channel);
        // show separate consent step when there is no KYC
        if (
            initialSubmission &&
            !initialized?.consentsAndDeclarations &&
            ((applyForFinancing && financingConsents.length && !bank?.isKYCMandatory) ||
                (!applyForFinancing && nonFinancingConsents.length))
        ) {
            steps.push(this.createStep(flowSteps.ConsentStep));
        }

        if (initialSubmission && channelSetting.isDepositPaymentMandatory) {
            steps.push(this.createStep(this.getPaymentStep()));
        }

        if (applyForFinancing && bank) {
            switch (initialSubmission ? bank.signing.onCreate : bank.signing.onUpdate) {
                case BankSigningMode.NAMIRIAL:
                    steps.push(this.createStep(flowSteps.NamirialStep));
                    break;

                case BankSigningMode.OTP:
                    steps.push(this.createStep(flowSteps.OTPStep));
                    break;

                case BankSigningMode.NONE:
                default:
                    // do nothing
                    break;
            }
        }

        return [...steps, this.createStep(flowSteps.SubmitStep)];
    }
}

export default RemoteFlow;
