import { useQuery } from '@apollo/client';
import { get, isEmpty, isNil, last } from 'lodash/fp';
import React, { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router';
import {
    getApplicationByToken,
    GetApplicationByTokenQuery,
    GetApplicationByTokenQueryVariables,
} from '../../../api/application.graphql';
import { useCountry } from '../../../hookSelectors';
import { CoeVehicleCondition, EventExternalSite, FinderVehicleBookingType, FinderVehicleStatus } from '../../../schema';
import { getCalculatorFromApplication } from '../../../utilities/application';
import { getBank, GetBankQuery, GetBankQueryVariables, BankDataFragment } from '../../data/useLoadBank.graphql';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import { CalculatorValues } from '../../shared/calculator-next/types';
import { ApplicationDataFragment } from '../ApplicationRoute/data.graphql';
import Error404Suggestion from '../Error404Suggestion';
import { EventDataFragment } from '../EventRoute/EventRoute.graphql';
import { FinderProvider } from './FinderContext';
import {
    GetDataQuery,
    GetDataQueryVariables,
    getData,
    FinderVehicleDataFragment,
    getFinderVehicleBooking,
    GetFinderVehicleBookingQuery,
    GetFinderVehicleBookingQueryVariables,
} from './data.graphql';

type RouterParams = { listingId: string };

export type FinderBootstrapProps = {
    children: (context: {
        dealerId: string;
        event: EventDataFragment;
        isListingSame: boolean;
        isExpired: boolean;
        finderVehicle?: FinderVehicleDataFragment;
        application?: GetApplicationByTokenQuery['application'];
        calculator?: Partial<CalculatorValues>;
        variant?: VariantDataFragment;
        bank?: BankDataFragment;
    }) => ReactElement;
    token?: string;
    bookingId?: string;
    reservation?: ApplicationDataFragment;
};

const isFinderPurposeSame = (event: EventDataFragment, finderVehicle: FinderVehicleDataFragment, code: string) => {
    const { setting } = event;
    const { listing } = finderVehicle;

    // By pass for other than SG
    if (setting.coeVehicleCondition === CoeVehicleCondition.ALL) {
        return true;
    }

    if (
        (setting.coeVehicleCondition === CoeVehicleCondition.NEW &&
            listing?.vehicle.condition?.value?.toLowerCase() === 'new') ||
        (setting.coeVehicleCondition === CoeVehicleCondition.PREOWNED &&
            listing?.vehicle.condition?.value?.toLowerCase() !== 'new')
    ) {
        return true;
    }

    return false;
};

const FinderBootstrap = ({ children, token, bookingId, reservation }: FinderBootstrapProps) => {
    const { listingId } = useParams<RouterParams>();
    const { id: countryId, channelSetting, code } = useCountry();
    const { isActive } = channelSetting.event;

    // fetch porsche finder events
    // and finder vehicle
    const { data, loading } = useQuery<GetDataQuery, GetDataQueryVariables>(getData, {
        variables: {
            externalSite: EventExternalSite.PORSCHEFINDER,
            activeOnly: true,
            countryId,
            listingId,
        },
        fetchPolicy: 'no-cache',
    });

    // find event for respective dealer
    const [event, dealer] = useMemo(() => {
        // no data retrieved yet
        if (!data) {
            return [null, null];
        }

        const { finderVehicle, events } = data;

        // there's no listing data
        if (!finderVehicle?.listing) {
            return [null, null];
        }

        const { listing } = finderVehicle;
        const partnerNumber = listing.seller.porschePartnerNumber;

        // find event from dealer, with same condition
        const eventFound = events.items.find(
            item =>
                item.dealers &&
                item.dealers.find(dealer => partnerNumber === dealer.partnerNumber) &&
                isFinderPurposeSame(item, finderVehicle, code)
        );

        return [eventFound, eventFound?.dealers?.find(dealer => partnerNumber === dealer.partnerNumber)];
    }, [code, data]);

    // fetch application data
    const { data: applicationData, loading: applicationLoading } = useQuery<
        GetApplicationByTokenQuery,
        GetApplicationByTokenQueryVariables
    >(getApplicationByToken, {
        fetchPolicy: 'network-only',
        variables: { token: token as string },
        skip: isEmpty(token),
    });

    const { data: bookingData, loading: bookingLoading } = useQuery<
        GetFinderVehicleBookingQuery,
        GetFinderVehicleBookingQueryVariables
    >(getFinderVehicleBooking, {
        fetchPolicy: 'network-only',
        variables: { bookingId: bookingId as string },
        skip: isEmpty(bookingId),
    });

    const { data: bankData, loading: bankLoading } = useQuery<GetBankQuery, GetBankQueryVariables>(getBank, {
        fetchPolicy: 'cache-first',
        variables: { bankId: reservation?.bank?.id as string },
        skip: isEmpty(reservation?.bank?.id),
    });

    const usedApplication = useMemo(() => {
        if (applicationData?.application) {
            return applicationData.application;
        }

        if (reservation) {
            return {
                ...reservation,
                appliedForFinancing: true,
            };
        }

        return undefined;
    }, [reservation, applicationData]);

    const { calculator, variant } = useMemo(() => {
        if (!usedApplication) {
            return {};
        }

        return {
            calculator: getCalculatorFromApplication(usedApplication),
            variant: {
                ...usedApplication.variant,
                images: [
                    {
                        id: get('listing.vehicle.images.edges[0].node.id', data?.finderVehicle),
                        url: last(data?.finderVehicle?.listing?.vehicle.images.edges[0].node.variants)?.url,
                        metaData: { filename: '' },
                    },
                ],
            },
        };
    }, [usedApplication, data]);

    const isLoading = loading || applicationLoading || bookingLoading || bankLoading;

    if (isLoading) {
        // wait for loading to be over
        return null;
    }

    const isEventExpired = event?.period?.end && event.period.end < new Date();
    // events is not active or not valid
    if (!isActive || !event?.isActive || isNil(dealer) || isEventExpired) {
        return <Error404Suggestion header={false} />;
    }

    const finderVehicle = data?.finderVehicle;
    // there's no finder vehicle setup yet
    if (!finderVehicle || !finderVehicle?.setting || finderVehicle.setting.isHidden) {
        return <Error404Suggestion header={false} />;
    }

    const booking = bookingData?.booking;
    const isListingSame = booking?.listingId === finderVehicle.id;
    const isExpired = booking?.bookingType === FinderVehicleBookingType.DELETED;

    // current booking is already submitted
    // exception: when approval step = true (namirial), while booking type is completed
    // need to show the thank you page
    if (
        isListingSame &&
        booking?.bookingType === FinderVehicleBookingType.COMPLETED &&
        !usedApplication?.steps?.approval
    ) {
        return <Error404Suggestion header={false} />;
    }

    // new vehicle is no longer available for reservation
    if (!isListingSame && finderVehicle.status !== FinderVehicleStatus.AVAILABLE) {
        return <Error404Suggestion header={false} />;
    }

    // Throw error when reservation is present, but bank data is not loaded
    if (reservation && !bankData?.bank) {
        return <Error404Suggestion header={false} />;
    }

    const context = {
        dealerId: dealer?.id,
        event,
        isListingSame,
        isExpired,
        finderVehicle,
        application: usedApplication,
        variant,
        calculator,
        bank: bankData?.bank as BankDataFragment,
    };

    return <FinderProvider finderVehicle={finderVehicle}>{children(context)}</FinderProvider>;
};

export default FinderBootstrap;
