// @ts-ignore
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { flow, get, isEmpty, pick } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormErrors, FormSection, getFormSyncErrors, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import * as yup from 'yup';
import { ObjectSchemaDefinition } from 'yup';
import { ConsentDataFragment } from '../../../../../api/consents.graphql';
import { ApplicationCustomerDataFragment } from '../../../../../components/routes/ApplicationRoute/data.graphql';
import FormError from '../../../../../components/shared/form/FormError';
import useCustomerNamesSynchronization from '../../../../../components/shared/useCustomerNamesSynchronization';
import { Error as ErrorMessage } from '../../../../../components/ui/form/FormActions';
import { Formats } from '../../../../../components/utilities/useCompanyFormatting';
import useFormValues from '../../../../../components/utilities/useFormValues';
import { BankPresetOption, UploadPurpose } from '../../../../../schema';
import { AddressType } from '../../../../../utilities/constants/optionTypes';
import { fromContextValidation, yupExt } from '../../../../../utilities/forms';
import { FileInput } from '../../../../utils/uploads';
import { Application } from '../../../types';

import { Buttons, PrimaryButton, SecondaryButton } from '../../Calculator/ui';
import DraftModalProvider from '../../DraftPage/DraftModalProvider';
import useSaveDraftValidation from '../AFC/useSaveDraftValidation';
import { PageTitle, Title } from '../ui';
import { ConsentForm, schema as consentFormSchema } from './ConsentForm';
import ContactDetails, { contactPMESASchema, contactSchema } from './ContactDetails';
import CustomerInformationForm, {
    rootSchema as customerRootSchema,
    detailsSchema as customerDetailsSchema,
} from './CustomerDetails';
import IdentityDetails, { detailsSchema as identityDetailsSchema, identityEnbdSchema } from './IdentityDetails';
import OccupationForm, { occupationEnbdSchema, occupationFilesSchema, occupationSchema } from './OccupationDetails';
import QuotationDetails, { quotationSchema } from './QuotationDetails';
import ReferenceDetails, { rootSchema as referenceRootSchema } from './ReferenceDetails';
import { filesSchema } from './validateFile';

export type PageProps = {
    apply: (values: any, validate: (values: any) => void) => Promise<any>;
    useCustomerBirthDay: boolean;
    useCustomerNationality: boolean;
    initialValues: any;
    validation: any;
    application: Application;
    consents: ConsentDataFragment[];
    onSaveDraft: (values: CustomerFormValues) => Promise<void>;
    companyFormats: Formats;
};

export type CustomerFormValues = {
    customerInfo: Partial<ApplicationCustomerDataFragment> & {
        files?: FileInput[];
        occupationFiles?: FileInput[];
        identityFiles?: FileInput[];
    };
    consentDraft?: { consents: Record<string, boolean>; referenceId: undefined };
};

const CustomerForm = (props: PageProps & InjectedFormProps<CustomerFormValues, PageProps>) => {
    const { valid, handleSubmit, change, consents, application, form, onSaveDraft } = props;
    const { t } = useTranslation();
    const { consentDraft } = useFormValues<CustomerFormValues>();
    const values = useFormValues<CustomerFormValues>();
    const { consents: consentStatuses } = consentDraft ?? {};
    // update choices for test drive and trade in if checkboxes are in current page
    const onNormalizeNames = useCustomerNamesSynchronization('customerInfo');
    const hasConsents = !!consents?.length;

    // only save draft only when not proceed with customer
    // and has not submitted kyc yet
    const hasSaveDraft = useMemo(() => !application.proceedWithCustomerDevice && !application.steps?.kyc, [
        application,
    ]);

    const errors = useSelector(getFormSyncErrors(form));

    const validate = useSaveDraftValidation(saveDraftSchema, application);

    const canSaveDraft = flow([get('consentDraft'), isEmpty])(errors);
    const bankPresetOption = application?.financeProduct?.bank?.presetOption;

    return (
        <DraftModalProvider change={change} handleSubmit={() => onSaveDraft(values)}>
            {setDraftModal => (
                <>
                    <PageTitle>{t('kycPage.applicantDetailsTitle')}</PageTitle>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <div className="row mb-5">
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <FormSection name="customerInfo">
                                        <CustomerInformationForm
                                            bankPresetOption={bankPresetOption}
                                            onNormalizeNames={onNormalizeNames}
                                        />
                                    </FormSection>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <FormSection name="customerInfo">
                                        <div className="mb-5">
                                            <OccupationForm bankPresetOption={bankPresetOption} />
                                        </div>
                                    </FormSection>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <FormSection name="customerInfo">
                                        <div className="mb-5">
                                            <IdentityDetails bankPresetOption={bankPresetOption} />
                                        </div>
                                        {bankPresetOption === BankPresetOption.ENBDBANK && (
                                            <QuotationDetails application={application} change={change} />
                                        )}
                                    </FormSection>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <FormSection name="customerInfo">
                                        <div className="mb-5">
                                            <ContactDetails bankPresetOption={bankPresetOption} change={change} />
                                        </div>
                                        <ReferenceDetails />
                                    </FormSection>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            {hasConsents && (
                                <FormSection name="consentDraft">
                                    <Title>{t('consentPage.title')}</Title>
                                    <ConsentForm
                                        change={change}
                                        consentStatuses={consentStatuses}
                                        consents={consents}
                                    />
                                </FormSection>
                            )}
                            <Buttons>
                                <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                                    <FontAwesomeIcon icon={faAngleRight} /> {t('eventDraftPage.button.next')}
                                </PrimaryButton>
                                {hasSaveDraft && (
                                    <SecondaryButton
                                        disabled={!canSaveDraft}
                                        onClick={() => {
                                            if (validate()) {
                                                setDraftModal(true);
                                            }
                                        }}
                                        type="button"
                                    >
                                        <FontAwesomeIcon icon={faAngleRight} /> {t('kycPage.button.save')}
                                    </SecondaryButton>
                                )}
                            </Buttons>
                        </div>
                    </div>
                    <FormError form="customer">
                        {(error: FormErrors<{}, string>) => <ErrorMessage>{error}</ErrorMessage>}
                    </FormError>
                </>
            )}
        </DraftModalProvider>
    );
};

// remove error message for required in events
export const schema = () => (t: TFunction) => {
    const shape: ObjectSchemaDefinition<any> = {
        consentDraft: consentFormSchema(t),
        // @ts-ignore
        customerInfo: yup.lazy((value, options) => {
            const isEnbdBank =
                get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

            if (isEnbdBank) {
                return yupExt.customerProperty().shape({
                    ...pick(['title', 'email', 'phone', 'dateOfBirth', 'firstName', 'lastName'], customerRootSchema(t)),
                    details: yupExt.customerProperty().shape({
                        ...quotationSchema(t),
                    }),
                });
            }

            return yupExt.customerProperty().shape({
                ...customerRootSchema(t),
                details: yupExt.customerProperty().shape({
                    ...customerDetailsSchema(t),
                    ...occupationSchema(t),
                    ...identityDetailsSchema(t),
                    ...contactSchema(t),
                    ...referenceRootSchema(t),
                    thirdParty: yupExt
                        .customerProperty()
                        .shape({
                            // @ts-ignore
                            enbdBank: yup.lazy((value, options) => {
                                const isEnbdBankPreset =
                                    get('context.application.financeProduct.bank.presetOption', options) ===
                                    BankPresetOption.ENBDBANK;

                                if (isEnbdBankPreset) {
                                    return yupExt.customerProperty().shape({
                                        ...identityEnbdSchema(t),
                                        ...occupationEnbdSchema(t),
                                    });
                                }

                                return yupExt.customerProperty().nullable();
                            }),
                            // @ts-ignore
                            pmeSA: yup.lazy((value, options) => {
                                const isPMESAPreset =
                                    get('context.application.financeProduct.bank.presetOption', options) ===
                                    BankPresetOption.PMESA;

                                if (isPMESAPreset) {
                                    return yupExt.customerProperty().shape({
                                        ...contactPMESASchema(t),
                                    });
                                }

                                return yupExt.customerProperty().shape({});
                            }),
                        })
                        .nullable(),
                }),
                identityFiles: filesSchema({ min: 1, max: 5 }, UploadPurpose.CUSTOMER_IDENTITY, t),
                occupationFiles: occupationFilesSchema(t),
            });
        }),
    };

    return yup.object().shape(shape);
};

const saveDraftSchema = (t: TFunction) => {
    const shape: ObjectSchemaDefinition<any> = {
        // @ts-ignore
        customerInfo: yup.lazy((value, options) => {
            const isEnbdBank =
                get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

            if (isEnbdBank) {
                return yupExt.customerProperty().shape({
                    ...pick(['title', 'email', 'phone', 'dateOfBirth', 'firstName', 'lastName'], customerRootSchema(t)),
                    details: yupExt.customerProperty().shape({
                        // as for draft, only validate the options
                        ...pick(['options'], quotationSchema(t)),
                    }),
                });
            }

            return yupExt.customerProperty().shape({
                ...pick(['title', 'email', 'phone', 'dateOfBirth', 'firstName', 'lastName'], customerRootSchema(t)),
                details: yupExt.customerProperty().shape({
                    ...pick(['nationality'], customerDetailsSchema(t)),
                    drivingLicense: yup
                        .array()
                        .of(
                            yup.lazy(value => {
                                const licenseSchema = {
                                    number: yup
                                        .string()
                                        .nullable()
                                        .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
                                };

                                return yup.object().shape(licenseSchema);
                            })
                        )
                        .nullable()
                        .required(),
                    employment: yupExt.customerProperty().shape({
                        bankAccountNumber: yupExt.customerProperty().shape({
                            value: yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 })),
                        }),
                        designation: yupExt.customerProperty().shape({
                            value: yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 })),
                        }),
                        employer: yupExt.customerProperty().shape({
                            value: yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 })),
                        }),
                        monthlyIncome: yupExt.customerProperty().shape({
                            value: yup
                                .string()
                                .max(200, t('common.error.lessThanOrEqual', { max: 200 }))
                                .nullable(),
                        }),
                        totalWorkExperience: yupExt.customerProperty().shape({
                            value: yup.number().max(200, t('common.error.lessThanOrEqual', { max: 200 })),
                        }),
                    }),
                    homeCountryAddress: yupExt.customerProperty().shape({
                        phone: fromContextValidation(
                            yup
                                .string()
                                .notRequired()
                                .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
                            'phonePattern',
                            t('common.error.mobile'),
                            true
                        ),
                    }),
                    office: yup
                        .object()
                        .when('addressType', {
                            is: addressType => addressType?.value === AddressType.OFFICE,
                            then: yupExt.customerProperty().shape({
                                phone: fromContextValidation(
                                    yup.string().max(10, t('common.error.lessThanOrEqual', { max: 10 })),
                                    'phonePattern',
                                    t('common.error.mobile'),
                                    true
                                ),
                            }),
                        })
                        .nullable(),
                    passport: yupExt.customerProperty().shape({
                        number: yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 })),
                    }),
                    reference: yupExt.customerProperty().shape({
                        name: yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 })),
                        phone: fromContextValidation(
                            yup.string().max(10, t('common.error.lessThanOrEqual', { max: 10 })),
                            'phonePattern',
                            t('common.error.mobile'),
                            true
                        ),
                    }),
                }),
                identityFiles: filesSchema({ min: 0, max: 5 }, UploadPurpose.CUSTOMER_IDENTITY, t),
                occupationFiles: filesSchema({ min: 0, max: 5 }, UploadPurpose.CUSTOMER_ATTACHED, t),
            });
        }),
    };

    return yup.object().shape(shape);
};

export default reduxForm<CustomerFormValues, PageProps>({
    form: 'customer',
    // @ts-ignore
    onSubmit: (values, dispatch, props) => {
        const { validate, apply } = props;

        // @ts-ignore
        return apply({ ...values }, nextValues => {
            if (!validate) {
                return;
            }
            const errors = validate(nextValues, props);
            if (!isEmpty(errors)) {
                throw new SubmissionError(errors);
            }
        });
    },
})(CustomerForm);
