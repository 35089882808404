import React from 'react';
import {
    completeAppointment,
    CompleteAppointmentMutation,
    CompleteAppointmentMutationVariables,
} from '../../../api/draft.graphql';
import { ReduxFormFlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';
import AppointmentFlowPage, { AppointmentStepValues } from '../components/AppointmentFlowPage';

class EventAppointmentStep extends ReduxFormFlowStep<EventFlowState, AppointmentStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'appointment';
    }

    public get isCompleted(): boolean {
        const { application } = this.state;

        return !!application?.steps?.reservationToFinance || application?.steps?.appointment || false;
    }

    protected async execute(values: AppointmentStepValues) {
        const { apolloClient } = this;
        const { token } = this.state;

        if (!token) {
            throw new Error('Token missing in state');
        }

        const response = await apolloClient.mutate<CompleteAppointmentMutation, CompleteAppointmentMutationVariables>({
            mutation: completeAppointment,
            variables: { token, data: { appointmentDate: values.appointmentDate } },
        });

        this.stateStore.update({ ...response.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const {
            variant,
            event,
            channel,
            calculator,
            carOfInterest,
            miniConfiguratorDetails,
            source,
            dealerId,
            zone,
        } = this.state;
        if (!event) {
            throw new Error('Event missing in state');
        }

        if (!dealerId) {
            throw new Error('Dealer id missing in state');
        }

        return (
            <AppointmentFlowPage
                backStep={this.getBackContext()}
                calculator={calculator}
                carOfInterest={carOfInterest}
                channel={channel}
                dealerId={dealerId}
                event={event}
                isLastStep={this.isLastStep}
                miniConfiguratorDetails={miniConfiguratorDetails}
                onSubmit={this.submit}
                source={source}
                variant={variant}
                zone={zone}
            />
        );
    }
}

export default EventAppointmentStep;
