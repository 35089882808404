import { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { loadRelationships } from '../../../../../actions';
import { getRelationships } from '../../../../../selectors';
import { fromContextValidation, onTelKeyPress, onTextKeyPress, yupExt } from '../../../../../utilities/forms';
import SubTitle from '../../../../ui/SubTitle';
import usePhone from '../../../../utilities/usePhone';
import SelectField from '../../SelectField';
import TextField from '../../TextField';

export type ReferenceDetailsProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const ReferenceDetails = ({ allowPrimaryInfoChanges = false, disabled = false }: ReferenceDetailsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadRelationships);
    }, [dispatch]);

    const relationships = useSelector(getRelationships);

    const { code: phoneCountryCode } = usePhone();

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.referenceDetails')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        autoComplete={false}
                        label={t('customerDetails.label.referenceName')}
                        name="details.reference.name"
                        onKeyPress={onTextKeyPress}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.relationship')}
                        name="details.reference.relationship"
                        options={relationships}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.contactNo')}
                        name="details.reference.phone"
                        onKeyPress={onTelKeyPress}
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export const schema = (t: TFunction) => ({
    reference: yupExt.customerProperty().shape({
        name: yup
            .string()
            .required(t('common.error.required'))
            .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
        phone: fromContextValidation(
            yup
                .string()
                .required(t('common.error.required'))
                .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
            'phonePattern',
            t('common.error.mobile')
        ),
        relationship: yup.string().required(t('common.error.required')),
    }),
});

export default ReferenceDetails;
