import { useQuery } from '@apollo/client';
import { Footer } from '@appvantageasia/afc-ui';
import { get } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { setNotification } from '../../actions';
import { useContentTranslation } from '../../i18n';
import { ApplicationPhase, EventExternalSite, PaymentProviderType } from '../../schema';
import { getCurrentCountry, getZoneId } from '../../selectors';
import useLoading from '../../useLoading';
import { getApplicationUrl } from '../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../shared/HelmetTitle';
import SubmitSuccessModalWithNoSession from '../shared/SubmitSuccessModalWithNoSession';
import PageContainer, { Title } from '../ui/PageContainer';
import { TextAlignCenter } from '../ui/TextAlign';
import useListContext, { useListContextFromState } from '../utilities/useListContext';
import useSearch from '../utilities/useSearch';
import useServerPaging from '../utilities/useServerPaging';
import useSorting from '../utilities/useSorting';
import ApplicationList from './ApplicationList';
import { getApplications } from './ApplicationsRoute.graphql';
import DownloadModal from './DownloadModal';

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

export const getTransactionId = application => {
    switch (application?.payment?.channel?.type) {
        case PaymentProviderType.PORSCHE:
        case PaymentProviderType.PAYGATE:
            return get('payment.channel.details.transactionId', application, '');

        case PaymentProviderType.ADYEN:
            return get('payment.channel.details.pspReference', application, '');

        default:
            return '';
    }
};

const pageSetting = {
    phase: ApplicationPhase.FINANCE,
    titleKey: 'applicationsListPage.financeTitle',
    previousKey: 'applicationDetailsPage.button.financePrevious',
};

const ApplicationsRoute = () => {
    const { t } = useTranslation();
    const { language } = useContentTranslation();
    const dispatch = useDispatch();
    const { formatPath } = useContentTranslation();
    const searchFields = [
        'identifier',
        'customer.name.value',
        'variant.name',
        formatPath('financeProduct.name'),
        'status',
        'transactionID',
    ];
    // get initial list context
    const [, getInitial] = useListContextFromState();

    const search = useSearch('applications', getInitial('search'));

    const [paging] = useServerPaging({ search, initialPage: getInitial('paging.page') });

    const sorting = useSorting({
        initialKey: getInitial('sorting.key', 'version.updatedAt'),
        initialOrder: getInitial('sorting.order'),
    });

    const { phase, titleKey, previousKey } = pageSetting;

    // get applications with apollo
    const zoneId = useSelector(getZoneId);
    const dealerId = useMustExistDealerIdFromTenantContext();
    const variables = {
        zoneId,
        filter: {
            dealerIds: [dealerId],
            phase,
        },
        locale: language,
        sorting: [
            {
                type: sorting && sorting?.key,
                order: sorting && sorting?.order?.toUpperCase(),
            },
        ],
        paging: {
            limit: paging.pageSize,
            offset: (paging.page - 1) * paging.pageSize,
        },
        search: search
            ? searchFields.map(field => {
                  return { identifier: field, value: search };
              })
            : null,
    };

    const { data, loading, error: apiError } = useQuery(getApplications, {
        variables,
        fetchPolicy: 'cache-and-network',
    });

    const applicationData = data?.applications?.items || [];
    const isLoading = loading && applicationData.length <= 0;

    useLoading(isLoading);

    useEffect(() => {
        paging.setItemCount(data?.applications?.count || 0);
    }, [paging, data]);

    const items = useMemo(() => {
        return applicationData.map(application => ({
            ...application,
            transactionID: getTransactionId(application), // hide variant for marketing reconsent
            variant:
                application.event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT
                    ? null
                    : application.variant,
        }));
    }, [applicationData]);

    // make a context to bump/restore those configuration
    const listContext = useListContext(paging, sorting, search);

    // callback on items
    const history = useHistory();
    const onItemClick = useCallback(
        item => history.pushWithCompanyAndState(getApplicationUrl, { listContext, previous: t(previousKey) }, item),
        [history, listContext, previousKey, t]
    );

    const { error = null, submitted = false, application = null, updated = false } = useLocation().state ?? {};
    useEffect(() => {
        if (error) {
            dispatch(setNotification('Error', error));
        }
    }, [dispatch, error]);

    // display special submit modal
    const [showModal, setShowModal] = useState(submitted || updated);
    const onClose = useCallback(() => setShowModal(state => !state), [setShowModal]);

    const country = useSelector(getCurrentCountry);

    const [downloadModal, setDownloadModal] = useState(false);

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        () => setDownloadModal(<DownloadModal onClose={closeDownloadModal} type="Applications" />),
        [setDownloadModal, closeDownloadModal]
    );

    const renderMessage = () => {
        return !isLoading ? <TextAlignCenter>{t('applicationsListPage.list.noApplications')}</TextAlignCenter> : null;
    };

    if (apiError) {
        throw apiError;
    }

    return (
        <PageContainer>
            <HelmetTitle title="Application List" />
            <Title>{t(titleKey)}</Title>
            {items.length ? (
                <ApplicationList
                    applicationPhase={ApplicationPhase.FINANCE}
                    items={items}
                    onItemClick={onItemClick}
                    paging={paging}
                    sorting={sorting}
                />
            ) : (
                renderMessage()
            )}

            <div style={{ marginBottom: '80px' }} />
            {!!items.length && (
                <FootContainer>
                    <FootBar>
                        <FootBarRow>
                            <div style={{ flex: 1 }} />
                            <FootBarButton onClick={openDownloadModal}>
                                {t('applicationsListPage.button.download')}
                            </FootBarButton>
                        </FootBarRow>
                    </FootBar>
                </FootContainer>
            )}

            {showModal && (
                <SubmitSuccessModalWithNoSession
                    application={application}
                    hasSendCustomerConfirmationEmail={country.sendCustomerConfirmationEmail}
                    onClose={onClose}
                    updated={updated}
                />
            )}
            {downloadModal}
        </PageContainer>
    );
};

export default ApplicationsRoute;
