import { useMemo } from 'react';
import { MiniConfiguratorDetails } from '../../../flows/ConfiguratorFlow/components/Summary/Summary';
import EventSubmitFlow, { EventFlowState } from '../../../flows/EventFlow/EventSubmitFlow';
import { CarOfInterestType } from '../../../flows/EventFlow/steps/EventDraftStep';
import { useManagedFlow, useScrollTop } from '../../../flows/utils/flow';
import { useCompany, useCountry, useZone } from '../../../hookSelectors';
import { ContentTranslator, useContentTranslation } from '../../../i18n';
import { ApplicationFlowSource, Channel } from '../../../schema';
import { BankDataFragment } from '../../data/useLoadBank.graphql';
import { PromoDataFragment } from '../../data/useLoadPromo.graphql';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import { CalculatorValues } from '../../shared/calculator-next/types';
import { ApplicationDataFragment } from '../ApplicationRoute/data.graphql';
import { CsvConfiguratorDataFragment } from '../CsvRoute/data.graphql';
import { FinderVehicleDataFragment } from '../FinderRoute/data.graphql';
import { EventDataFragment } from './EventRoute.graphql';

export type EventFlowHandlerProps = {
    application?: ApplicationDataFragment;
    dealerId?: string;
    event: EventDataFragment;
    finderVehicle?: FinderVehicleDataFragment;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    calculator?: Partial<CalculatorValues>;
    token?: string;
    promo?: PromoDataFragment | null;
    bookingId?: string;
    variant?: VariantDataFragment;
    csvConfigurator?: CsvConfiguratorDataFragment;
    reservation?: ApplicationDataFragment;
    bank?: BankDataFragment;
};

const getCarOfInterest = (
    ct: ContentTranslator,
    application?: ApplicationDataFragment
): CarOfInterestType | undefined => {
    if (!application || !application.assetCondition) {
        return undefined;
    }

    const hasSubModel = !!application.variant.model.parent;

    return {
        assetCondition: application.assetCondition,
        variantId: application.variant.id,
        modelId: hasSubModel ? application.variant.model.parent?.id : application.variant.modelId,
        subModelId: hasSubModel ? application.variant.modelId : undefined,
        // hide variant name if express
        variantName: application.variant.channels.express ? ct(application.variant.name) : '',
    };
};

const EventHandler = ({
    event,
    finderVehicle,
    miniConfiguratorDetails,
    calculator,
    application,
    dealerId,
    token,
    promo,
    bookingId,
    variant,
    csvConfigurator,
    reservation,
    bank,
}: EventFlowHandlerProps) => {
    // get zone and country from redux store
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();
    const { ct } = useContentTranslation();

    // create the initial state
    const initialState = useMemo(
        (): EventFlowState => ({
            channel: Channel.EVENT,
            company,
            zone,
            country,
            dealerId,
            event,
            source: ApplicationFlowSource.EVENT,
            finderVehicle,
            miniConfiguratorDetails,
            calculator,
            application,
            token,
            promo,
            carOfInterest: getCarOfInterest(ct, application),
            bookingId,
            variant,
            csvConfigurator,
            reservation,
            bank,
            hasTestDrive: reservation?.hasTestDrive ?? false,
            hasTradeIn: reservation?.hasTradeIn ?? false,
            isCalculatorEnabled: reservation?.isCalculatorEnabled ?? undefined,
            appliedForFinancing: !!reservation,
        }),
        [
            company,
            zone,
            country,
            dealerId,
            event,
            finderVehicle,
            miniConfiguratorDetails,
            calculator,
            application,
            token,
            promo,
            ct,
            bookingId,
            variant,
            csvConfigurator,
            reservation,
            bank,
        ]
    );

    const { step } = useManagedFlow(EventSubmitFlow, initialState, 'eventSubmitFlow');

    useScrollTop(step.identifier);

    return step.render();
};

export default EventHandler;
