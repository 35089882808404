import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { Actions, DarkButton } from '@appvantageasia/afc-ui';
import { set, isEmpty } from 'lodash/fp';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, batch } from 'react-redux';
import { change } from 'redux-form';
import { useCountry } from '../../../hookSelectors';
import { getCalculatorPayload } from '../../../utilities/calculator';
import { getPromoFromIdentifier } from '../../data/useLoadPromo';
import { PromoDataFragment } from '../../data/useLoadPromo.graphql';
import { CalculatorValues } from '../../shared/calculator-next/types';
import { CalculatorError, CalculatorModal } from '../../ui/calculator';
import useFormValues from '../../utilities/useFormValues';
import { ApplicationFormValues } from './ApplicationForm';
import Calculator from './Calculator';
import { ApplicationDataFragment } from './data.graphql';

export type RecalculateProviderProps = {
    application: ApplicationDataFragment;
    children: (openRecalculate: () => void) => ReactElement;
};

const RecalculateProvider = ({ children, application }: RecalculateProviderProps) => {
    const dispatch = useDispatch();
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const [isOpen, setOpen] = useState(false);
    const { id: countryId, code } = useCountry();

    const openRecalculate = useCallback(() => setOpen(true), [setOpen]);

    // get form values
    const formValues = useFormValues<ApplicationFormValues>();
    const [context, setContext] = useState<CalculatorContext<CalculatorValues>>();
    const { errors } = context || {};

    // apply callback
    const apply = useCallback(async () => {
        // close modal
        setOpen(false);

        if (context) {
            const { values, getFieldContext } = context;

            let { selectedVariant: variant } = getFieldContext('variant');
            const { selectedFinanceProduct } = getFieldContext('financeProduct');
            const { selectedOptions } = getFieldContext('carOptions');

            let promo: null | PromoDataFragment = null;
            if (countryId && values?.promoCode && application.dealerId) {
                promo = await getPromoFromIdentifier(client, countryId, values?.promoCode, [application.dealerId]);
            }
            const validPromo = promo && promo.remainingQuantity > 0 ? promo : null;

            if (formValues?.expressVariant) {
                variant = set('preOwnedCarDetails', formValues.expressVariant.preOwnedCarDetails, variant);
            }

            // apply updates in the form (synchronously)
            batch(() => {
                dispatch(change('application', 'calculator', values));
                dispatch(change('application', 'financeProduct', selectedFinanceProduct));
                dispatch(change('application', 'variant', variant));
                dispatch(change('application', 'options', Object.values(selectedOptions || {})));
                dispatch(change('application', 'promoCode', validPromo));
            });
        }
    }, [context, countryId, application.dealerId, formValues.expressVariant, client, dispatch]);

    // close callback
    const onClose = useCallback(() => {
        // close modal
        setOpen(false);
    }, [setOpen]);

    const source = useMemo(
        () => ({
            ...formValues,
            dealerId: application.dealerId,
            calculator: getCalculatorPayload(formValues.calculator),
        }),
        [application.dealerId, formValues]
    );

    const isPorscheFinder = useMemo(() => !!application?.finderVehicle?.id, [application]);

    const variants = useMemo(() => (isPorscheFinder ? [application?.variant] : undefined), [
        application,
        isPorscheFinder,
    ]);

    return (
        <>
            {children(openRecalculate)}
            {isOpen && (
                <CalculatorModal channel={application.channel} countryCode={code} onClose={onClose} showClose showTitle>
                    <Calculator
                        application={source}
                        channel={application.channel}
                        dealerId={application.dealerId}
                        eventId={application.event?.id}
                        onChange={setContext}
                        variants={variants}
                    />
                    <Actions>
                        <DarkButton disabled={!isEmpty(errors)} onClick={apply}>
                            Save
                        </DarkButton>
                    </Actions>

                    <CalculatorError errors={errors} />
                </CalculatorModal>
            )}
        </>
    );
};

export default RecalculateProvider;
