import { TFunction } from 'i18next';
import { get, getOr } from 'lodash/fp';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadAffinBank, loadNationalities } from '../../../../actions';
import { BankPresetOption } from '../../../../schema';
import { getAffinBankAddressTypes, getCountries } from '../../../../selectors';
import { yupExt } from '../../../../utilities/forms';
import SubTitle from '../../../ui/SubTitle';
import SelectField from '../SelectField';
import TextField from '../TextField';
import { getLabelWithFlag } from './CustomerDetails';

const AddressDetails = () => {
    const { t } = useTranslation();
    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();

    const residentialAddress = get(
        sectionPrefix ? `${sectionPrefix}.details.residentialAddress` : 'details.residentialAddress',
        formValues
    );
    const residentialAddressSource = residentialAddress?.source;
    const customer = getOr(formValues, `${sectionPrefix}`, formValues);
    const isAffinBank = getOr('', 'financeProduct.bank.presetOption', formValues) === BankPresetOption.AFFINBANK;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadNationalities());
    }, [dispatch]);

    useEffect(() => {
        if (isAffinBank) {
            dispatch(loadAffinBank());
        }
    }, [dispatch, isAffinBank]);

    const countries = useSelector(getCountries);
    const addressTypesAffin = useSelector(getAffinBankAddressTypes);

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.residentialAddress')}</SubTitle>
            <div className="row">
                {isAffinBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag(
                                t('customerDetails.label.addressType'),
                                customer?.details?.thirdParty?.affinBank?.addressType.source
                            )}
                            name="details.thirdParty.affinBank.addressType.value"
                            options={addressTypesAffin}
                            disabled
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    {residentialAddress?.countryName ? (
                        <TextField
                            label={getLabelWithFlag(t('customerDetails.label.country'), residentialAddressSource)}
                            name="details.residentialAddress.countryName"
                            disabled
                        />
                    ) : (
                        <SelectField.Outline
                            label={getLabelWithFlag(t('customerDetails.label.country'), residentialAddressSource)}
                            name="details.residentialAddress.country"
                            options={countries}
                            disabled
                        />
                    )}
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.city'), residentialAddressSource)}
                        name="details.residentialAddress.city"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.postalCode'), residentialAddressSource)}
                        name="details.residentialAddress.postalCode"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.street'), residentialAddressSource)}
                        name="details.residentialAddress.street"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.block'), residentialAddressSource)}
                        name="details.residentialAddress.block"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.building'), residentialAddressSource)}
                        name="details.residentialAddress.building"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.unit'), residentialAddressSource)}
                        name="details.residentialAddress.unit"
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export const schema = (t: TFunction) => ({
    // @ts-ignore
    residentialAddress: yup.lazy((values, options) => {
        const { context } = options;

        // validate only when it's not an event
        // because porsche finder finance won't have this value for it's applicant
        if (context?.application?.event?.id) {
            return yup.mixed().notRequired();
        }

        return yupExt.customerProperty().shape({
            block: yup
                .string()
                .notRequired()
                .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
            building: yup
                .string()
                .notRequired()
                .max(66, t('common.error.lessThanOrEqual', { max: 66 })),
            country: yup.string().required(t('common.error.required')),
            postalCode: yup
                .string()
                .required(t('common.error.required'))
                .max(6, t('common.error.lessThanOrEqual', { max: 6 })),
            street: yup
                .string()
                .required(t('common.error.required'))
                .max(66, t('common.error.lessThanOrEqual', { max: 66 })),
            unit: yup
                .string()
                .required(t('common.error.required'))
                .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
        });
    }),
});

export default AddressDetails;
