import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ApplicationRoute from '../routes/ApplicationRoute';
import ApplicationsRoute from '../routes/ApplicationsRoute';
import ResumeRoute from '../routes/wip/ResumeRoute';
import Header from '../shared/Header';
import { prefixApplicationPath } from './shared';
import useNotPaidBackAction from './useNotPaidBackAction';
import useNotSignedBackAction from './useNotSignedBackAction';

const ApplicationRouter = () => {
    useNotSignedBackAction();
    useNotPaidBackAction();

    return (
        <>
            <Header />
            <Switch>
                <Route component={ApplicationsRoute} path={prefixApplicationPath('/')} exact />
                <Route component={ApplicationRoute} path={prefixApplicationPath('/:id')} exact />
                <Route component={ResumeRoute} path={prefixApplicationPath('/:id/resume')} exact />
                <Redirect to={prefixApplicationPath('/')} />
            </Switch>
        </>
    );
};

export default ApplicationRouter;
