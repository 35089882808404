import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import breakpoints from '../../../../../utilities/constants/breakpoints';

export type DisclaimerProps = {
    text: string;
};

const DisclaimerContainer = styled.div`
    font-size: 10px;
    position: relative;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
`;

const HiddenText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    width: 100%;

    /* tune this value to make it not too far and not too close with the button */
    @media screen and (min-width: ${breakpoints.sm}) {
        max-width: 280px;
    }

    @media screen and (min-width: ${breakpoints['2xl']}) {
        max-width: 500px;
    }
`;

const Button = styled.span`
    cursor: pointer;
    flex-shrink: 0;
    padding-left: 10px;
`;

const Disclaimer = ({ text }: DisclaimerProps) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const onExpand = useCallback(() => {
        setShow(true);
    }, []);

    // we don't need to hide if it's short
    useEffect(() => {
        if (text.length < 70) {
            onExpand();
        }
    }, [text, onExpand]);

    return (
        <DisclaimerContainer>
            {show ? text : <HiddenText>{text}</HiddenText>}
            {!show && <Button onClick={onExpand}>{t('eventCalculatorPage.disclaimerReadMore')}</Button>}
        </DisclaimerContainer>
    );
};

export default Disclaimer;
