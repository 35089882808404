import { useCallback } from 'react';
import useCompanyFormatting from '../../components/utilities/useCompanyFormatting';
import { Maybe } from '../../schema';

const useGetFormattedCurrency = () => {
    const { formatCurrency } = useCompanyFormatting();

    return useCallback((value: number) => formatCurrency(value).split(' ')[1], [formatCurrency]);
};

const useGetCalculatorFooterText = () => {
    const getFormattedCurrency = useGetFormattedCurrency();

    return useCallback(
        (
            text?: string | null,
            coe?: number,
            ppsr?: number,
            establishment?: number,
            luxuryTax?: number,
            selectedDealerEstablishment?: number,
            selectedBankEstablishment?: Maybe<number> | undefined
        ) => {
            if (!text) {
                return null;
            }

            let result = text;

            if (coe) {
                result = result?.replace('<<COE>>', getFormattedCurrency(coe));
            }

            if (ppsr) {
                result = result?.replace('<<ppsr>>', getFormattedCurrency(ppsr));
            }

            // Refer to: PNZ-9
            // <<establishment>> became dealer establishment in price disclaimer
            if (establishment) {
                result = result?.replace('<<establishment>>', getFormattedCurrency(selectedDealerEstablishment ?? 0));
            }

            if (luxuryTax) {
                result = result?.replace('<<LUXURY_TAX>>', getFormattedCurrency(luxuryTax));
            }

            result = result?.replace(
                '<<NZfees>>',
                getFormattedCurrency(
                    (ppsr ?? 0) + (selectedDealerEstablishment ?? 0) + (selectedBankEstablishment ?? 0)
                )
            );

            return result;
        },
        [getFormattedCurrency]
    );
};

export default useGetCalculatorFooterText;
