import { omit, getOr } from 'lodash/fp';
import React from 'react';
import {
    createCustomer,
    CreateCustomerMutation,
    CreateCustomerMutationVariables,
    updateCustomer,
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables,
} from '../../../api/customer.graphql';
import {
    completeConsents,
    CompleteConsentsMutation,
    CompleteConsentsMutationVariables,
    completeKyc,
    CompleteKycMutation,
    CompleteKycMutationVariables,
    completeReservationToFinance,
    CompleteReservationToFinanceMutation,
    CompleteReservationToFinanceMutationVariables,
    create,
    CreateMutation,
    CreateMutationVariables,
} from '../../../api/draft.graphql';
import {
    createUsedVariant,
    CreateUsedVariantMutation,
    CreateUsedVariantMutationVariables,
} from '../../../api/miscellaneous.graphql';
import { ApplicationCustomerDataFragment } from '../../../components/routes/ApplicationRoute/data.graphql';
import { ApplicationFlowSource, AssetCondition, CustomerDetailsSource, CustomerType } from '../../../schema';
import { getCalculatorPayload } from '../../../utilities/calculator';
import { mapCreateCustomerToPayload } from '../../../utilities/customer';
import { prepareForGraphQL } from '../../../utilities/forms';
import { VehicleDataFragment } from '../../DraftFlow/components/MyInfo/MyInfoStep.graphql';
import { ReduxFormFlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';
import DraftRoute from '../components/DraftPage';

export type TradeInProps = VehicleDataFragment;

export type CarOfInterestType = {
    assetCondition?: AssetCondition;
    modelId?: string;
    subModelId?: string;
    variantId?: string;
    variantName?: string;
    dealerId?: string;
};

export type EventDraftStepValues = {
    dealerId: string;
    customerInfo: ApplicationCustomerDataFragment;
    tradeIn?: TradeInProps;
    carOfInterest: CarOfInterestType;
    uploadId?: string;
    application?: {
        remark?: string;
        appliedForFinancing?: boolean;
        proceedWithCustomerDevice?: boolean;
    };
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
    referenceId: string | null | undefined;
};

class DraftStep extends ReduxFormFlowStep<EventFlowState, EventDraftStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return ApplicationFlowSource.DRAFT;
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('eventDraftPage.label.step');
    }

    public get isCompleted(): boolean {
        const { application, reservation } = this.state;

        if (reservation) {
            // For next step to my info
            if (reservation.customer && reservation.customer.withMyInfo) {
                return true;
            }

            return !!application?.steps?.reservationToFinance;
        }

        return !!application?.id;
    }

    // eslint-disable-next-line class-methods-use-this
    public get ignoreOnBack(): boolean {
        const { application } = this.state;

        // if customer data does come from my info, we skip this step
        return !!application?.customer?.withMyInfo;
    }

    public render(): React.ReactElement | null {
        const {
            application,
            zone,
            dealerId,
            channel,
            event,
            calculator,
            variant,
            promo,
            financeProduct,
            withMyInfoError,
            hasTestDrive,
            hasTradeIn,
            carOfInterest,
            miniConfiguratorDetails,
            bookingId,
            appliedForFinancing,
            bank,
            isCalculatorEnabled,
        } = this.state;

        if (!event) {
            throw new Error('Event missing in state');
        }

        const initialValues = !application
            ? null
            : {
                  dealerId: application.dealerId!,
                  customerInfo: application.customer,
                  tradeIn: application?.tradeIn,
                  carOfInterest,
                  hasTradeIn,
                  hasTestDrive,
                  appliedForFinancing,
                  isCalculatorEnabled,
              };

        return (
            <DraftRoute
                appliedForFinancing={appliedForFinancing}
                backStep={this.getBackContext()}
                bank={bank}
                bookingId={bookingId}
                calculator={calculator}
                channel={channel}
                dealerId={dealerId}
                event={event}
                financeProduct={financeProduct}
                hasTestDrive={hasTestDrive}
                hasTradeIn={hasTradeIn}
                initialValues={initialValues}
                isCalculatorEnabled={isCalculatorEnabled}
                miniConfiguratorDetails={miniConfiguratorDetails}
                promo={promo}
                step={this.identifier}
                submit={this.submit}
                variant={variant}
                withMyInfoError={withMyInfoError}
                zone={zone}
            />
        );
    }

    protected async execute(values: EventDraftStepValues) {
        const { apolloClient, contentTranslation } = this;
        const {
            token: tokenInState,
            country,
            zone,
            calculator,
            leadId,
            channel,
            promo,
            event,
            miniConfiguratorDetails,
            finderVehicle,
            bookingId,
            isCalculatorEnabled,
            appliedForFinancing,
            bank,
            csvConfigurator,
            reservation,
        } = this.state;

        const { mapIntlValue } = contentTranslation;

        const { hasTestDrive, hasTradeIn, referenceId, dealerId } = values;

        const carOfInterest = values?.carOfInterest;
        let customerId = values.customerInfo.id;

        if (!customerId) {
            // prepare payload
            const customerPayload = mapCreateCustomerToPayload(zone.id, {
                ...prepareForGraphQL(values.customerInfo),
                zoneId: zone.id,
                type: CustomerType.INDIVIDUAL,
            });

            // create a new customer
            const createResponse = await apolloClient.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
                mutation: createCustomer,
                variables: customerPayload,
            });

            const customer = createResponse?.data?.customer;

            if (!customer) {
                throw new Error('Failed to create customer');
            }

            // keep the ID for the newly created customer
            customerId = customer.id;
        } else {
            // create a clean payload
            const customerPayload = omit(
                ['id', 'type'],
                values.customerInfo
            ) as UpdateCustomerMutationVariables['data'];

            // update existing customer
            await apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
                mutation: updateCustomer,
                variables: {
                    id: customerId,
                    token: tokenInState,
                    data: prepareForGraphQL(customerPayload),
                },
            });
        }

        const hasPromoStock = promo && promo.remainingQuantity > 0;

        const vehicles = values?.tradeIn;

        const { remark, proceedWithCustomerDevice = false } = values.application || {};

        let variantId;
        /** No new car setup */
        if (carOfInterest?.variantName) {
            const variantResponse = await apolloClient.mutate<
                CreateUsedVariantMutation,
                CreateUsedVariantMutationVariables
            >({
                mutation: createUsedVariant,
                variables: {
                    data: {
                        countryId: country.id,
                        // for express use model name as variant name
                        name: mapIntlValue({ [this.i18n.languages[0]]: carOfInterest.variantName }),
                        makeName: mapIntlValue({ [this.i18n.languages[0]]: carOfInterest.variantName }),
                        modelName: mapIntlValue({ [this.i18n.languages[0]]: carOfInterest.variantName }),
                    },
                },
            });
            const expressVariant = variantResponse.data?.variant;
            if (!expressVariant) {
                throw new Error('failed to create the express variant');
            }

            variantId = expressVariant.id;
        } else if (finderVehicle) {
            // create variant for porsche finder vehicle
            const variantResponse = await apolloClient.mutate<
                CreateUsedVariantMutation,
                CreateUsedVariantMutationVariables
            >({
                mutation: createUsedVariant,
                variables: {
                    data: {
                        countryId: country.id,
                        // for express use model name as variant name
                        name: mapIntlValue({ [this.i18n.languages[0]]: finderVehicle.name }),
                        makeName: mapIntlValue({ [this.i18n.languages[0]]: 'Porsche' }),
                        modelName: mapIntlValue({
                            [this.i18n.languages[0]]:
                                finderVehicle.listing?.vehicle.modelSeries.localize || finderVehicle.name,
                        }),
                    },
                },
            });
            const finderVehicleVariant = variantResponse.data?.variant;
            if (!finderVehicleVariant) {
                throw new Error('failed to create the finder vehicle variant');
            }

            variantId = finderVehicleVariant.id;
        } else if (csvConfigurator) {
            const locale = this.i18n.languages[0];
            const variantResponse = await apolloClient.mutate<
                CreateUsedVariantMutation,
                CreateUsedVariantMutationVariables
            >({
                mutation: createUsedVariant,
                variables: {
                    data: {
                        countryId: country.id,
                        // for express use model name as variant name
                        name: mapIntlValue({ [locale]: csvConfigurator?.variant?.name ?? '' }),
                        makeName: mapIntlValue({ [locale]: 'Porsche' }),
                        modelName: mapIntlValue({
                            [locale]: csvConfigurator.model?.name ?? '',
                        }),
                    },
                },
            });
            const csvVehicleVariant = variantResponse.data?.variant;
            if (!csvVehicleVariant) {
                throw new Error('failed to create the csv vehicle variant');
            }

            variantId = csvVehicleVariant.id;
        } else {
            variantId = calculator?.variant || carOfInterest?.variantId;
        }

        // build application payload
        const applicationPayload = prepareForGraphQL({
            // add event id
            eventId: event?.id,
            // add the zone id
            zoneId: zone.id,
            // add dealer id
            dealerId,
            // doesn't exist in event
            remark,
            appliedForFinancing: appliedForFinancing ?? false,
            proceedWithCustomerDevice,
            optionIds: [],
            // add the customer id
            customerId,
            // include booking id if porsche finder flow
            bookingId: finderVehicle && bookingId,
            // lead id comes from meta, as does upload id
            leadId,
            uploadId: values.uploadId,
            // add the channel
            channel,
            // porsche finder flow
            financeProductId: calculator?.financeProduct,
            variantId,
            assetCondition: carOfInterest?.assetCondition,
            bank: calculator?.bank ? { id: calculator?.bank } : null,
            // then calculator payload
            calculator: calculator ? getCalculatorPayload(calculator) : {},
            // promotion code
            promoCodeId: hasPromoStock ? promo?.id : null,
            // trade in payload
            tradeIn: vehicles
                ? {
                      ...vehicles,
                      source: CustomerDetailsSource.MANUAL,
                  }
                : null,
            hasTradeIn,
            hasTestDrive,
            // application locale
            locale: this.i18n.languages[0],
            miniConfiguratorDetails,
            finderVehicleId: getOr(null, 'id', finderVehicle),
            isCalculatorEnabled,
            csvConfigurator,
            reservationVersionId: reservation?.version.id,
        });

        const draftResponse = await apolloClient.mutate<CreateMutation, CreateMutationVariables>({
            mutation: create,
            variables: { data: applicationPayload },
        });

        const { application, token } = draftResponse.data?.response || {};

        if (!application) {
            throw new Error('application missing in state');
        }

        if (!token) {
            throw new Error('token missing in state');
        }

        // update the state
        this.stateStore.update({
            dealerId,
            application,
            token,
            carOfInterest,
            hasTestDrive,
            hasTradeIn,
            miniConfiguratorDetails,
        });

        // complete consents
        if (values.referenceId) {
            const variables = { token, eventId: referenceId };

            const { data } = await apolloClient.mutate<CompleteConsentsMutation, CompleteConsentsMutationVariables>({
                mutation: completeConsents,
                variables,
            });

            this.stateStore.update({ ...data?.response });
        }

        // complete kyc if appliedForFinancing enabled
        if (appliedForFinancing && bank?.isKYCMandatory) {
            const apiResponse = await apolloClient.mutate<CompleteKycMutation, CompleteKycMutationVariables>({
                mutation: completeKyc,
                variables: { token },
            });

            this.stateStore.update({ ...apiResponse.data?.response });
        }

        if (reservation?.id) {
            const apiResponse = await apolloClient.mutate<
                CompleteReservationToFinanceMutation,
                CompleteReservationToFinanceMutationVariables
            >({
                mutation: completeReservationToFinance,
                variables: { token },
            });

            this.stateStore.update({ ...apiResponse.data?.response });
        }

        // update cache key
        this.flow.updateCacheKey();

        return this.nextStep;
    }
}

export default DraftStep;
