// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import { getOr } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationEventType, PaymentProviderType, PaymentStatus } from '../../../../schema';
import { ApplicationDataFragment } from '../../../routes/ApplicationRoute/data.graphql';
import useFormatDateTime from '../../useFormatDateTime';
import NumberField from '../NumberField';
import TextField from '../TextField';

export type PaymentDetailsProps = {
    application: ApplicationDataFragment;
};

const mapStatusLabel = (status: PaymentStatus) => {
    switch (status) {
        case PaymentStatus.PAYMENTCAPTURING:
            return 'Capturing Payment';

        default:
            return status;
    }
};

const PaymentDetails = ({ application }: PaymentDetailsProps) => {
    const { t } = useTranslation();
    const formatDateTime = useFormatDateTime();
    const variant = application.events.find(
        item => item.type === ApplicationEventType.PAYMENTCAPTURING || item.type === ApplicationEventType.PAID
    );
    const variantAt = getOr('', 'at', variant);
    const paymentDate = variantAt !== '' ? formatDateTime(variantAt) : '';
    const statusLabel = application.payment ? mapStatusLabel(application.payment.status) : '';

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.paymentDetails')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.paymentAmount')}
                            name="reservationDeposit"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={paymentDate}
                            label={t('applicationDetailsPage.label.paymentDate')}
                            name="__exclude.paymentDate"
                            disabled
                        />
                    </div>
                    {application.payment?.channel.type === PaymentProviderType.ADYEN && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={t('applicationDetailsPage.label.transactionID')}
                                name="payment.channel.details.pspReference"
                                disabled
                            />
                        </div>
                    )}
                    {(application.payment?.channel.type === PaymentProviderType.PORSCHE ||
                        application.payment?.channel.type === PaymentProviderType.PAYGATE) && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={t('applicationDetailsPage.label.transactionID')}
                                name="payment.channel.details.transactionId"
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={statusLabel}
                            label={t('applicationDetailsPage.label.status')}
                            name="__exclude.status"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.paymentMethod')}
                            name="payment.channel.details.paymentMethod"
                            style={{ textTransform: 'capitalize' }}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default PaymentDetails;
