import {
    CalculatorContext,
    CalculatorFields,
    GridCalculator,
    GridCalculatorProps,
    useCalculatorContext,
    defaultInsuranceComputingFields,
    defaultInsurancePreset,
    InsuranceValues,
} from '@appvantageasia/afc-calculator-ui-next';
import { isEqual } from 'lodash/fp';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../i18n';
import { Channel } from '../../../schema';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import useCompanyFormatting, { Formats } from '../../utilities/useCompanyFormatting';
import useInsurancePremium from '../../utilities/useInsurancePremium';
import { useCompanyCalculatorTheme } from './CompanyCalculator';
import { CalculatorInsuranceValues, CalculatorMeta, CalculatorValues } from './types';

export type CompanyCalculatorProps = {
    channel: Channel;
    dealerId: string;
    onChange?: (context: CalculatorContext<CalculatorInsuranceValues>) => void;
    onInsurancePremiumChange: (value: { insurancePremiumAmount: number; hasError: boolean }) => void;
    initialValues: Partial<CalculatorValues>;
    fields?: CalculatorFields<CalculatorInsuranceValues>;
    meta: Partial<CalculatorMeta>;
    variants: VariantDataFragment[];
    children?: ReactNode;
    preset?: JSX.Element;
} & Omit<
    GridCalculatorProps<CalculatorInsuranceValues>,
    'context' | keyof Formats | 't' | 'ct' | 'language' | 'children'
>;

const InsuranceCalculator = ({
    channel,
    dealerId,
    onChange,
    onInsurancePremiumChange,
    initialValues,
    fields = defaultInsuranceComputingFields,
    meta,
    children,
    variants,
    preset = defaultInsurancePreset,
    ...props
}: CompanyCalculatorProps) => {
    const { t } = useTranslation();
    const { ct, language } = useContentTranslation();
    // get formatting
    const formats = useCompanyFormatting();
    const theme = useCompanyCalculatorTheme(channel);

    const enhancedMeta = useMemo(
        () => ({
            variants,
            ...meta,
        }),
        [meta, variants]
    );

    const context = useCalculatorContext<CalculatorInsuranceValues>(initialValues, fields, enhancedMeta);
    const [values, setValues] = useState<Partial<InsuranceValues> | null>(null);

    const { insurancePremiumAmount, loading } = useInsurancePremium(channel, dealerId, values);

    useEffect(() => {
        const { values: newValues } = context;

        if (!isEqual(values, newValues)) {
            setValues(newValues);
        }
        if (onChange) {
            onChange(context);
        }
    }, [context, onChange, values]);

    useEffect(() => {
        if (!loading && context) {
            onInsurancePremiumChange({ insurancePremiumAmount, hasError: !insurancePremiumAmount });
            context.change('insurancePremium', insurancePremiumAmount);
        }
    }, [loading, insurancePremiumAmount, onInsurancePremiumChange]);

    return (
        <GridCalculator context={context} theme={theme} {...formats} {...props} ct={ct} language={language} t={t}>
            {preset}
            {children}
        </GridCalculator>
    );
};

export default InsuranceCalculator;
