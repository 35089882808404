import { isNil } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useDealerBookingPayment from '../../../flows/utils/useDealerBookingPayment';
import { useCountry } from '../../../hookSelectors';
import { Channel } from '../../../schema';
import { getContext } from '../../../selectors';
import useCompanyFormatting from '../../utilities/useCompanyFormatting';

const FieldContainer = styled.div`
    background-color: #bcafb433;
    color: ${props => props.theme.calculator.textColor};
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 0 15px;
    overflow: hidden;
    box-sizing: border-box;
`;

const FieldTitle = styled.div`
    font-size: 11px;
    line-height: 12px;
    color: ${props => props.theme.calculator.labelColor};
    align-items: flex-start;
`;

const FieldValue = styled.div`
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    vertical-align: baseline;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export type ReservationDepositFieldProps = {
    channel: Channel;
    amount?: number;
    isPaid?: boolean;
};

const ReservationDepositField = ({ channel, amount: amountProp, isPaid = false }: ReservationDepositFieldProps) => {
    const { t } = useTranslation();
    const { channelSetting } = useCountry();
    const { dealerId } = useSelector(getContext);

    const { data, loading } = useDealerBookingPayment(dealerId as string);
    const dealerBookingPayment = data?.result;

    const amount = useMemo(() => {
        switch (channel) {
            case Channel.EXPRESS:
                return (
                    (dealerBookingPayment && !isNil(dealerBookingPayment.express) && dealerBookingPayment.express) ||
                    channelSetting.express.bookingPayment?.amount
                );

            case Channel.USED:
                return (
                    (dealerBookingPayment && !isNil(dealerBookingPayment.used) && dealerBookingPayment.used) ||
                    channelSetting.used.bookingPayment?.amount
                );

            case Channel.NEW:
                return (
                    (dealerBookingPayment && !isNil(dealerBookingPayment.new) && dealerBookingPayment.new) ||
                    channelSetting.new.bookingPayment?.amount
                );

            case Channel.EVENT:
                return amountProp;

            default:
                throw new Error('not implemented');
        }
    }, [channel, channelSetting, amountProp, dealerBookingPayment]);

    const { formatCurrency } = useCompanyFormatting();

    if (!amount || loading) {
        return null;
    }

    return (
        <FieldContainer>
            <FieldTitle>
                {t(isPaid ? 'calculator.label.reservationDepositPaid' : 'calculator.label.reservationDeposit')}
            </FieldTitle>
            <FieldValue>{formatCurrency(amount)}</FieldValue>
        </FieldContainer>
    );
};

export default ReservationDepositField;
