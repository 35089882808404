// @ts-ignore
import { InsuranceValues } from '@appvantageasia/afc-calculator-ui-next';
import { TFunction } from 'i18next';
import { isEqual } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { BankDataFragment } from '../../../../components/data/useLoadBank.graphql';
import { ZoneScopeFragment } from '../../../../components/data/useLoadScope.graphql';
import useMustExistDealerIdFromTenantContext from '../../../../components/data/useMustExistDealerIdFromTenantContext';
import { schema, pmeSchema } from '../../../../components/shared/partialForms/CustomerInformationForm';
import { Container, Title } from '../../../../components/ui/calculator';
import useValidation from '../../../../components/utilities/useValidation';
import useValidationContext from '../../../../components/utilities/useValidationContext';
import { useCountry } from '../../../../hookSelectors';
import { Channel, Customer, CustomerDetailsSource, RuntimeType } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import { handleResponseError } from '../../../../utilities/forms';
import Navigation from '../../../utils/Navigation';
import { BackStepContext } from '../../../utils/flow';
import CustomerForm, { genericCustomerSchema } from './CustomerForm';
import SearchCustomer, { useSearchCustomer } from './SearchCustomer';

export type InnerProps = {
    submit: (values: any) => Promise<any>;
    bank: BankDataFragment;
    zone: ZoneScopeFragment;
    isFromLead: boolean;
    backStep?: BackStepContext;
    channel: Channel;
    disableFinanceApplication?: boolean;
    customer?: Customer;
    dealerId: string;
    withInsurance: boolean;
    withFinancing: boolean;
    insuranceCalculator?: Partial<InsuranceValues> | undefined;
};

const buildInsuranceCalculatorValues = (insuranceCalculator: Partial<InsuranceValues> | undefined) => {
    if (!insuranceCalculator) {
        return {};
    }

    const { noClaimDiscount, yearsOfDriving, insurancePremium, dateOfRegistration } = insuranceCalculator;

    return {
        insurance: {
            calculator: {
                ncd: noClaimDiscount?.value,
                insurancePremium,
                yearsOfDriving: yearsOfDriving?.value,
                dateOfRegistration,
            },
        },
    };
};

const Inner = ({
    submit,
    bank,
    zone,
    isFromLead,
    backStep,
    channel,
    disableFinanceApplication,
    customer,
    dealerId,
    withFinancing,
    withInsurance,
    insuranceCalculator,
}: InnerProps) => {
    // get runtime settings
    const { useCustomerBirthDay, useCustomerNationality, type } = useSelector(getRuntimeSettings);
    const { code: countryCode } = useCountry();

    const initialValues = useMemo(() => {
        return {
            application: {
                appliedForFinancing: withFinancing,
                appliedForInsurance: withInsurance,
            },

            customer: {
                withMyInfo: false,
                ...(useCustomerNationality &&
                    countryCode === 'SG' && {
                        details: {
                            nationality: {
                                value: 'SG',
                                source: CustomerDetailsSource.MANUAL,
                            },
                        },
                    }),
                ...(withInsurance &&
                    insuranceCalculator?.dateOfBirth && {
                        dateOfBirth: {
                            value: insuranceCalculator?.dateOfBirth,
                            source: CustomerDetailsSource.MANUAL,
                        },
                    }),
                ...customer,
            },

            ...buildInsuranceCalculatorValues(insuranceCalculator),
        };
    }, [customer, useCustomerNationality, countryCode, withInsurance, withFinancing, insuranceCalculator]);

    const validation = useValidationContext();

    // For save as draft validation
    const usedSchema = useCallback(
        (t: TFunction) => {
            const baseSchema = type === RuntimeType.PME ? pmeSchema : schema;

            return yup.object().shape({}).concat(baseSchema(t)).concat(genericCustomerSchema(t));
        },
        [type]
    );
    const validate = useValidation(usedSchema);

    // we need a way to search existing customer
    // there is a type different between dateField and initial value , dateField expect isoString , but customer expect date object , so i put ignore here
    // @ts-ignore
    const [search, searching] = useSearchCustomer(isFromLead, initialValues?.customer);

    // prepare submit
    const onApply = useCallback(
        (values: any, validate: (values: any) => void) => {
            // callback to execute after the search has been successful
            const callback = async (nextValues: any) => {
                // first validate values
                validate(nextValues);

                // then proceed with apply function
                return submit(nextValues);
            };

            // catch error in handleResponseError
            return search(values, callback).catch(handleResponseError);
        },
        [search, submit]
    );

    // get translations
    const { t } = useTranslation();

    const selectedDealerId = useMustExistDealerIdFromTenantContext();

    // if current dealer and selected dealer not the same
    // go back to car listing
    useEffect(() => {
        if (!isEqual(selectedDealerId, dealerId)) {
            backStep?.goTo();
        }
    }, [backStep, dealerId, selectedDealerId]);

    return (
        <Container>
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <Title>{t('draftPage.title')}</Title>
            <CustomerForm
                apply={onApply}
                bank={bank}
                channel={channel}
                countryCode={countryCode}
                disableFinanceApplication={disableFinanceApplication}
                initialValues={initialValues}
                insuranceCalculator={insuranceCalculator}
                useCustomerBirthDay={useCustomerBirthDay}
                useCustomerNationality={useCustomerNationality}
                validate={validate}
                validation={validation}
                zone={zone}
            />
            <SearchCustomer searching={searching} />
        </Container>
    );
};

export default Inner;
