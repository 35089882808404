import { useQuery } from '@apollo/client';
import { InsuranceValues } from '@appvantageasia/afc-calculator-ui-next';
import { isNil } from 'lodash/fp';
import { useMemo } from 'react';
import { useContentTranslation } from '../../i18n';
import { Channel } from '../../schema';
import {
    getInsurancePremiumAmount,
    GetInsurancePremiumAmountQuery,
    GetInsurancePremiumAmountQueryVariables,
} from '../data/useLoadInsurancePremium.graphql';
import useLoadVariants from '../data/useLoadVariants';
import useInsuranceCompany from './useInsuranceCompany';

const useInsurancePremium = (channel: Channel, dealerId: string, values: Partial<InsuranceValues> | null) => {
    const { ct } = useContentTranslation();
    const insuranceCompany = useInsuranceCompany();
    const { variants = [] } = useLoadVariants('cache-and-network', channel, dealerId);
    const selectedVariant = useMemo(() => {
        if (values?.variant) {
            return variants.find(variant => variant.id === values?.variant);
        }

        return null;
    }, [values?.variant]);

    const premiumPayload = useMemo<GetInsurancePremiumAmountQueryVariables | {}>(() => {
        if (isNil(values) || isNil(insuranceCompany) || isNil(selectedVariant)) {
            return {};
        }

        return {
            insuranceCompanyId: insuranceCompany?.id || '',
            data: {
                modelName: selectedVariant?.identifier,
                vehiclePrice: values?.carPrice || 0,
                dateOfBirth: values?.dateOfBirth || new Date(),
                originalRegistrationDate: values?.dateOfRegistration || new Date(),
                drivingExperience: values?.yearsOfDriving?.value || 0,
                ncd: values?.noClaimDiscount?.value || 0,
            },
        };
    }, [selectedVariant, values, insuranceCompany]);

    const { data, loading } = useQuery<GetInsurancePremiumAmountQuery, GetInsurancePremiumAmountQueryVariables>(
        getInsurancePremiumAmount,
        {
            skip: isNil(values) || isNil(insuranceCompany) || isNil(selectedVariant),
            variables: premiumPayload as GetInsurancePremiumAmountQueryVariables,
        }
    );

    return useMemo(
        () => ({
            loading,
            insurancePremiumAmount: data?.insurancePremiumAmount || 0,
        }),
        [data, loading]
    );
};

export default useInsurancePremium;
