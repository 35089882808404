import { TFunction } from 'i18next';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadAffinBank } from '../../../../../actions';
import CountryField from '../../../../../components/shared/form-v2/CountryField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import { BankPresetOption } from '../../../../../schema';
import { getAffinBankAddressTypes } from '../../../../../selectors';
import { yupExt } from '../../../../../utilities/forms';
import { Title } from '../ui';

const watchedFields = [
    {
        path: 'details.residentialAddress',
        properties: ['street', 'block', 'building', 'unit', 'city', 'postalCode', 'country', 'countryName'],
    },
    'details.thirdParty.affinBank.addressType',
];

type ContactFormProps = {
    disabled?: boolean;
    bankPresetOption?: BankPresetOption;
    step: string;
};

const ContactForm = ({ disabled: disabledFromProps = false, bankPresetOption, step }: ContactFormProps) => {
    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const fieldState = useCustomerSource(watchedFields, sectionPrefix);
    const disabled = disabledFromProps || fieldState.details?.residentialAddress;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const isAffinBank = useMemo(() => bankPresetOption === BankPresetOption.AFFINBANK, [bankPresetOption]);

    useEffect(() => {
        if (isAffinBank) {
            dispatch(loadAffinBank());
        }
    }, [dispatch, isAffinBank]);

    const addressTypesAffin = useSelector(getAffinBankAddressTypes);

    return (
        <>
            <Title>
                {step === 'guarantor' ? t('kycPage.guarantorContactTitle') : t('kycPage.contactDetailsTitle')}
            </Title>
            {isAffinBank && (
                <SelectField.Outline
                    disabled={disabled || fieldState.details?.thirdParty?.affinBank?.addressType}
                    label={t('customerDetails.label.addressType')}
                    name="details.thirdParty.affinBank.addressType.value"
                    options={addressTypesAffin}
                />
            )}
            <TextField
                disabled={disabled}
                label={t('customerDetails.label.street')}
                name="details.residentialAddress.street"
            />
            <TextField
                disabled={disabled}
                label={t('customerDetails.label.block')}
                name="details.residentialAddress.block"
            />
            <TextField
                disabled={disabled}
                label={t('customerDetails.label.building')}
                name="details.residentialAddress.building"
            />
            <TextField
                disabled={disabled}
                label={t('customerDetails.label.unit')}
                name="details.residentialAddress.unit"
            />
            <TextField
                disabled={disabled}
                label={t('customerDetails.label.city')}
                name="details.residentialAddress.city"
            />
            <TextField
                disabled={disabled}
                label={t('customerDetails.label.postalCode')}
                name="details.residentialAddress.postalCode"
            />
            {formValues?.details?.residentialAddress?.countryName ? (
                <TextField
                    disabled={disabled}
                    label={t('customerDetails.label.country')}
                    name="details.residentialAddress.countryName"
                />
            ) : (
                <CountryField
                    disabled={disabled}
                    label={t('customerDetails.label.country')}
                    name="details.residentialAddress.country"
                />
            )}
        </>
    );
};

export const affinBankSchema = (t: Function) => ({
    addressType: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
});

export const detailsSchema = (t: TFunction) => ({
    residentialAddress: yupExt.customerProperty().shape({
        block: yup
            .string()
            .notRequired()
            .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
        building: yup
            .string()
            .notRequired()
            .max(66, t('common.error.lessThanOrEqual', { max: 66 })),
        country: yup.string().required(t('common.error.required')),
        postalCode: yup
            .string()
            .required(t('common.error.required'))
            .max(6, t('common.error.lessThanOrEqual', { max: 6 })),
        street: yup
            .string()
            .required(t('common.error.required'))
            .max(66, t('common.error.lessThanOrEqual', { max: 66 })),
        unit: yup
            .string()
            .required(t('common.error.required'))
            .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
    }),
});
export default ContactForm;
