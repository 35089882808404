import { TFunction } from 'i18next';
import { filter, isEmpty, omit } from 'lodash/fp';
import * as yup from 'yup';

const validateFile = (file: File | object) => {
    if (!(file instanceof File)) {
        return true;
    }

    const extension = file.name.split('.').pop()?.toLowerCase();
    const allowedExtensions = ['jpg', 'png', 'jpeg', 'pdf'];

    return !!extension && allowedExtensions.includes(extension);
};

export const filesSchema = ({ min, max }: { min: number; max: number }, purpose: string, t: TFunction) =>
    // @ts-ignore
    yup.lazy((value: any, { parent }: any) => {
        const { withMyInfo } = parent;

        const validator = yup
            .array()
            .transform((currentValue: any) =>
                filter(item => {
                    if (!item) {
                        return false;
                    }

                    if (item.purpose !== purpose) {
                        // wrong purpose, skip it
                        return false;
                    }

                    // ensure it's not an empty item
                    return item instanceof File || !isEmpty(omit(['purpose'], item));
                }, currentValue)
            )
            .of(
                yup.mixed().test(
                    'extension-validation',
                    t('common.error.fileExtension'),
                    // @ts-ignore
                    validateFile
                )
            );

        if (withMyInfo) {
            // it's not mandatory
            return validator;
        }

        return validator.min(min, t('common.error.filesRequired'));
    });
export default validateFile;
