import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
    getFlowConsentsAndDeclarations,
    GetFlowConsentsAndDeclarationsQuery,
    GetFlowConsentsAndDeclarationsQueryVariables,
} from '../../../../api/consents.graphql';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import Navigation from '../../../../components/routes/NewApplicationRoutes/shared/Navigation';
import HelmetTitle from '../../../../components/shared/HelmetTitle';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useValidation from '../../../../components/utilities/useValidation';
import { ApplicationFlowSource, Channel, PaymentProviderType } from '../../../../schema';
import PaymentStatus from '../../../../utilities/constants/paymentStatus';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import { Application } from '../../../DraftFlow';
import { BackStepContext } from '../../../utils/flow';
import { ConsentDepositStepValues } from '../../steps/EventConsentDepositStep';
import { CarOfInterestType } from '../../steps/EventDraftStep';
import { getPaymentConsents } from '../../utils/consents';
import { EventGrid, Page, PageTitle, Space, Title } from '../DraftPage/ui';
import DealerInformation from '../shared/DealerInformation';
import SelectedCar from '../shared/SelectedCar';
import DepositConsentForm, { schema } from './ConsentForm';
import DepositPayment from './DepositPayment';
import PayGatePayment from './PayGatePayment';
import PorschePayment from './PorschePayment';
import ReservationInformation from './ReservationInformation';

export type ConsentDepositProps = {
    application: Application;
    backStep?: BackStepContext;
    channel: Channel;
    dealerId: string;
    calculator?: Partial<CalculatorValues>;
    event: EventDataFragment;
    isLastStep: boolean;
    paymentResult?: any;
    onSubmit: (values: ConsentDepositStepValues) => Promise<void>;
    setPaymentResult: (resultCode: any) => void;
    token: string;
    variant?: VariantDataFragment;
    carOfInterest?: CarOfInterestType;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    source: ApplicationFlowSource;
};

const ConsentDeposit = ({
    application,
    backStep,
    calculator,
    channel,
    event,
    isLastStep,
    paymentResult,
    onSubmit,
    setPaymentResult,
    token,
    variant,
    carOfInterest,
    source,
    miniConfiguratorDetails,
    dealerId,
}: ConsentDepositProps) => {
    const { t } = useTranslation();

    const [consentGiven, setConsentGiven] = useState(false);
    const [referenceId, setReferenceId] = useState<string>();

    const [showModal, setShowModal] = useState(false);

    const handleSubmit = useCallback(() => {
        onSubmit(referenceId);
    }, [onSubmit, referenceId]);

    const { data: flowConsentsData, loading: flowConsentsLoading } = useQuery<
        GetFlowConsentsAndDeclarationsQuery,
        GetFlowConsentsAndDeclarationsQueryVariables
    >(getFlowConsentsAndDeclarations, {
        variables: {
            consent: {
                dealerId,
                eventId: event.id,
            },
        },
    });

    const consents = useMemo(() => orderBy('order', 'asc', getPaymentConsents(flowConsentsData?.result)), [
        flowConsentsData,
    ]);

    const validate = useValidation(schema(consents));

    const eventSetting = event?.setting;
    const hasDeposit = !!eventSetting?.isDepositPaymentMandatory;
    const depositAmount = eventSetting?.bookingPayment?.amount;

    const hasConsents = hasDeposit && !!consents?.length;

    const commonProps = {
        application,
        channel,
        consentGiven: !hasConsents || consentGiven,
        depositAmount,
        isLastStep,
        onSubmit: handleSubmit,
        token,
        source,
    };

    const renderDepositPayment = () => {
        switch (eventSetting?.bookingPayment?.provider.type) {
            case PaymentProviderType.ADYEN:
                return (
                    <DepositPayment
                        {...commonProps}
                        paymentResult={paymentResult}
                        setPaymentResult={setPaymentResult}
                    />
                );

            case PaymentProviderType.PORSCHE:
                return <PorschePayment {...commonProps} />;

            case PaymentProviderType.PAYGATE:
                return (
                    <PayGatePayment
                        {...commonProps}
                        hasConsents={hasConsents}
                        setShowModal={setShowModal}
                        showModal={showModal}
                    />
                );

            default:
                return null;
        }
    };

    // get paymentStatus from redux
    const formValues: { paymentStatus?: boolean } = useSelector(getFormValues('customer'));

    const showBackStep = formValues?.paymentStatus
        ? !formValues?.paymentStatus
        : paymentResult !== PaymentStatus.AUTHORISED;

    const isPayGate = eventSetting?.bookingPayment?.provider.type === PaymentProviderType.PAYGATE;
    const showNextButton = !hasDeposit || isPayGate;
    const depositSubmit = useMemo(() => (isPayGate ? () => setShowModal(true) : handleSubmit), [
        handleSubmit,
        isPayGate,
    ]);

    if (flowConsentsLoading) {
        // can't do yet
        return null;
    }

    return (
        <Page>
            <HelmetTitle channel={Channel.EVENT} title="Consents & Payment" />
            {backStep && showBackStep && <Navigation onPrev={backStep.goTo} />}
            <PageTitle>{t('depositPage.title')}</PageTitle>
            <EventGrid maxColumn={isPayGate ? 2 : 3}>
                <Space>
                    <ReservationInformation reservationPeriod={eventSetting?.reservationPeriod?.toString() ?? ''} />
                    <SelectedCar
                        calculator={calculator}
                        carOfInterest={carOfInterest}
                        dealerId={dealerId}
                        event={event}
                        miniConfiguratorDetails={miniConfiguratorDetails}
                        variant={variant}
                    />
                    <DealerInformation dealerId={dealerId} />
                </Space>
                {hasConsents && (
                    <div>
                        <Title>{t('eventConsentDepositPage.paymentConsentTitle')}</Title>
                        <DepositConsentForm
                            consents={consents}
                            onSubmit={depositSubmit}
                            referenceId={referenceId}
                            setReferenceId={setReferenceId}
                            setValid={setConsentGiven}
                            showNextButton={showNextButton}
                            token={token}
                            validate={validate}
                        />
                    </div>
                )}
                {renderDepositPayment()}
            </EventGrid>
        </Page>
    );
};

export default ConsentDeposit;
