import React from 'react';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import { CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import { EventExternalSite } from '../../../../../schema';
import { MiniConfiguratorDetails } from '../../../../ConfiguratorFlow/components/Summary/Summary';
import CarOfInterestPorscheFlow from '../../shared/CarOfInterestContainer';
import CarOfInterestChooser from './CarOfInterestChooser';
import CarOfInterestMarketingReconsent from './CarOfInterestMarketingReconsent';

export type CarOfInterestProps = {
    event: EventDataFragment;
    variant?: VariantDataFragment;
    calculator?: Partial<CalculatorValues>;
    disabled?: boolean;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    showDealerSelection: boolean;
    dealerId: string;
};

const CarOfInterest = ({
    event,
    variant,
    calculator,
    miniConfiguratorDetails,
    showDealerSelection,
    disabled = false,
    dealerId,
}: CarOfInterestProps) => {
    if (event.setting.externalSite === EventExternalSite.MARKETINGRECONSENT) {
        // hide car of interest when event is marketing reconsent
        return (
            <CarOfInterestMarketingReconsent
                disabled={disabled}
                event={event}
                showDealerSelection={showDealerSelection}
            />
        );
    }

    if (calculator && (variant || miniConfiguratorDetails)) {
        return (
            <CarOfInterestPorscheFlow
                calculator={calculator}
                externalSite={event.setting.externalSite}
                miniConfiguratorDetails={miniConfiguratorDetails}
                variant={variant}
            />
        );
    }

    return (
        <CarOfInterestChooser
            dealerId={dealerId}
            disabled={disabled}
            event={event}
            showDealerSelection={showDealerSelection}
        />
    );
};

export default CarOfInterest;
