import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValid, parseISO, addDays, setMinutes, setHours } from 'date-fns';
import { format } from 'date-fns-tz';
import { get, isString } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { findTimeZone, getUnixTime, setTimeZone } from 'timezone-support';
import { ZoneScopeFragment } from '../../../../components/data/useLoadScope.graphql';
// eslint-disable-next-line max-len
import { GetReservedAppointmentDatesQuery } from '../../../../components/routes/AppointmentRoute/Detail/Details.graphql';
import DateField from '../../../../components/shared/form-v2/DateField';
import SelectField from '../../../../components/shared/form-v2/SelectField';
import { getRuntimeSettings } from '../../../../selectors';
import { Buttons, PrimaryButton } from '../Calculator/ui';

export type AppointmentFlowFormValues = {
    hasTestDrive?: boolean;
    appointmentDate: Date;
};

// intentionally lowercase it, to reduce error inputs
const mapDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const toDate = (value: any) => {
    if (value === undefined || value === null) {
        return value;
    }

    if (isString(value)) {
        return parseISO(value);
    }

    return value;
};

export const convertLocalToUTC = (date: any, timeZone: string) => {
    const parsedDate = toDate(date);
    const target = findTimeZone(timeZone);
    const time = setTimeZone(parsedDate, target, { useUTC: false });

    return new Date(getUnixTime(time));
};

const setHoursAndMinutes = (date: Date, hours: number, minutes: number) => setMinutes(setHours(date, hours), minutes);
type AppointmentFlowFormProps = {
    reservedAppointments: GetReservedAppointmentDatesQuery['reserved'];
    zone: ZoneScopeFragment;
};

const AppointmentFlowForm = ({
    reservedAppointments,
    handleSubmit,
    change,
    form,
    valid,
    zone,
}: AppointmentFlowFormProps & InjectedFormProps<AppointmentFlowFormValues, AppointmentFlowFormProps>) => {
    const { t } = useTranslation();
    const { appointment: appointmentSetting } = useSelector(getRuntimeSettings);
    const formValues = useSelector<AppointmentFlowFormValues>(getFormValues(form));

    const appointmentDate = get('appointmentDate', formValues) as string;

    const allowedDays = appointmentSetting?.availabilityDays?.map(day => mapDays.indexOf(day.toLowerCase())) ?? [];

    // this was fallback, if environment setting was not found
    const { slotHourEnd = 24, slotHourStart = 0, slotMinuteInterval = 15 } = appointmentSetting;

    const onTimeChange = useCallback(
        (value: string) => {
            const [hour, minute] = value.split(':');
            const newDate = toDate(appointmentDate).setHours(parseInt(hour, 10), parseInt(minute, 10), 0, 0);

            const usedDate = new Date(newDate);
            const utcDateString = convertLocalToUTC(usedDate, zone.timezone);

            change('appointmentDate', utcDateString);
        },
        [appointmentDate, change, zone.timezone]
    );

    const timeOptions = useMemo(() => {
        const currentDate = toDate(appointmentDate);
        const currentDateString = isValid(currentDate) ? format(currentDate, 'yyyy-MM-dd') : null;
        const blockedTimes = reservedAppointments.find(item => item.date === currentDateString)?.times ?? [];
        const availableTimesFromSetting = appointmentSetting?.availabilityTimes ?? [];

        // make an array of hours and minutes for time picker options with label and value
        return Array.from({ length: (slotHourEnd - slotHourStart) * (60 / slotMinuteInterval) }, (_, i) => {
            const hour: number = slotHourStart + Math.floor((i * slotMinuteInterval) / 60);
            const minute: number = (i * slotMinuteInterval) % 60;

            const label = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

            if (
                blockedTimes.includes(value) ||
                (availableTimesFromSetting?.length > 0 && !availableTimesFromSetting.includes(value))
            ) {
                return undefined;
            }

            return { label, value };
        }).filter(Boolean);
    }, [appointmentDate, appointmentSetting, reservedAppointments, slotHourEnd, slotHourStart, slotMinuteInterval]);

    return (
        <>
            <div>
                <DateField
                    allowedDays={allowedDays}
                    label={t('appointmentDetailPage.summary.appointmentDate')}
                    maxDate={
                        appointmentSetting.slotMaxDays
                            ? addDays(setHoursAndMinutes(new Date(), 23, 59), appointmentSetting.slotMaxDays ?? 0)
                            : undefined
                    }
                    minDate={addDays(setHoursAndMinutes(new Date(), 0, 0), appointmentSetting.slotMinDays ?? 0)}
                    name="appointmentDate"
                />
                <SelectField.Outline
                    disabled={!appointmentDate}
                    label={t('appointmentDetailPage.summary.appointmentTime')}
                    name="__exclude.appointmentTime"
                    onChange={onTimeChange}
                    options={timeOptions}
                />
            </div>

            <Buttons>
                <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faAngleRight} /> {t('eventAppointmentPage.button.next')}
                </PrimaryButton>
            </Buttons>
        </>
    );
};

export default reduxForm<AppointmentFlowFormValues, AppointmentFlowFormProps>({
    form: 'appointment',
})(AppointmentFlowForm);
