import { InsuranceValues } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { DarkButton, Actions } from '@appvantageasia/afc-ui';
import { TFunction } from 'i18next';
import { filter, get, isEmpty, omit } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps, FormSection, SubmissionError, FormErrors } from 'redux-form';
import * as yup from 'yup';
import { BankDataFragment } from '../../../../components/data/useLoadBank.graphql';
import { ZoneScopeFragment } from '../../../../components/data/useLoadScope.graphql';
import CheckboxField from '../../../../components/shared/form/CheckboxField';
import FormError from '../../../../components/shared/form/FormError';
import TextareaField from '../../../../components/shared/form/TextareaField';
import { Error as ErrorMessage } from '../../../../components/ui/form/FormActions';
import useFormValues from '../../../../components/utilities/useFormValues';
import { useCountry } from '../../../../hookSelectors';
import { BankSigningMode, CustomerDetailsSource, Channel, UploadPurpose } from '../../../../schema';
import { getIsAuthenticated } from '../../../../selectors';
import AddGuarantorForm from '../AddGuarantor/AddGuarantorForm';
import validateFile from '../KYC/AFC/validateFile';
import CustomerDetailsForm from './CustomerDetailsForm';
import DraftModalProvider from './DraftModalProvider';
import VsoUploading from './VsoUploading';

export type CustomerFormProps = {
    countryCode: string;
    apply: (values: any, validate: (values: any) => void) => Promise<any>;
    bank: BankDataFragment;
    zone: ZoneScopeFragment;
    useCustomerBirthDay: boolean;
    useCustomerNationality: boolean;
    validation: any;
    channel: Channel;
    disableFinanceApplication?: boolean;
    insuranceCalculator?: Partial<InsuranceValues> | undefined;
};

const CustomerForm = (props: CustomerFormProps & InjectedFormProps<any, CustomerFormProps>) => {
    const { valid, handleSubmit, initialValues, bank, channel, change } = props;
    const { channelSetting } = useCountry();
    const { t } = useTranslation();

    const channelSettingData = useMemo(() => {
        switch (channel) {
            case Channel.EXPRESS:
                return channelSetting.express;

            case Channel.USED:
                return channelSetting.used;

            case Channel.NEW:
                return channelSetting.new;

            default:
                throw new Error('Not implemented');
        }
    }, [channel, channelSetting]);

    const {
        isFinanceApplicationMandatory,
        allowFinanceApplication,
        isDepositPaymentMandatory,
        allowProceedWithCustomerDevice,
    } = channelSettingData;

    const { isGuarantorIncluded, hasVSOUpload } = bank;

    // get authentication status
    const isAuthenticated = useSelector(getIsAuthenticated);

    const hasProceedWithCustomerDevice = isAuthenticated && allowProceedWithCustomerDevice;

    const identityFromMyInfo = get('identityNumber.source', initialValues) === CustomerDetailsSource.MYINFO;

    const values = useFormValues();

    const hasGuarantorOption =
        allowFinanceApplication && (isFinanceApplicationMandatory || get('application.appliedForFinancing', values));

    const occupation = get('customer.details.employment.occupation.value', values);

    const canSubmit =
        isFinanceApplicationMandatory ||
        values.application?.appliedForFinancing ||
        values.application?.appliedForInsurance ||
        isDepositPaymentMandatory;

    useEffect(() => {
        if (!hasGuarantorOption) {
            change('withGuarantor', false);
            change('guarantor', undefined);
        }
    }, [hasGuarantorOption, change]);

    useEffect(() => {
        if (occupation) {
            change('customer.details.employment.employer', {
                value: occupation,
                source: CustomerDetailsSource.MANUAL,
            });
        }
    }, [occupation, change]);

    return (
        <DraftModalProvider change={change} handleSubmit={handleSubmit}>
            {setDraftModal => (
                <>
                    <CustomerDetailsForm identityFromMyInfo={identityFromMyInfo} />
                    {hasVSOUpload && <VsoUploading max={3} name="vsoFiles" purpose={UploadPurpose.VSO_UPLOAD} />}
                    <FormSection name="application">
                        <TextareaField.Raw
                            label={t('calculator.label.remarks')}
                            name="remark"
                            placeholder={t('calculator.placeholder.remarks')}
                            style={{ width: '100%' }}
                        />

                        {hasProceedWithCustomerDevice && (
                            <CheckboxField
                                label={t('draftPage.checkbox.proceedWithCustomerDevice')}
                                margin="20px 0"
                                name="proceedWithCustomerDevice"
                            />
                        )}
                    </FormSection>
                    {isGuarantorIncluded && hasGuarantorOption && (
                        <div style={{ margin: '5px 0' }}>
                            <CheckboxField label={t('calculator.label.addGuarantor')} name="withGuarantor" />
                        </div>
                    )}
                    {get('withGuarantor', values) && (
                        <FormSection name="guarantor">
                            <AddGuarantorForm />
                        </FormSection>
                    )}
                    <FormError form="customer">
                        {(error: FormErrors<{}, string>) => <ErrorMessage>{error}</ErrorMessage>}
                    </FormError>
                    <Actions>
                        {isAuthenticated && (
                            <DarkButton disabled={!valid} onClick={() => setDraftModal(true)} type="button">
                                {t('draftPage.button.draft')}
                            </DarkButton>
                        )}
                        <DarkButton disabled={!valid || !canSubmit} onClick={handleSubmit} type="button" value="submit">
                            {t('draftPage.button.next')}
                        </DarkButton>
                    </Actions>
                </>
            )}
        </DraftModalProvider>
    );
};

export const genericCustomerSchema = (t: TFunction) => {
    return yup.object().shape({
        // @ts-ignore
        vsoFiles: yup.lazy((value, options) => {
            const hasVSOUpload = get('context.bank.hasVSOUpload', options);
            if (!hasVSOUpload) {
                return yup.array().notRequired().nullable();
            }

            return yup
                .array()
                .transform(currentValue =>
                    filter(item => {
                        if (!item) {
                            return false;
                        }

                        if (item.purpose !== UploadPurpose.VSO_UPLOAD) {
                            // wrong purpose, skip it
                            return false;
                        }

                        // ensure it's not an empty item
                        return item instanceof File || !isEmpty(omit(['purpose'], item));
                    }, currentValue)
                )
                .of(
                    yup.mixed().test(
                        'extension-validation',
                        t('common.error.fileExtension'),
                        // @ts-ignore
                        validateFile
                    )
                )
                .required(t('common.error.filesRequired'))
                .min(1, t('common.error.filesRequired'));
        }),
    });
};

export default reduxForm<any, CustomerFormProps>({
    form: 'customer',
    // @ts-ignore
    onSubmit: (values, dispatch, props) => {
        const { validate, apply } = props;

        // @ts-ignore
        return apply(values, nextValues => {
            if (!validate) {
                return;
            }

            const errors = validate(nextValues, props);

            if (!isEmpty(errors)) {
                throw new SubmissionError(errors);
            }
        });
    },
})(CustomerForm);
