import React from 'react';
import { useSelector } from 'react-redux';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { useContentTranslation } from '../../../../i18n';
import { EventExternalSite } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import useExteriorImage from '../../../ConfiguratorFlow/components/Configurator/shared/useExteriorImage';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import CarOfInterestWithCsvConfigurator from '../../../CsvFlow/components/CarOfInterestWithCsvConfigurator';
import { CarOfInterestContainer, CarOfInterestVariantName, CarOfInterestVariantPrice } from '../DraftPage/ui';
import CarOfInterestWithFinderVariant from './CarOfInterestWithFinderVariant';
import noImg from '../../../../assets/images/no-image.png';

export type CarOfInterestWithVariantProps = {
    variant?: VariantDataFragment;
    calculator: Partial<CalculatorValues>;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    externalSite?: EventExternalSite;
};

const CarOfInterestWithVariant = ({
    variant,
    calculator,
    miniConfiguratorDetails,
    externalSite,
}: CarOfInterestWithVariantProps) => {
    const { formatCurrency } = useCompanyFormatting();
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const variantImage = useExteriorImage(
        miniConfiguratorDetails?.exteriorColor,
        miniConfiguratorDetails?.interiorColor
    );
    const { ct } = useContentTranslation();

    if (externalSite === EventExternalSite.PORSCHEFINDER) {
        return <CarOfInterestWithFinderVariant {...{ variant, calculator }} />;
    }

    if (externalSite === EventExternalSite.ICC) {
        return <CarOfInterestWithCsvConfigurator calculator={calculator} variant={variant} />;
    }

    const { totalPrice = 0 } = calculator;
    const imageUrl = variant?.images?.[0]?.url || variantImage;
    const variantName = ct(variant?.name) || ckdConfiguration.vehicle.model;

    return (
        <CarOfInterestContainer>
            <div>
                <ImgWithFallback alt={variantName} fallbackSrc={noImg} src={imageUrl || noImg} />
            </div>
            <div className="variantInfo">
                <CarOfInterestVariantName>{variantName}</CarOfInterestVariantName>
                <CarOfInterestVariantPrice>{formatCurrency(totalPrice)}</CarOfInterestVariantPrice>
            </div>
        </CarOfInterestContainer>
    );
};

export default CarOfInterestWithVariant;
