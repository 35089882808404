import { isNil } from 'lodash';
import React from 'react';
import { ApplicationStatus } from '../../../schema';
import { FlowStep } from '../../utils/flow';
import Namirial from '../components/Namirial';
import { NewFlowState } from '../types';

class NamirialStep extends FlowStep<NewFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'namirial';
    }

    public get isCompleted(): boolean {
        const { application } = this.state;

        return !!application?.steps?.approval;
    }

    public render(): React.ReactElement | null {
        const { token, source } = this.state;

        if (!token) {
            throw new Error('token missing in state');
        }

        return <Namirial source={source} token={token} />;
    }
}

export default NamirialStep;
