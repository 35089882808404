import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BankDataFragment } from '../../../../components/data/useLoadBank.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import HelmetTitle from '../../../../components/shared/HelmetTitle';
import useValidation from '../../../../components/utilities/useValidation';
import { Channel } from '../../../../schema';
import Navigation from '../../../utils/Navigation';
import { BackStepContext } from '../../../utils/flow';
import { Page, PageTitle } from '../DraftPage/ui';
import ApplicantForm, { schema } from './ApplicantForm';

type ApplicationProps = {
    event: EventDataFragment;
    backStep?: BackStepContext;
    onSubmit: (values: any) => Promise<void>;
    initialValues: any;
    hasTradeIn?: boolean;
    hasTestDrive?: boolean;
    appliedForFinancing?: boolean;
    dealerId: string;
    bank?: BankDataFragment;
    isCalculatorEnabled?: boolean;
};

const Applicant = ({
    backStep,
    initialValues,
    onSubmit,
    hasTestDrive,
    hasTradeIn,
    appliedForFinancing,
    bank,
    isCalculatorEnabled,
    ...props
}: ApplicationProps) => {
    const { t } = useTranslation();
    const validate = useValidation(schema);
    const [referenceId, setReferenceId] = useState<string>();

    const submit = useCallback(
        (values: any) => {
            return onSubmit({ ...values, referenceId });
        },
        [onSubmit, referenceId]
    );

    return (
        <Page>
            <HelmetTitle channel={Channel.EVENT} title="Applicant Details" />
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <PageTitle>{t('eventApplicantPage.title')}</PageTitle>
            <ApplicantForm
                {...props}
                appliedForFinancing={appliedForFinancing}
                bank={bank}
                hasTestDrive={hasTestDrive}
                hasTradeIn={hasTradeIn}
                initialValues={initialValues}
                isCalculatorEnabled={isCalculatorEnabled}
                onSubmit={submit}
                referenceId={referenceId}
                setReferenceId={setReferenceId}
                validate={validate}
            />
        </Page>
    );
};

export default Applicant;
