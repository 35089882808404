import { TFunction } from 'i18next';
import { get, getOr, isEmpty, isNil } from 'lodash/fp';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps, ReduxFormContext, WrappedFieldArrayProps } from 'redux-form';
import * as yup from 'yup';
import { Action, FieldArrayCustom } from '../../../../../components/shared/form-v2/FileFieldArray';
import NumberField from '../../../../../components/shared/form-v2/NumberField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import { CustomerDetailsSource, RoundingMode } from '../../../../../schema';
import useOptions from '../../../../../utilities/constants/useOptions';
import { yupExt } from '../../../../../utilities/forms';
import { formatCurrency, roundNumber } from '../../../../../utilities/numberHelper';
import { Application } from '../../../types';
import { Title } from '../ui';

type OptionFieldArrayProps = {
    disabled?: boolean;
    name: string;
    dealerOptionsAmount?: number;
};

type OptionFieldsProps = WrappedFieldArrayProps & OptionFieldArrayProps;

const OptionFields = ({ disabled = false, fields, dealerOptionsAmount = 0 }: OptionFieldsProps) => {
    const { t } = useTranslation();

    const data = fields.getAll() || [];

    useEffect(() => {
        if (data.length === 0 && !disabled) {
            // add an empty object
            fields.push({
                description: { value: '', source: CustomerDetailsSource.MANUAL },
                price: roundNumber(2, RoundingMode.ROUNDING)(dealerOptionsAmount / 1.05),
            });
        }
    }, [data, dealerOptionsAmount, disabled, fields]);

    const addAnotherOption = useCallback(() => {
        const hasEmptyItem = fields.getAll()?.some(item => isEmpty(item));

        if (!hasEmptyItem) {
            // we can add another one
            fields.push({
                description: { value: '', source: CustomerDetailsSource.MANUAL },
                price: 0,
            });
        }
    }, [fields]);

    return (
        <div style={{ paddingBottom: !disabled ? 21 : 0 }}>
            {fields.map((name, index, { remove }) => (
                <div key={name} style={{ paddingBottom: fields.length > 1 ? 21 : 0 }}>
                    <TextField
                        disabled={disabled}
                        label={t('customerDetails.label.optionDescription', { no: index + 1 })}
                        name={`${name}.description.value`}
                    />
                    <NumberField
                        disabled={disabled}
                        label={t('customerDetails.label.optionPrice', { no: index + 1 })}
                        name={`${name}.price`}
                        overrideFormats={{
                            currencyPrecision: 2,
                        }}
                        type="currency"
                    />
                    {fields.length > 1 && (
                        <Action onClick={() => remove(index)}>{t('customerDetails.label.removeOption')}</Action>
                    )}
                </div>
            ))}

            {!disabled && <Action onClick={addAnotherOption}>{t('customerDetails.label.addAnotherOption')}</Action>}
        </div>
    );
};
const OptionFieldArray = (props: OptionFieldArrayProps) => <FieldArrayCustom {...props} component={OptionFields} />;

type QuotationDetailsProps = {
    disabled?: boolean;
    application: Application;
    change: InjectedFormProps['change'];
};

const watchedFields = [
    'details.quotation.fullName',
    'details.quotation.commissionNo',
    'details.quotation.engineNo',
    'details.quotation.chassisNo',
    'details.quotation.exteriorColor',
    'details.quotation.downpaymentTo',
    'details.quotation.companyName',
    'details.quotation.financeInsuranceManager',
];

const wrapWithAsterisk = (text: string) => `${text}*`;

const presetNameFinanceManager = 'Bernard Tellis';

const QuotationDetails = ({ disabled = false, application, change }: QuotationDetailsProps) => {
    const { t } = useTranslation();
    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    const values = getValues();

    const { downpaymentTo: downpaymentToOptions } = useOptions();

    const initialFirstName = get(`${sectionPrefix}.firstName.value`, values);
    const initialLastName = get(`${sectionPrefix}.lastName.value`, values);
    const quotationFullName = get(`${sectionPrefix}.details.quotation.fullName.value`, values);
    const financeManager = get(`${sectionPrefix}.details.quotation.financeInsuranceManager.value`, values);

    useEffect(() => {
        if (initialFirstName && initialLastName && isNil(quotationFullName)) {
            change(`${sectionPrefix}.details.quotation.fullName.value`, `${initialFirstName} ${initialLastName}`);
        }
    }, [change, initialFirstName, initialLastName, quotationFullName, sectionPrefix]);

    useEffect(() => {
        if (isNil(financeManager)) {
            change(`${sectionPrefix}.details.quotation.financeInsuranceManager.value`, presetNameFinanceManager);
        }
    }, [change, financeManager, sectionPrefix]);

    return (
        <>
            <Title>{t('kycPage.quotationDetailsTitle')}</Title>
            <TextField
                disabled={disabled || fieldState.details.quotation.fullName}
                label={wrapWithAsterisk(t('customerDetails.label.fullName'))}
                name="details.quotation.fullName.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.commissionNo}
                label={wrapWithAsterisk(t('customerDetails.label.commissionNo'))}
                name="details.quotation.commissionNo.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.engineNo}
                label={wrapWithAsterisk(t('customerDetails.label.engineNo'))}
                name="details.quotation.engineNo.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.chassisNo}
                label={wrapWithAsterisk(t('customerDetails.label.chassisNo'))}
                name="details.quotation.chassisNo.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.exteriorColor}
                label={wrapWithAsterisk(t('customerDetails.label.exteriorColor'))}
                name="details.quotation.exteriorColor.value"
            />
            <OptionFieldArray
                dealerOptionsAmount={application.calculator?.dealerOptions?.amount ?? 0}
                disabled={disabled || (application.calculator?.dealerOptions?.amount ?? 0) === 0}
                name="details.quotation.options"
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.quotation.downpaymentTo}
                label={t('customerDetails.label.downpaymentTo')}
                name="details.quotation.downpaymentTo.value"
                options={downpaymentToOptions}
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.companyName}
                label={wrapWithAsterisk(t('customerDetails.label.companyName'))}
                name="details.quotation.companyName.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.financeInsuranceManager}
                label={wrapWithAsterisk(t('customerDetails.label.financeInsuranceManager'))}
                name="details.quotation.financeInsuranceManager.value"
            />
        </>
    );
};

export default QuotationDetails;

export const quotationSchema = (t: TFunction) => ({
    quotation: yupExt.customerProperty().shape({
        chassisNo: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        commissionNo: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        companyName: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        downpaymentTo: yupExt.customerProperty().shape({
            value: yup.string().nullable().notRequired(),
        }),

        engineNo: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        exteriorColor: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        financeInsuranceManager: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        fullName: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        // @ts-ignore
        options: yup.lazy((values: any[], { context }: any) => {
            const totalDealerOptions = getOr(0, 'application.calculator.dealerOptions.amount', context);
            const totalDealerOptionsNet = roundNumber(2, RoundingMode.ROUNDING)(totalDealerOptions / 1.05);

            if (totalDealerOptionsNet > 0) {
                const companyFormats = get('companyFormats', context);

                const label = t('customerDetails.label.optionPrice', { no: '' });
                const totalDealerOptionsNetFormatted = formatCurrency(2)(
                    totalDealerOptionsNet,
                    companyFormats?.currencySymbol
                );

                const inputtedPrice = values?.reduce((acc, item) => acc + (item?.price ?? 0), 0) ?? 0;

                return yup.array().of(
                    yup.object().shape({
                        description: yupExt.customerProperty().shape({
                            value: yup.string().nullable(),
                        }),
                        // @ts-ignore
                        price: yup.lazy((currentPrice: number) => {
                            const otherPrice = inputtedPrice - currentPrice;

                            const totalEqual = totalDealerOptionsNet - otherPrice;

                            return yup
                                .number()
                                .min(
                                    totalEqual,
                                    t('common.error.totalEqual', { total: totalDealerOptionsNetFormatted, label })
                                )
                                .max(
                                    totalEqual,
                                    t('common.error.totalEqual', { total: totalDealerOptionsNetFormatted, label })
                                );
                        }),
                    })
                );
            }

            return yup.object().shape({}).nullable();
        }),
    }),
});
