import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { getRuntimeSettings } from '../../../../../selectors';
import useOptions from '../../../../../utilities/constants/useOptions';
import { getMinDateOfBirth } from '../../../../../utilities/dates';
import { fromContextValidation, getLastModified, yupExt } from '../../../../../utilities/forms';
import SubTitle from '../../../../ui/SubTitle';
import useCustomerSourceOptions from '../../../../utilities/useCustomerSourceOptions';
import usePhone from '../../../../utilities/usePhone';
import { useDataMask } from '../../../partialForms/CustomerInformationForm';
import useFormatDateTime from '../../../useFormatDateTime';
import DateField from '../../DateField';
import SelectField from '../../SelectField';
import TextField from '../../TextField';

export type CustomerDetailsProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const CustomerDetails = ({ allowPrimaryInfoChanges = false, disabled = false }: CustomerDetailsProps) => {
    const { t } = useTranslation();

    const { code: phoneCountryCode } = usePhone();
    const beingMask = useDataMask();

    // get runtime settings
    const { useCustomerBirthDay } = useSelector(getRuntimeSettings);
    const minDateOfBirth = useMemo(() => getMinDateOfBirth(), []);

    const { getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const formatDateTime = useFormatDateTime();
    const lastModified = getLastModified(formValues.customer?.version, formatDateTime);

    const { genders } = useCustomerSourceOptions();
    const { title, nationalities, maritalStatuses, educations } = useOptions();

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.customerDetails')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.title')}
                        name="title.value"
                        options={title}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={t('customerDetails.label.firstName')}
                        maxLength="200"
                        name="firstName.value"
                        withFocusClear={beingMask}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={t('customerDetails.label.lastName')}
                        maxLength="200"
                        name="lastName.value"
                        withFocusClear={beingMask}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={t('customerDetails.label.email')}
                        name="email.value"
                        type="email"
                        withFocusClear={beingMask}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.mobile')}
                        name="phone.value"
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        type="tel"
                        withFocusClear={beingMask}
                        disabled
                    />
                </div>
                {useCustomerBirthDay && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField
                            label={t('customerDetails.label.dateOfBirth')}
                            maxDate={minDateOfBirth}
                            name="dateOfBirth.value"
                            disabled
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.nationality')}
                        name="details.nationality.value"
                        options={nationalities}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.gender')}
                        name="details.gender.value"
                        options={genders}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={t('customerDetails.label.maritalStatus')}
                        name="details.maritalStatus.value"
                        options={maritalStatuses}
                        sort={false}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.education')}
                        name="details.education.value"
                        options={educations}
                        sort={false}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        fixedValue={lastModified}
                        label={t('customerDetails.label.lastModified')}
                        name="lastModified"
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export const schema = (t: TFunction) => ({
    email: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .email(t('common.error.email'))
            .max(320, t('common.error.lessThanOrEqual', { max: 320 })),
    }),
    firstName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .max(100, t('common.error.lessThanOrEqual', { max: 100 })),
    }),
    lastName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .max(100, t('common.error.lessThanOrEqual', { max: 100 })),
    }),
    phone: yupExt.customerProperty().shape({
        value: fromContextValidation(
            yup.string().required(t('common.error.required')),
            'phonePattern',
            t('common.error.mobile')
        ),
    }),
    // @ts-ignore
    dateOfBirth: yup.lazy((values, options) => {
        const shouldValidate = get(['context', 'useCustomerBirthDay'], options);

        if (shouldValidate) {
            return yupExt.customerProperty().shape({
                value: yup.string().required(t('common.error.required')),
            });
        }

        return yup.mixed().notRequired();
    }),
    title: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
});

export const detailsSchema = (t: TFunction) => ({
    gender: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    maritalStatus: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    nationality: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    education: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
});

export default CustomerDetails;
