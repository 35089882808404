import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { isNil } from 'lodash/fp';
import { customAlphabet } from 'nanoid';
import qs from 'qs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormErrors, FormSection, SubmitHandler, Normalizer } from 'redux-form';
import useMedia from 'use-media';
import * as yup from 'yup';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { BankDataFragment } from '../../../../components/data/useLoadBank.graphql';
import { PromoDataFragment } from '../../../../components/data/useLoadPromo.graphql';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import ReservationDepositField from '../../../../components/shared/calculator/ReservationDepositField';
import FormError from '../../../../components/shared/form/FormError';
import { Error as ErrorMessage } from '../../../../components/ui/form/FormActions';
import { Channel, EventExternalSite } from '../../../../schema';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { yupExt } from '../../../../utilities/forms';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import { CarOfInterestType } from '../../steps/EventDraftStep';
import FinancingForm from '../Calculator/blocks/FinancingForm';
import { Buttons, PrimaryButton } from '../Calculator/ui';
import { ConsentForm, schema as consentFormSchema } from '../ConsentDeposit/ConsentForm';
import DealerInformation from '../shared/DealerInformation';
import { CustomerFormValues, CustomerFormProps } from './CustomerForm';
import CarOfInterest from './Form/CarOfInterest';
import ContactForm, { addressSchema, contactSchema } from './Form/ContactForm';
import CustomerInformationForm, { baseSchema, detailsSchema } from './Form/CustomerInformationForm';
import MyInfo from './Form/MyInfo';
import TradeInVehicleForm from './Form/TradeInVehicleForm';
import { EventGrid, Title, PageTitle, Space } from './ui';

const nanoid = customAlphabet('1234567890abcdef', 24);

export type AFCCustomerFormProps = {
    event: EventDataFragment;
    variant?: VariantDataFragment;
    calculator?: Partial<CalculatorValues>;
    dealerId: string;
    financeProduct?: FinanceDataFragment;
    promo?: PromoDataFragment | null;
    withMyInfoError?: boolean;
    consents?: ConsentDataFragment[];
    referenceId: string | undefined;
    setReferenceId: (id: string) => unknown;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    bookingId?: string;
    nextHasTestDrive?: boolean;
    nextHasTradeIn?: boolean;
    nextIsCalculatorEnabled?: boolean;
    appliedForFinancing?: boolean;
    bank?: BankDataFragment;
    carOfInterest: CarOfInterestType | undefined;
    consentStatuses: Record<string, boolean> | undefined;
    valid: boolean;
    hasConsents: boolean;
    showDealerSelection: boolean;
    step: string;
    handleSubmit: SubmitHandler<CustomerFormValues, CustomerFormProps, string>;
    onNormalizeNames: {
        onNormalizeFullName: Normalizer;
        onNormalizeFirstName: Normalizer;
        onNormalizeLastName: Normalizer;
    };
};

const CustomerForm = (props: AFCCustomerFormProps) => {
    const {
        valid,
        handleSubmit,
        event,
        variant,
        calculator,
        financeProduct,
        promo,
        withMyInfoError,
        consents,
        consentStatuses,
        referenceId,
        setReferenceId,
        miniConfiguratorDetails,
        bookingId,
        nextHasTestDrive,
        nextHasTradeIn,
        nextIsCalculatorEnabled,
        carOfInterest,
        onNormalizeNames,
        dealerId,
        showDealerSelection,
        step,
        appliedForFinancing,
        bank,
    } = props;
    const { t } = useTranslation();
    const isTabletMode = useMedia({ maxWidth: breakpoints.xl });
    const eventSetting = event.setting;

    const { myInfo, allowTradeIn, allowTestDrive, externalSite } = eventSetting;
    const isPorscheFinderEnabled = externalSite === EventExternalSite.PORSCHEFINDER;
    const isIccCheckoutEnabled = externalSite === EventExternalSite.ICC;
    const isPorscheFinderOrIccCheckout = isPorscheFinderEnabled || isIccCheckoutEnabled;

    const customizedMyInfo = useMemo(() => {
        if (isNil(myInfo)) {
            return { requestUrl: '' };
        }

        const {
            endpoints: { authorize: authorizeEndpoint },
            clientId,
            redirectUri,
            attributes,
            purpose,
        } = myInfo;
        const authorizeParams = {
            client_id: clientId,
            purpose,
            redirect_uri: redirectUri,
            attributes: [
                ...attributes.base,
                ...(nextHasTestDrive ? attributes.drivingLicense : []),
                ...(nextHasTradeIn ? attributes.vehicle : []),
            ].join(','),
            state: nanoid(),
        };

        return { requestUrl: `${authorizeEndpoint}?${qs.stringify(authorizeParams)}` };
    }, [myInfo, nextHasTestDrive, nextHasTradeIn]);
    const hasConsents = !!consents?.length;

    const renderReservationField = useMemo(() => {
        const hasDepositPayment = !!event?.setting?.isDepositPaymentMandatory;
        const paymentAmount = event?.setting?.bookingPayment?.amount;

        return hasDepositPayment && <ReservationDepositField amount={paymentAmount} channel={Channel.EVENT} />;
    }, [event]);

    return (
        <>
            <PageTitle>{event.name}</PageTitle>
            <EventGrid>
                <Space>
                    <FormSection name="carOfInterest">
                        <CarOfInterest
                            calculator={calculator}
                            dealerId={dealerId}
                            event={event}
                            miniConfiguratorDetails={miniConfiguratorDetails}
                            showDealerSelection={showDealerSelection}
                            variant={variant}
                        />
                    </FormSection>
                    {renderReservationField}
                    {!isPorscheFinderOrIccCheckout && (
                        <FinancingForm
                            allowTestDrive={allowTestDrive}
                            allowTradeIn={allowTradeIn}
                            page="eventDraftPage"
                        />
                    )}
                    <DealerInformation dealerId={dealerId} />
                    {myInfo && (
                        <MyInfo
                            appliedForFinancing={appliedForFinancing}
                            bank={bank}
                            bookingId={bookingId}
                            calculator={calculator}
                            carOfInterest={carOfInterest}
                            dealerId={dealerId}
                            event={event}
                            financeProduct={financeProduct}
                            hasTestDrive={nextHasTestDrive}
                            hasTradeIn={nextHasTradeIn}
                            isCalculatorEnabled={nextIsCalculatorEnabled}
                            myInfo={customizedMyInfo}
                            promo={promo}
                            step={step}
                            variant={variant}
                            withMyInfoError={withMyInfoError}
                        />
                    )}
                </Space>
                <div>
                    <FormSection name="customerInfo">
                        <CustomerInformationForm
                            appliedForFinancing={appliedForFinancing}
                            hasTestDrive={nextHasTestDrive}
                            onNormalizeNames={onNormalizeNames}
                        />
                    </FormSection>
                    <FormSection name="customerInfo">
                        <ContactForm />
                    </FormSection>
                    {isTabletMode && (
                        <>
                            {nextHasTradeIn && <TradeInVehicleForm />}
                            {hasConsents && (
                                <>
                                    <Title>{t('eventConsentDepositPage.consentTitle')}</Title>
                                    <FormSection name="consentDraft">
                                        <ConsentForm
                                            consentStatuses={consentStatuses}
                                            consents={consents!}
                                            referenceId={referenceId}
                                            setReferenceId={setReferenceId}
                                        />
                                    </FormSection>
                                </>
                            )}
                            <Buttons>
                                <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                                    <FontAwesomeIcon icon={faAngleRight} /> {t('eventDraftPage.button.next')}
                                </PrimaryButton>
                            </Buttons>
                        </>
                    )}
                </div>
                {!isTabletMode && (
                    <div>
                        {nextHasTradeIn && <TradeInVehicleForm />}
                        {hasConsents && (
                            <>
                                <Title>{t('eventConsentDepositPage.consentTitle')}</Title>
                                <FormSection name="consentDraft">
                                    <ConsentForm
                                        consentStatuses={consentStatuses}
                                        consents={consents!}
                                        referenceId={referenceId}
                                        setReferenceId={setReferenceId}
                                    />
                                </FormSection>
                            </>
                        )}
                        <Buttons>
                            <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                                <FontAwesomeIcon icon={faAngleRight} /> {t('eventDraftPage.button.next')}
                            </PrimaryButton>
                        </Buttons>
                    </div>
                )}
            </EventGrid>
            <FormError form="customer">
                {(error: FormErrors<{}, string>) => <ErrorMessage>{error}</ErrorMessage>}
            </FormError>
        </>
    );
};

// remove error message for required in events
export const schema = (hasCalculator: boolean, isMarketingReconsent: boolean) => (t: TFunction) => {
    const shape: yup.ObjectSchemaDefinition<any> = {
        consentDraft: consentFormSchema()(t),
        customerInfo: yup.object().shape({
            ...baseSchema(t),
            ...contactSchema(t),
            details: yup.object().shape({
                ...detailsSchema,
                residentialAddress: yupExt.customerProperty().shape(addressSchema(t)),
            }),
        }),
        dealerId: yup.string().required(' '),
    };

    if (!hasCalculator && !isMarketingReconsent) {
        shape.carOfInterest = yup.object().shape(
            {
                assetCondition: yup.string().required(' '),
                /** add this validation if variant name not exist */
                modelId: yup
                    .string()
                    .notRequired()
                    .when('variantName', {
                        is: val => val === undefined,
                        then: yup.string().required(' '),
                        otherwise: yup.string().notRequired(),
                    }),
                variantId: yup
                    .string()
                    .notRequired()
                    .when('variantName', {
                        is: val => val === undefined,
                        then: yup.string().required(' '),
                        otherwise: yup.string().notRequired(),
                    }),
                /** add this validation if model id not exist */
                variantName: yup
                    .string()
                    .notRequired()
                    .when('modelId', {
                        is: val => val === undefined,
                        then: yup.string().required(' '),
                        otherwise: yup.string().notRequired(),
                    }),
            },
            // @ts-ignore
            ['variantName', 'modelId']
        );
    }

    return yup.object().shape(shape);
};

export default CustomerForm;
