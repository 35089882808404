import { omit } from 'lodash/fp';
import React from 'react';
import {
    getFlowConsentsAndDeclarations,
    GetFlowConsentsAndDeclarationsQuery,
    GetFlowConsentsAndDeclarationsQueryVariables,
} from '../../../api/consents.graphql';
import {
    createCustomer,
    CreateCustomerMutation,
    CreateCustomerMutationVariables,
    updateCustomer,
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables,
} from '../../../api/customer.graphql';
import {
    create,
    CreateMutation,
    CreateMutationVariables,
    createInsuranceApplication,
    CreateInsuranceApplicationMutation,
    CreateInsuranceApplicationMutationVariables,
} from '../../../api/draft.graphql';
import {
    executeBookInventory,
    ExecuteBookInventoryMutation,
    ExecuteBookInventoryMutationVariables,
} from '../../../api/miscellaneous.graphql';
import { ApplicationCustomerDataFragment } from '../../../components/routes/ApplicationRoute/data.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { Channel, CustomerType, Customer, ApplicationFlowSource } from '../../../schema';
import getCalculatorPayload from '../../../utilities/calculator/getCalculatorPayload';
import { mapCreateCustomerToPayload } from '../../../utilities/customer';
import { prepareForGraphQL } from '../../../utilities/forms';
import { getDraftUrl, getInsuranceDraftUrl, getLocationCode } from '../../../utilities/urls';
import { ReduxFormFlowStep } from '../../utils/flow';
import { FileInput, uploadFiles } from '../../utils/uploads';
import DraftRoute from '../components/DraftPage';
import { NewFlowState } from '../types';

export type DraftStepValues = {
    draftTo?: string; // assignee ID to provide when drafting
    customer: ApplicationCustomerDataFragment;
    uploadId?: string;
    application?: {
        remark?: string;
        appliedForFinancing?: boolean;
        proceedWithCustomerDevice?: boolean;
        appliedForInsurance?: boolean;
    };
    vsoFiles?: FileInput[];
    withGuarantor?: boolean;
    guarantor?: Pick<ApplicationCustomerDataFragment, 'email' | 'firstName' | 'phone' | 'identityNumber'>;
    insurance?: {
        calculator: {
            driverLicensePassDate: Date;
            claimDiscount: number;
            occupation: string;
            ncd: string;
            existingCarPlate: string;
            displacement: number;
            price: number;
        };
        comment?: string;
    };
};

type State = NewFlowState & {
    unitId?: string;
    customer?: Customer;
};

class DraftStep extends ReduxFormFlowStep<State, DraftStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return ApplicationFlowSource.DRAFT;
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('draftPage.label.step');
    }

    public get channelSetting() {
        const { channel, country } = this.state;

        switch (channel) {
            case Channel.EXPRESS:
                return country.channelSetting.express;

            case Channel.USED:
                return country.channelSetting.used;

            case Channel.NEW:
                return country.channelSetting.new;

            default:
                throw new Error('Not implemented');
        }
    }

    public render(): React.ReactElement | null {
        const {
            bank,
            zone,
            leadId,
            channel,
            disableFinanceApplication,
            customer,
            dealerId,
            withFinancing,
            withInsurance,
            insuranceCalculator,
        } = this.state;

        if (!bank) {
            throw new Error('Bank is missing in state');
        }

        return (
            <>
                <HelmetTitle channel={channel} title="Applicant Details" />
                <DraftRoute
                    backStep={this.getBackContext()}
                    bank={bank}
                    channel={channel}
                    customer={customer}
                    dealerId={dealerId}
                    disableFinanceApplication={disableFinanceApplication}
                    insuranceCalculator={insuranceCalculator}
                    isFromLead={!!leadId}
                    submit={this.submit}
                    withFinancing={withFinancing || false}
                    withInsurance={withInsurance || false}
                    zone={zone}
                />
            </>
        );
    }

    protected async getGuarantor(values: DraftStepValues) {
        const { apolloClient } = this;
        const { zone } = this.state;

        if (!values.withGuarantor || !values.guarantor) {
            return undefined;
        }

        // prepare payload
        const customerPayload = mapCreateCustomerToPayload(zone.id, {
            withMyInfo: false,
            ...prepareForGraphQL(values.guarantor),
            zoneId: zone.id,
            type: CustomerType.INDIVIDUAL,
        });

        // create a new customer
        const createResponse = await apolloClient.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
            mutation: createCustomer,
            variables: customerPayload,
        });

        const guarantor = createResponse?.data?.customer;

        if (!guarantor) {
            throw new Error('Failed to create guarantor');
        }

        return guarantor.id;
    }

    protected async getCustomer(values: DraftStepValues) {
        const { apolloClient } = this;
        const { zone } = this.state;

        if (!values.customer.id) {
            // prepare payload
            const customerPayload = mapCreateCustomerToPayload(zone.id, {
                ...prepareForGraphQL(values.customer),
                zoneId: zone.id,
                type: CustomerType.INDIVIDUAL,
            });

            // create a new customer
            const createResponse = await apolloClient.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
                mutation: createCustomer,
                variables: customerPayload,
            });

            const customer = createResponse?.data?.customer;

            if (!customer) {
                throw new Error('Failed to create customer');
            }

            // keep the ID for the newly created customer
            return customer.id;
        }

        return values.customer.id;
    }

    protected async handleOptions() {
        const { apolloClient } = this;
        const { calculator, unitId, leadVersionId } = this.state;

        if (!calculator) {
            throw new Error('Calculator missing in state');
        }

        const { carOptions: options, variant: variantId } = calculator;

        if (!variantId) {
            throw new Error('Variant ID is missing');
        }

        // book inventory
        // first list the option ids
        const cleanedOptions = options || [];

        // then try to book it
        // reference ID in meta should be the application version ID (or one provided by the API)
        const { data: bookingData } = await apolloClient.mutate<
            ExecuteBookInventoryMutation,
            ExecuteBookInventoryMutationVariables
        >({
            mutation: executeBookInventory,
            variables: { referenceId: leadVersionId, variantId, options: cleanedOptions, unitId },
        });

        return bookingData?.response;
    }

    protected async execute(values: DraftStepValues) {
        const { apolloClient, channelSetting } = this;
        const {
            zone,
            calculator,
            financeProduct,
            leadId,
            channel,
            promo,
            country,
            expressVariant,
            disableFinanceApplication,
            hasTradeIn,
            hasTestDrive,
            dealerId,
        } = this.state;
        const allowOptions = channel === Channel.NEW && country.channelSetting.new.allowOptions;

        if (!calculator) {
            throw new Error('Calculator missing in state');
        }

        if (!financeProduct) {
            throw new Error('Missing finance product in state');
        }

        // get customer document ready
        const customerId = await this.getCustomer(values);
        // get guarantor document ready
        const guarantorId = await this.getGuarantor(values);

        const { appliedForFinancing = false, remark, proceedWithCustomerDevice = false, appliedForInsurance } =
            values.application || {};
        const {
            allowFinanceApplication,
            isFinanceApplicationMandatory,
            isInsuranceEnabled,
            isDepositPaymentMandatory,
        } = channelSetting;
        const hasPromoStock = promo && promo.remainingQuantity > 0;

        if (
            !isFinanceApplicationMandatory &&
            !appliedForFinancing &&
            !isDepositPaymentMandatory &&
            appliedForInsurance &&
            values.insurance
        ) {
            const insuranceApplicationPayload = prepareForGraphQL({
                // drafting
                assigneeId: values.draftTo,
                draft: !!values.draftTo,
                // add the zone id
                zoneId: zone.id,
                // and dealer id
                dealerId,
                customerId,
                uploadId: values.uploadId,
                // add the channel
                channel,
                variantId: calculator.variant,
                locale: this.i18n.languages[0],
                calculator: {
                    ...values.insurance.calculator,
                    displacement: calculator?.displacement || 0,
                    price: calculator?.carPrice || 0,
                    coe: calculator.coe ? { amount: calculator.coe } : undefined,
                },
                comment: values.insurance.comment,
                proceedWithCustomerDevice: !values.draftTo && proceedWithCustomerDevice,
            });

            const draftResponse = await apolloClient.mutate<
                CreateInsuranceApplicationMutation,
                CreateInsuranceApplicationMutationVariables
            >({
                mutation: createInsuranceApplication,
                variables: { data: insuranceApplicationPayload },
            });

            const { insuranceApplication, token } = draftResponse.data?.response || {};

            if (!insuranceApplication) {
                throw new Error('application missing in state');
            }

            if (!token) {
                throw new Error('token missing in state');
            }

            const consentsResponse = await apolloClient.query<
                GetFlowConsentsAndDeclarationsQuery,
                GetFlowConsentsAndDeclarationsQueryVariables
            >({
                query: getFlowConsentsAndDeclarations,
                variables: { consent: { dealerId, insuranceId: insuranceApplication.insuranceId } },
            });
            const consents = consentsResponse.data?.result || [];

            if (values.customer?.id) {
                // create a clean payload
                const customerPayload = omit(
                    ['id', 'type'],
                    values.customer
                ) as UpdateCustomerMutationVariables['data'];

                // update existing customer
                await apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
                    mutation: updateCustomer,
                    variables: {
                        id: values.customer.id,
                        data: prepareForGraphQL(customerPayload),
                        token,
                    },
                });
            }

            // update the state
            this.stateStore.update({ insuranceApplication, token, consents });

            if (insuranceApplicationPayload.draft) {
                // trigger events
                this.flow.dispatchCompleted();

                // no next step from here
                return null;
            }

            if (!this.isLastStep) {
                const { company, country, zone } = this.state;

                const locationCode = getLocationCode(country.code, zone.code);
                const url = getInsuranceDraftUrl(company.code, locationCode, insuranceApplication.version.id);

                this.flow.history.push(url, { token });

                // no next step from here
                return null;
            }

            return this.nextStep;
        }

        // handle options
        const preDraftId = allowOptions ? await this.handleOptions() : undefined;

        // build application payload
        const applicationPayload = prepareForGraphQL({
            // drafting
            assigneeId: values.draftTo,
            draft: !!values.draftTo,
            // add the zone id
            zoneId: zone.id,
            // and dealer id
            dealerId,
            // computed applied for financing value
            appliedForFinancing:
                !disableFinanceApplication &&
                allowFinanceApplication &&
                (isFinanceApplicationMandatory || appliedForFinancing),
            // pick remark and proceed with customer device from the customer values directly
            remark,
            proceedWithCustomerDevice: !values.draftTo && proceedWithCustomerDevice,
            // add the customer id
            customerId,
            // and guarantor
            guarantorId,
            // the booking id and lead id comes from meta, as does upload id
            leadId,
            bookingId: preDraftId,
            uploadId: values.uploadId,
            // add the channel
            channel,
            // build options
            optionIds: allowOptions && calculator.carOptions ? calculator.carOptions : [],
            // build bank settings
            bank: { id: calculator.bank },
            // finance product id and variant id comes from the calculator
            financeProductId: calculator.financeProduct,
            variantId: calculator.variant,
            // then calculator payload
            calculator: getCalculatorPayload(calculator),
            // promotion code
            promoCodeId: hasPromoStock ? promo?.id : null,
            // for trade in
            hasTradeIn,
            // for test drive
            hasTestDrive,
            // application locale
            locale: this.i18n.languages[0],
            // application insurance
            ...(isInsuranceEnabled &&
                appliedForInsurance &&
                values.insurance && {
                    insurance: {
                        calculator: {
                            ...values.insurance.calculator,
                            displacement: calculator?.displacement || 0,
                            price: calculator?.carPrice || 0,
                            coe: calculator.coe ? { amount: calculator.coe } : undefined,
                        },
                        comment: values.insurance.comment,
                    },
                }),
        });

        const draftResponse = await apolloClient.mutate<CreateMutation, CreateMutationVariables>({
            mutation: create,
            variables: { data: applicationPayload },
        });

        const { application, token } = draftResponse.data?.response || {};

        const consentsResponse = await apolloClient.query<
            GetFlowConsentsAndDeclarationsQuery,
            GetFlowConsentsAndDeclarationsQueryVariables
        >({
            query: getFlowConsentsAndDeclarations,
            variables: { consent: { dealerId, bankId: calculator.bank } },
        });
        const consents = consentsResponse.data?.result || [];

        if (!application) {
            throw new Error('application missing in state');
        }

        if (!token) {
            throw new Error('token missing in state');
        }

        if (values.vsoFiles && values.vsoFiles.length > 0) {
            await uploadFiles(apolloClient, values.vsoFiles, [], null, application.id, token);
        }

        // save vehicle log if express and has value
        if (expressVariant && expressVariant.preOwnedCarDetails?.vehicleLogCard) {
            await uploadFiles(
                apolloClient,
                [expressVariant.preOwnedCarDetails.vehicleLogCard],
                [],
                null,
                application.id,
                token
            );
        }

        // update customer if needed
        // make sure to pass token for update customer
        if (values.customer?.id) {
            // create a clean payload
            const customerPayload = omit(['id', 'type'], values.customer) as UpdateCustomerMutationVariables['data'];

            // update existing customer
            await apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
                mutation: updateCustomer,
                variables: {
                    id: values.customer.id,
                    data: prepareForGraphQL(customerPayload),
                    token,
                },
            });
        }

        // update the state
        this.stateStore.update({ application, token, consents });

        if (applicationPayload.draft) {
            // trigger events
            this.flow.dispatchCompleted();

            // no next step from here
            return null;
        }

        if (!this.isLastStep) {
            const { company, country, zone } = this.state;

            const locationCode = getLocationCode(country.code, zone.code);
            const url = getDraftUrl(company.code, locationCode, application.version.id);

            this.flow.history.push(url, { token });

            // no next step from here
            return null;
        }

        return this.nextStep;
    }
}

export default DraftStep;
