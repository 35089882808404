import { flow, get, map, uniqBy } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useLoadVariants from '../../../../components/data/useLoadVariants';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { useContentTranslation } from '../../../../i18n';
import { Channel, EventExternalSite } from '../../../../schema';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import { CarOfInterestType } from '../../steps/EventDraftStep';
import { useAssetConditionOptions } from '../DraftPage/Form/CarOfInterestSelection';
import { TextContainer, Title } from '../DraftPage/ui';
import CarOfInterestWithVariant from './CarOfInterestContainer';

export const getModelsFromVariants = flow([map(({ model }) => model), uniqBy(get('id'))]);

export type SelectedCarProps = {
    variant?: VariantDataFragment;
    calculator?: Partial<CalculatorValues>;
    carOfInterest?: CarOfInterestType;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    dealerId: string;
    event: EventDataFragment;
};

const SelectedCar = ({
    variant,
    calculator,
    carOfInterest,
    miniConfiguratorDetails,
    dealerId,
    event,
}: SelectedCarProps) => {
    const { t } = useTranslation();
    const { variants } = useLoadVariants('cache-first', Channel.EVENT, dealerId);
    const { ct } = useContentTranslation();

    if (event.setting.externalSite === EventExternalSite.MARKETINGRECONSENT) {
        // display nothing for marketing reconsent
        return null;
    }

    if (calculator && (variant || miniConfiguratorDetails)) {
        return (
            <CarOfInterestWithVariant
                calculator={calculator}
                externalSite={event.setting.externalSite}
                miniConfiguratorDetails={miniConfiguratorDetails}
                variant={variant}
            />
        );
    }

    const selectedVariant = carOfInterest
        ? variants.filter(variant => variant.id === carOfInterest?.variantId)[0]
        : null;
    const selectedModel = selectedVariant ? ct(selectedVariant.model.name) : null;
    const assetCondition = useAssetConditionOptions().find(option => option.value === carOfInterest?.assetCondition)
        ?.label;

    return (
        <TextContainer>
            <Title>{t('eventVehicleOfInterest.title')}</Title>
            <div>
                {assetCondition}
                <hr />
                {carOfInterest?.variantName ? (
                    carOfInterest?.variantName
                ) : (
                    <>
                        {selectedModel}
                        <hr />
                        {ct(selectedVariant?.name)}
                    </>
                )}
                <hr />
            </div>
        </TextContainer>
    );
};

export default SelectedCar;
