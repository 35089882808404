import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { useFinderContext } from '../../../../components/routes/FinderRoute/FinderContext';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { useContentTranslation } from '../../../../i18n';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import { MonthlyPayment } from '../Calculator/blocks/Financing';
import { CarOfInterestContainer, CarOfInterestVariantName, CarOfInterestVariantPrice } from '../DraftPage/ui';
import noImg from '../../../../assets/images/no-image.png';

export type CarOfInterestWithVariantProps = {
    variant?: VariantDataFragment;
    calculator: Partial<CalculatorValues>;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    isPorscheFinder?: boolean;
};

const ImageBox = styled.div<{ $color?: string }>`
    width: 60px;
    height: 60px;
    position: relative;
    ${({ $color }) => $color && `background-color: ${$color};`}
`;

const FlexItem = styled.div<{ $width?: string }>`
    padding: 10px 4px;

    ${({ $width }) => $width && `width: ${$width};`}
`;

const Flex = styled.div`
    display: flex;
    border-bottom: 1px solid #dddddd;

    &:last-child {
        border-bottom: none;
    }
`;

const DetailInfo = styled.div`
    border: 1px solid #dddddd;
    border-top: 0;
    padding: 0 10px;
`;

const ImageWrapper = styled.div`
    padding: 2px;
    border: 1px solid #dddddd;
`;

const PrimaryText = styled.p`
    margin-bottom: 0;
    font-weight: 600;
`;

const SecondaryText = styled.p`
    margin-bottom: 0;
    color: #737278;
`;

const CarOfInterestWithFinderVariant = ({ variant, calculator }: CarOfInterestWithVariantProps) => {
    const { formatCurrency } = useCompanyFormatting();
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    // Fetch finder info
    const finderVehicle = useFinderContext();
    const vehicle = finderVehicle?.listing?.vehicle;

    const { totalPrice = 0 } = calculator;
    const imageUrl = variant?.images?.[0]?.url;
    const variantName = ct(variant?.name);

    const monthlyPayment = calculator?.monthlyInstalments ? calculator.monthlyInstalments[0].amount : undefined;

    const seatStichColor = vehicle?.interior?.color?.seatStitchingColorHexCode ?? '#ffffff';
    const seatColor = vehicle?.interior?.color?.seatColorHexCode ?? '#ffffff';
    const cockpitStichColor = vehicle?.interior?.color?.cockpitStitchingColorHexCode ?? '#ffffff';
    const cockpitColor = vehicle?.interior?.color?.cockpitColorHexCode ?? '#ffffff';

    return (
        <>
            <CarOfInterestContainer
                onClick={() => setOpen(oldOpen => !oldOpen)}
                style={{ marginBottom: isOpen ? 0 : undefined, cursor: 'pointer' }}
            >
                <div>
                    <ImgWithFallback alt={variantName} fallbackSrc={noImg} src={imageUrl || noImg} />
                </div>
                <div className="variantInfo" style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: 6, right: 6, width: 'auto' }}>
                        <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} size="lg" />
                    </div>
                    <CarOfInterestVariantName>{variantName}</CarOfInterestVariantName>
                    <CarOfInterestVariantPrice>{formatCurrency(totalPrice)}</CarOfInterestVariantPrice>
                    <MonthlyPayment style={{ padding: '0 10px', width: '100%', fontSize: '85%' }}>
                        <span>
                            <Trans
                                components={{ s: <span /> }}
                                i18nKey="eventCalculatorPage.label.monthlyPayment"
                                values={{ monthlyPayment: formatCurrency(monthlyPayment) }}
                            />
                        </span>
                    </MonthlyPayment>
                </div>
            </CarOfInterestContainer>
            {isOpen && (
                <DetailInfo>
                    <Flex>
                        <FlexItem>
                            <ImageWrapper>
                                <ImageBox $color={vehicle?.exteriorColor?.value} />
                            </ImageWrapper>
                        </FlexItem>
                        <FlexItem>
                            <SecondaryText>{t('eventVehicleOfInterest.finder.exteriorColor')}</SecondaryText>
                            <PrimaryText>{vehicle?.exteriorColor?.name?.localize}</PrimaryText>
                        </FlexItem>
                    </Flex>
                    <Flex>
                        <FlexItem>
                            <ImageWrapper>
                                <ImageBox style={{ display: 'flex' }}>
                                    <svg
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            left: '0px',
                                            top: '0px',
                                        }}
                                        viewBox="0 0 48 48"
                                    >
                                        <rect fill={seatStichColor} height="11" rx="2" width="2" x="15" y="-1" />
                                        <rect fill={seatStichColor} height="11" rx="2" width="2" x="15" y="12" />
                                        <rect fill={seatStichColor} height="11" rx="2" width="2" x="15" y="25" />
                                        <rect fill={seatStichColor} height="11" rx="2" width="2" x="15" y="38" />
                                        <rect fill={cockpitStichColor} height="11" rx="2" width="2" x="31" y="-1" />
                                        <rect fill={cockpitStichColor} height="11" rx="2" width="2" x="31" y="12" />
                                        <rect fill={cockpitStichColor} height="11" rx="2" width="2" x="31" y="25" />
                                        <rect fill={cockpitStichColor} height="11" rx="2" width="2" x="31" y="38" />
                                    </svg>
                                    <div
                                        style={{
                                            height: '100%',
                                            width: '50%',
                                            backgroundColor: seatColor,
                                        }}
                                    />
                                    <div
                                        style={{
                                            height: '100%',
                                            width: '50%',
                                            backgroundColor: cockpitColor,
                                        }}
                                    />
                                </ImageBox>
                            </ImageWrapper>
                        </FlexItem>
                        <FlexItem>
                            <SecondaryText>{t('eventVehicleOfInterest.finder.interiorColor')}</SecondaryText>
                            <PrimaryText>{vehicle?.interior?.name?.localize}</PrimaryText>
                        </FlexItem>
                    </Flex>
                    <Flex>
                        <FlexItem>
                            <ImageWrapper>
                                <ImageBox
                                    as="img"
                                    src={
                                        vehicle?.transmission?.localize === 'MANUAL'
                                            ? '/uploads/public/images/manual.jpg'
                                            : '/uploads/public/images/automatic.jpg'
                                    }
                                />
                            </ImageWrapper>
                        </FlexItem>
                        <FlexItem>
                            <SecondaryText>{t('eventVehicleOfInterest.finder.transmission')}</SecondaryText>
                            <PrimaryText>{vehicle?.transmission?.localize}</PrimaryText>
                        </FlexItem>
                    </Flex>
                    <Flex>
                        <FlexItem $width="50%">
                            <SecondaryText>{t('eventVehicleOfInterest.finder.mileage')}</SecondaryText>
                        </FlexItem>
                        <FlexItem $width="50%">
                            <PrimaryText>{vehicle?.mileage?.localize}</PrimaryText>
                        </FlexItem>
                    </Flex>
                    <Flex>
                        <FlexItem $width="50%">
                            <SecondaryText>{t('eventVehicleOfInterest.finder.firstRegistration')}</SecondaryText>
                        </FlexItem>
                        <FlexItem $width="50%">
                            <PrimaryText>{vehicle?.firstRegistrationDate?.localize}</PrimaryText>
                        </FlexItem>
                    </Flex>
                    <Flex>
                        <FlexItem $width="50%">
                            <SecondaryText>{t('eventVehicleOfInterest.finder.previousOwners')}</SecondaryText>
                        </FlexItem>
                        <FlexItem $width="50%">
                            <PrimaryText>{vehicle?.numberOfPreviousOwners}</PrimaryText>
                        </FlexItem>
                    </Flex>
                    <Flex>
                        <FlexItem $width="50%">
                            <SecondaryText>
                                {t('eventVehicleOfInterest.finder.maximumPowerCombustionEngine')}
                            </SecondaryText>
                        </FlexItem>
                        <FlexItem $width="50%">
                            <PrimaryText>
                                {vehicle?.power?.kilowatt?.localize}/{vehicle?.power?.horsepower?.localize}
                            </PrimaryText>
                        </FlexItem>
                    </Flex>
                    <Flex>
                        <FlexItem $width="50%">
                            <SecondaryText>{t('eventVehicleOfInterest.finder.accidentFree')}</SecondaryText>
                        </FlexItem>
                        <FlexItem $width="50%">
                            <PrimaryText>
                                {vehicle?.accidentFree
                                    ? t('eventVehicleOfInterest.accidentFree.yes')
                                    : t('eventVehicleOfInterest.accidentFree.no')}
                            </PrimaryText>
                        </FlexItem>
                    </Flex>
                    <Flex>
                        <FlexItem $width="50%">
                            <SecondaryText>{t('eventVehicleOfInterest.finder.engine')}</SecondaryText>
                        </FlexItem>
                        <FlexItem $width="50%">
                            <PrimaryText>{vehicle?.engineType?.localize}</PrimaryText>
                        </FlexItem>
                    </Flex>
                </DetailInfo>
            )}
        </>
    );
};

export default CarOfInterestWithFinderVariant;
