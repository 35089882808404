import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext, Normalizer } from 'redux-form';
import * as yup from 'yup';
import DateField from '../../../../../components/shared/form-v2/DateField';
import IdentityNumberField from '../../../../../components/shared/form-v2/IdentityNumberField';
import NationalityField from '../../../../../components/shared/form-v2/NationalityField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import useCustomerSourceOptions from '../../../../../components/utilities/useCustomerSourceOptions';
import { CustomerDetailsSource } from '../../../../../schema';
import useOptions from '../../../../../utilities/constants/useOptions';
import { getMinDateOfBirth } from '../../../../../utilities/dates';
import { onTextKeyPress, validateNirc, validateTextFieldOnly, yupExt } from '../../../../../utilities/forms';
import DrivingLicense, { drivingLicenseSchema } from '../../shared/DrivingLicense';
import { Title } from '../ui';

const watchedFields = [
    'title',
    'name',
    'firstName',
    'lastName',
    'identityNumber',
    'dateOfBirth',
    'details.nationality',
    'details.gender',
    'details.maritalStatus',
    'details.residentialStatus',
    'details.nationality',
];

type CustomerInformationFormProps = {
    isMyInfo?: boolean;
    disabled?: boolean;
    onNormalizeNames: {
        onNormalizeFullName: Normalizer;
        onNormalizeFirstName: Normalizer;
        onNormalizeLastName: Normalizer;
    };
    hasTestDrive?: boolean;
    appliedForFinancing?: boolean;
};

const CustomerInformationForm = ({
    isMyInfo = false,
    disabled = false,
    onNormalizeNames: { onNormalizeFirstName, onNormalizeLastName, onNormalizeFullName },
    hasTestDrive = false,
    appliedForFinancing = false,
}: CustomerInformationFormProps) => {
    const { t } = useTranslation();
    const { sectionPrefix, initialValues }: any = useContext(ReduxFormContext);
    const fieldState = useCustomerSource(watchedFields, sectionPrefix);
    const withMyInfo = get('withMyInfo', initialValues);
    const hasMyInfoDrivingLicense = withMyInfo && get('details.drivingLicense.length', initialValues);

    const minDateOfBirth = useMemo(() => getMinDateOfBirth(), []);
    const { genders, residentialStatuses } = useCustomerSourceOptions();

    const { title, maritalStatuses } = useOptions();

    return (
        <>
            <Title>{t('eventCustomerDetails.title')}</Title>
            <SelectField.Outline
                disabled={disabled || fieldState.title}
                label={`${t('eventCustomerDetails.label.title')}*`}
                name="title.value"
                options={title}
            />
            {isMyInfo && (
                <TextField
                    disabled={disabled || fieldState.name}
                    label={t('eventCustomerDetails.label.name')}
                    name="name.value"
                    normalize={onNormalizeFullName}
                />
            )}
            {!isMyInfo && (
                <>
                    <TextField
                        disabled={disabled || fieldState.firstName}
                        label={t('eventCustomerDetails.label.firstName')}
                        name="firstName.value"
                        normalize={onNormalizeFirstName}
                        onKeyPress={onTextKeyPress}
                    />
                    <TextField
                        disabled={disabled || fieldState.lastName}
                        label={t('eventCustomerDetails.label.lastName')}
                        name="lastName.value"
                        normalize={onNormalizeLastName}
                        onKeyPress={onTextKeyPress}
                    />
                </>
            )}
            <DateField
                disabled={disabled || fieldState.dateOfBirth}
                label={t('eventCustomerDetails.label.dateOfBirth')}
                maxDate={minDateOfBirth}
                name="dateOfBirth.value"
            />
            <NationalityField
                disabled={disabled || fieldState.details.nationality}
                label={t('eventCustomerDetails.label.nationality')}
                name="details.nationality.value"
            />
            {appliedForFinancing && (
                <IdentityNumberField
                    disabled={disabled || fieldState.identityNumber}
                    label={`${t('eventCustomerDetails.label.identityNumberFinder')}*`}
                    maxLength="200"
                    name="identityNumber.value"
                    placeholder={t('customerDetails.placeholder.identityNumber')}
                />
            )}
            <SelectField.Outline
                disabled={disabled || fieldState.details.gender}
                label={t('eventCustomerDetails.label.gender')}
                name="details.gender.value"
                options={genders}
            />
            <SelectField.Outline
                disabled={disabled}
                label={t('eventCustomerDetails.label.maritalStatus')}
                name="details.maritalStatus.value"
                options={maritalStatuses}
                sort={false}
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.residentialStatus}
                label={t('eventCustomerDetails.label.residentialStatus')}
                name="details.residentialStatus.value"
                options={residentialStatuses}
                sort={false}
            />
            {!hasMyInfoDrivingLicense && hasTestDrive && <DrivingLicense disabled={disabled} />}
        </>
    );
};

export default CustomerInformationForm;

// remove error message for required in events
export const baseSchema = (t: TFunction) => ({
    name: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .test(
                'invalid-text-input',
                t('common.error.alphabet'),
                (value?: string | null) => !value || validateTextFieldOnly(value)
            ),
    }),

    firstName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .test(
                'invalid-text-input',
                t('common.error.alphabet'),
                (value?: string | null) => !value || validateTextFieldOnly(value)
            ),
    }),

    // @ts-ignore
    lastName: yup.lazy(value => {
        if (get('source', value) === CustomerDetailsSource.MYINFO) {
            return yup.mixed().notRequired();
        }

        return yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .test(
                    'invalid-text-input',
                    t('common.error.alphabet'),
                    (value?: string | null) => !value || validateTextFieldOnly(value)
                ),
        });
    }),
    title: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),

    // @ts-ignore
    identityNumber: yup.lazy((values: any, options: any) => {
        const { context, parent } = options;

        if (context?.appliedForFinancing) {
            const identityValidator = validateNirc(parent?.details?.nationality?.value || context.countryCode);

            return yupExt.customerProperty().shape({
                value: yup
                    .string()
                    .required(t('common.error.required'))
                    .test(
                        'invalid-identity',
                        t('common.error.identityNumber'),
                        (value: any) => !value || identityValidator(value, parent?.dateOfBirth?.value)
                    ),
            });
        }

        return yup.mixed().notRequired();
    }),
});

export const detailsSchema = {
    drivingLicense: drivingLicenseSchema,
};
