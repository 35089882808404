import React from 'react';
import { Trans } from 'react-i18next';
import { VariantDataFragment } from '../../../components/data/useLoadVariants.graphql';
import { useCsvContext } from '../../../components/routes/CsvRoute/CsvContext';
import ImgWithFallback from '../../../components/shared/ImgWithFallback';
import { CalculatorValues } from '../../../components/shared/calculator-next/types';
import useCompanyFormatting from '../../../components/utilities/useCompanyFormatting';
import { useContentTranslation } from '../../../i18n';
import { MonthlyPayment } from '../../EventFlow/components/Calculator/blocks/Financing';
import {
    CarOfInterestContainer,
    CarOfInterestVariantName,
    CarOfInterestVariantPrice,
} from '../../EventFlow/components/DraftPage/ui';
import noImg from '../../../assets/images/no-image.png';

export type CarOfInterestWithCsvConfiguratorProps = {
    variant?: VariantDataFragment;
    calculator: Partial<CalculatorValues>;
};

const CarOfInterestWithCsvConfigurator = ({ variant, calculator }: CarOfInterestWithCsvConfiguratorProps) => {
    const { formatCurrency } = useCompanyFormatting();
    const { ct } = useContentTranslation();

    const { csvConfigurator } = useCsvContext();

    const { totalPrice = 0 } = calculator;
    const imageUrl = csvConfigurator?.variant?.imageUrl ?? variant?.images?.[0]?.url;
    const variantName = ct(variant?.name);

    const monthlyPayment = calculator?.monthlyInstalments ? calculator.monthlyInstalments[0].amount : undefined;

    return (
        <CarOfInterestContainer>
            <div>
                <ImgWithFallback alt={variantName} fallbackSrc={noImg} src={imageUrl || noImg} />
            </div>
            <div className="variantInfo" style={{ position: 'relative' }}>
                <CarOfInterestVariantName>{variantName}</CarOfInterestVariantName>
                <CarOfInterestVariantPrice>{formatCurrency(totalPrice)}</CarOfInterestVariantPrice>
                <MonthlyPayment style={{ padding: '0 10px', width: '100%', fontSize: '85%' }}>
                    <span>
                        <Trans
                            components={{ s: <span /> }}
                            i18nKey="eventCalculatorPage.label.monthlyPayment"
                            values={{ monthlyPayment: formatCurrency(monthlyPayment) }}
                        />
                    </span>
                </MonthlyPayment>
            </div>
        </CarOfInterestContainer>
    );
};

export default CarOfInterestWithCsvConfigurator;
