import React from 'react';
import { completePayment, CompletePaymentMutation, CompletePaymentMutationVariables } from '../../../api/draft.graphql';
import PaymentStatus from '../../../utilities/constants/paymentStatus';
import { ReduxFormFlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';
import ConsentDeposit from '../components/ConsentDeposit';

export type ConsentDepositStepValues = any;

class EventConsentDepositStep extends ReduxFormFlowStep<EventFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'consentDeposit';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('eventConsentDepositPage.label.step');
    }

    public get isCompleted(): boolean {
        const { application } = this.state;

        return !!application?.steps?.reservationToFinance || application?.steps?.payment || false;
    }

    public async execute() {
        const { apolloClient } = this;
        const { token, event } = this.state;

        const eventSetting = event?.setting;
        const hasDeposit = !!eventSetting?.isDepositPaymentMandatory;

        if (!token) {
            throw new Error('Token missing in state');
        }

        if (hasDeposit) {
            const response = await apolloClient.mutate<CompletePaymentMutation, CompletePaymentMutationVariables>({
                mutation: completePayment,
                variables: { token },
            });

            this.stateStore.update({ ...response.data?.response });
        }

        return this.nextStep;
    }

    public setPaymentResult = (nextPaymentResult: any) => this.stateStore.update({ paymentResult: nextPaymentResult });

    public render(): React.ReactElement | null {
        const {
            variant,
            event,
            application,
            token,
            paymentResult,
            channel,
            calculator,
            carOfInterest,
            miniConfiguratorDetails,
            source,
            dealerId,
        } = this.state;

        if (!application) {
            throw new Error('Application missing in state');
        }

        if (!token) {
            throw new Error('Token missing in state');
        }

        if (!event) {
            throw new Error('Event missing in state');
        }

        if (!dealerId) {
            throw new Error('Dealer id missing in state');
        }

        const applicationPaymentResult = application.steps?.payment ? PaymentStatus.AUTHORISED : undefined;

        return (
            <ConsentDeposit
                application={application}
                backStep={this.getBackContext()}
                calculator={calculator}
                carOfInterest={carOfInterest}
                channel={channel}
                dealerId={dealerId}
                event={event}
                isLastStep={this.isLastStep}
                miniConfiguratorDetails={miniConfiguratorDetails}
                onSubmit={this.submit}
                paymentResult={paymentResult || applicationPaymentResult}
                setPaymentResult={this.setPaymentResult}
                source={source}
                token={token}
                variant={variant}
            />
        );
    }
}

export default EventConsentDepositStep;
