import { filter, get } from 'lodash/fp';
import { ConsentDataFragment } from '../../../api/consents.graphql';
import { ConsentOrDeclarationOwnerType } from '../../../schema';

export const getDraftConsents = (data: ConsentDataFragment[] = [], appliedForFinancing: boolean = false) =>
    filter<ConsentDataFragment>(item => {
        const type = get('owner.type', item);

        return (
            type === ConsentOrDeclarationOwnerType.COUNTRY ||
            type === ConsentOrDeclarationOwnerType.EVENT ||
            type === ConsentOrDeclarationOwnerType.ALLEVENTS ||
            (appliedForFinancing && type === ConsentOrDeclarationOwnerType.BANK)
        );
    })(data);

export const getPaymentConsents = filter<ConsentDataFragment>(
    item => get('owner.type', item) === ConsentOrDeclarationOwnerType.PAYMENTTERMS
);
