import { isNil, isArray } from 'lodash/fp';
import { useMemo } from 'react';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';

const useApplyDisabled = (values: Partial<CalculatorValues>) =>
    useMemo(
        () =>
            isNil(values?.coe) ||
            !values?.monthlyInstalments ||
            (isArray(values?.monthlyInstalments) && !values?.monthlyInstalments[0]),
        [values]
    );

export default useApplyDisabled;
